import { useEffect, useMemo, useState } from 'react';
import { BUSINESS_LINE } from 'shared/constants';

export function useInvalidateAfterBatchUpdate({
    isInProgress,
    invalidate,
}: {
    isInProgress: (businessLine: string) => boolean;
    invalidate: () => void;
}) {
    const [shouldInvalidateForAgAndFoodBatchUpdate, setShouldInvalidateForAgAndFoodBatchUpdate] = useState(false);
    const [
        shouldInvalidateForInorganicSpecialtiesBatchUpdate,
        setShouldInvalidateForInorganicSpecialtiesBatchUpdate,
    ] = useState(false);
    const [
        shouldInvalidateForPaintAndCoatingsBatchUpdate,
        setShouldInvalidateForPaintAndCoatingsBatchUpdate,
    ] = useState(false);
    const [
        shouldInvalidateForNaturalResourcesBatchUpdate,
        setShouldInvalidateForNaturalResourcesBatchUpdate,
    ] = useState(false);

    const isAgAndFoodBatchLoadInProgress = useMemo(() => {
        return isInProgress(BUSINESS_LINE.agAndFood);
    }, [isInProgress]);

    const isInorganicSpecialtiesBatchLoadInProgress = useMemo(() => {
        return isInProgress(BUSINESS_LINE.inorganicSpecialties);
    }, [isInProgress]);

    const isPaintAndCoatingsBatchLoadInProgress = useMemo(() => {
        return isInProgress(BUSINESS_LINE.paintsAndCoatings);
    }, [isInProgress]);

    const isNaturalResourcesBatchLoadInProgress = useMemo(() => {
        return isInProgress(BUSINESS_LINE.naturalResources);
    }, [isInProgress]);

    useEffect(() => {
        if (!shouldInvalidateForAgAndFoodBatchUpdate && isAgAndFoodBatchLoadInProgress) {
            setShouldInvalidateForAgAndFoodBatchUpdate(true);
        }

        if (!isAgAndFoodBatchLoadInProgress && shouldInvalidateForAgAndFoodBatchUpdate) {
            invalidate();
            setShouldInvalidateForAgAndFoodBatchUpdate(false);
        }
    }, [
        isAgAndFoodBatchLoadInProgress,
        shouldInvalidateForAgAndFoodBatchUpdate,
        setShouldInvalidateForAgAndFoodBatchUpdate,
        invalidate,
    ]);

    useEffect(() => {
        if (!shouldInvalidateForInorganicSpecialtiesBatchUpdate && isInorganicSpecialtiesBatchLoadInProgress) {
            setShouldInvalidateForInorganicSpecialtiesBatchUpdate(true);
        }

        if (!isInorganicSpecialtiesBatchLoadInProgress && shouldInvalidateForInorganicSpecialtiesBatchUpdate) {
            invalidate();
            setShouldInvalidateForInorganicSpecialtiesBatchUpdate(false);
        }
    }, [
        isInorganicSpecialtiesBatchLoadInProgress,
        shouldInvalidateForInorganicSpecialtiesBatchUpdate,
        setShouldInvalidateForInorganicSpecialtiesBatchUpdate,
        invalidate,
    ]);

    useEffect(() => {
        if (!shouldInvalidateForPaintAndCoatingsBatchUpdate && isPaintAndCoatingsBatchLoadInProgress) {
            setShouldInvalidateForPaintAndCoatingsBatchUpdate(true);
        }

        if (!isPaintAndCoatingsBatchLoadInProgress && shouldInvalidateForPaintAndCoatingsBatchUpdate) {
            invalidate();
            setShouldInvalidateForPaintAndCoatingsBatchUpdate(false);
        }
    }, [
        isPaintAndCoatingsBatchLoadInProgress,
        shouldInvalidateForPaintAndCoatingsBatchUpdate,
        setShouldInvalidateForPaintAndCoatingsBatchUpdate,
        invalidate,
    ]);

    useEffect(() => {
        if (!shouldInvalidateForNaturalResourcesBatchUpdate && isNaturalResourcesBatchLoadInProgress) {
            setShouldInvalidateForNaturalResourcesBatchUpdate(true);
        }

        if (!isNaturalResourcesBatchLoadInProgress && shouldInvalidateForNaturalResourcesBatchUpdate) {
            invalidate();
            setShouldInvalidateForNaturalResourcesBatchUpdate(false);
        }
    }, [
        isNaturalResourcesBatchLoadInProgress,
        shouldInvalidateForNaturalResourcesBatchUpdate,
        setShouldInvalidateForNaturalResourcesBatchUpdate,
        invalidate,
    ]);
}
