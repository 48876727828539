import { useService } from '@insight2profit/drive-app';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { QUERY_KEYS } from 'shared/constants';
import { ITableNonStandardUOMConversion } from 'shared/types';
import { getNonStandardUomDropdownParams } from 'shared/utility/dropdown';

export function useNonStandardUomDropdown(materialId: string) {
    const { enqueueSnackbar } = useSnackbar();
    const { nonStandardUomDropdownService } = useService();

    const { data, ...restOfQuery } = useQuery<ITableNonStandardUOMConversion[]>({
        queryKey: [QUERY_KEYS.nonStandardUOMDropdown, materialId],
        queryFn: async () => {
            return nonStandardUomDropdownService.get(getNonStandardUomDropdownParams(materialId));
        },
        onError: () => {
            enqueueSnackbar('Failed to load Non Standard UOM dropdown options', { variant: 'error' });
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });

    const nonStandardUomList = useMemo(() => data ?? ([] as ITableNonStandardUOMConversion[]), [data]);

    const nonStandardUOMValueOptions = useMemo(
        () =>
            nonStandardUomList.map(({ targetUnit, displayUnit }) => ({
                value: targetUnit,
                label: displayUnit,
            })),
        [nonStandardUomList]
    );

    return {
        ...restOfQuery,
        nonStandardUomList,
        nonStandardUOMValueOptions,
    };
}
