import { Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { SaveButton } from 'shared/components/forms';
import { ModalHeader } from '../../mass-actions';
import { ITableProductPricesLocks, IViewProductPricesLocks } from 'shared/types';
import { DataAccessResponse } from '@price-for-profit/micro-services';
import { UseMutationResult } from '@tanstack/react-query';
import { ProductPricesLocksSoftEditParams } from 'shared/services';

interface ProductPricesLockConfirmationModalProps {
    businessLineName: string;
    openConfirmation: {
        businessLineName: string;
        isLocked: boolean;
    };
    handleClose: () => void;
    matchingLock: IViewProductPricesLocks | undefined;
    productPriceLocksMutation: UseMutationResult<
        {
            editResponse: DataAccessResponse<ITableProductPricesLocks>;
            addResponse: DataAccessResponse<ITableProductPricesLocks>;
        },
        Error,
        Omit<ProductPricesLocksSoftEditParams, 'userDisplayName'>,
        unknown
    >;
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const ProductPricesLockConfirmationModal = ({
    businessLineName,
    openConfirmation,
    handleClose,
    matchingLock,
    productPriceLocksMutation,
}: ProductPricesLockConfirmationModalProps) => {
    return (
        <Modal
            open={businessLineName === openConfirmation.businessLineName}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box sx={modalStyle}>
                <ModalHeader
                    title={`${openConfirmation.isLocked ? 'Lock' : 'Unlock'} Confirmation`}
                    handleClose={handleClose}
                />
                <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                    Are you sure you want to {openConfirmation.isLocked ? 'lock' : 'unlock'} global edits for{' '}
                    {businessLineName.toUpperCase()}?
                </Typography>
                <Box display='flex' justifyContent={'flex-end'} pt={4}>
                    <SaveButton
                        submitHandler={() => {
                            productPriceLocksMutation.mutate({
                                oldViewRow: matchingLock,
                                isLocked: openConfirmation.isLocked,
                            });
                        }}
                        text='Confirm'
                        isLoading={productPriceLocksMutation.isLoading}
                    />
                </Box>
            </Box>
        </Modal>
    );
};
