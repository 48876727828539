import { useEffect } from 'react';
import { UseFormResetField, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ICustomerPricesMassActionForm } from 'shared/types';
import { useCustomerPricesMassActionField } from './use-customer-prices-mass-action-field';

export function useCustomerPricesMassActionResetForm({
    resetField,
    setValue,
    watch,
}: {
    resetField: UseFormResetField<ICustomerPricesMassActionForm>;
    setValue: UseFormSetValue<ICustomerPricesMassActionForm>;
    watch: UseFormWatch<ICustomerPricesMassActionForm>;
}) {
    const selectedType = watch('type');
    const selectedField = watch('field');

    const { allowedFieldOptions, defaultSelectedField } = useCustomerPricesMassActionField(selectedType);

    useEffect(() => {
        const allowedFieldOptionValues = allowedFieldOptions?.map(fieldOptions => fieldOptions.value);
        if (!allowedFieldOptionValues?.includes(selectedField)) {
            resetField('field');
        }
    }, [selectedType, resetField, allowedFieldOptions, selectedField]);

    useEffect(() => {
        resetField('value');
    }, [selectedType, selectedField, resetField]);

    useEffect(() => {
        if (!selectedField && defaultSelectedField) {
            setValue('field', defaultSelectedField);
        }
    }, [defaultSelectedField, selectedField, setValue]);

    useEffect(() => {
        resetField('value');
    }, [selectedField, resetField]);
}
