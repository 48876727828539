import { PropsWithChildren, createContext, useContext } from 'react';

export const pricesInvalidatorContext = createContext<{ invalidateQuery: () => void }>({
    invalidateQuery: async () => {},
});

export function ProvidePricesInvalidator({
    children,
    invalidateQuery,
}: PropsWithChildren<{ invalidateQuery: () => void }>) {
    return (
        <pricesInvalidatorContext.Provider value={{ invalidateQuery }}>{children}</pricesInvalidatorContext.Provider>
    );
}

export function usePricesInvalidator() {
    return useContext(pricesInvalidatorContext);
}
