import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { ReactElement } from 'react';

type NullCellParams = GridRenderCellParams<number, any, number> & {
    isDisplayEditable?: boolean;
};

export function NullCell({ isDisplayEditable = false, ...params }: NullCellParams): ReactElement {
    if (isDisplayEditable) return <div className='editable-cell'></div>;
    return <div></div>;
}
