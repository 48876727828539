import { useService } from '@insight2profit/drive-app';
import { QueryKey, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

export function useCustomerQuoteDefaultQuery(businessLine: string, region: string) {
    const { enqueueSnackbar } = useSnackbar();
    const { customerPricesQuoteService } = useService();
    const combinedQueryKey: QueryKey = ['quoteDefaults', businessLine, region];

    const { data, isLoading, isFetching } = useQuery({
        queryKey: combinedQueryKey,
        queryFn: async () => customerPricesQuoteService.getDefaultsQuoteValues({ businessLine, region }),
        onError: () => {
            enqueueSnackbar('Failed to load quote products');
        },
        enabled: !!businessLine && !!region,
    });

    return {
        data: data,
        isLoading,
        isFetching,
    } as const;
}
