import { isNil } from 'lodash';
import { customerPriceStatuses, USER_ADDED_PROSPECTIVE_DATA_SOURCE, USER_ADDED_SAP_DATASOURCE } from 'shared/constants';
import { IViewCustomerPrices } from 'shared/types';
import { IExchangeRatesService } from './exchangeRatesService';

export type CustomerPricesApprovalColumns = Pick<
    IViewCustomerPrices,
    | 'recommendedPrice'
    | 'regionalMinimum'
    | 'globalMinimum'
    | 'updatedFloorPrice'
    | 'revisedPriceWithoutFreight'
    | 'lastInvoiceFreightCost'
    | 'dataSource'
    | 'perQuantity'
>;

type GetNewStatusParams<T extends CustomerPricesApprovalColumns> = {
    row: T;
    userApproverTier: number;
};

type CanCurrentUserApproveParams<T extends CustomerPricesApprovalColumns> = {
    row: T;
    userApproverTier: number;
};

type ScalePricingScaleAmts = CustomerPricesApprovalColumns &
    Pick<
        IViewCustomerPrices,
        'amount01' | 'amount02' | 'amount03' | 'amount04' | 'amount05' | 'amount06' | 'isScalePrice'
    >;

export interface ICustomerPriceApprovalService {
    getSubmitStatus<T extends CustomerPricesApprovalColumns>({
        row,
        userApproverTier,
    }: GetNewStatusParams<T>): {
        isAutoApproved: boolean;
        approver: string;
        status: string;
    };
    canCurrentUserApprove<T extends CustomerPricesApprovalColumns>({
        row,
        userApproverTier,
    }: CanCurrentUserApproveParams<T>): boolean;
    determinePriceForComparison<T extends ScalePricingScaleAmts>(row: T): CustomerPricesApprovalColumns;
}

export class CustomerPriceApprovalService implements ICustomerPriceApprovalService {
    constructor(private exchangeRatesService: IExchangeRatesService) {}
    getSubmitStatus<T extends CustomerPricesApprovalColumns>({ row, userApproverTier }: GetNewStatusParams<T>) {
        const tier = this.determineTier({ row });
        const approver = this.determineApprover(tier);
        const isAutoApproved = this.isAutoApproved(tier, userApproverTier);
        const status = this.determineStatusDisplay(isAutoApproved);

        return { isAutoApproved, approver, status };
    }

    canCurrentUserApprove<T extends CustomerPricesApprovalColumns>({
        row,
        userApproverTier,
    }: CanCurrentUserApproveParams<T>) {
        try {
            const tier = this.determineTier({ row });
            const updatedTier = userApproverTier >= tier ? 0 : tier;

            return updatedTier === 0;
        } catch {
            return false;
        }
    }

    private determineTier({ row }: { row: CustomerPricesApprovalColumns }): number {
        const isUserAddedRow = [USER_ADDED_SAP_DATASOURCE, USER_ADDED_PROSPECTIVE_DATA_SOURCE].includes(
            row.dataSource || 'Empty'
        );

        if (isNil(row.revisedPriceWithoutFreight))
            throw Error(`Error determining required approval level. Missing price for comparison.`);

        let tier = 0;

        if (!isUserAddedRow) {
            if (isNil(row.recommendedPrice))
                throw Error('Error determining required approval level. Missing Recommended Price.');
            if (Number(row.revisedPriceWithoutFreight.toFixed(2)) < Number(row.recommendedPrice.toFixed(2))) tier = 1;
        }

        if (isNil(row.regionalMinimum))
            throw Error('Error determining required approval level. Missing Regional Minimum.');
        if (Number(row.revisedPriceWithoutFreight.toFixed(2)) < Number(row.regionalMinimum.toFixed(2))) tier = 2;

        if (isNil(row.globalMinimum)) throw Error('Error determining required approval level. Missing Global Minimum.');
        if (Number(row.revisedPriceWithoutFreight.toFixed(2)) < Number(row.globalMinimum.toFixed(2))) tier = 3;

        if (!isUserAddedRow && isNil(row.updatedFloorPrice))
            throw Error('Error determining required approval level. Missing Updated Floor Price.');
        if (isUserAddedRow && isNil(row.updatedFloorPrice)) {
            if (Number(row.revisedPriceWithoutFreight.toFixed(2)) < 0) tier = 4;
        }
        if (row.updatedFloorPrice) {
            if (Number(row.revisedPriceWithoutFreight.toFixed(2)) < Number(row.updatedFloorPrice.toFixed(2))) tier = 4;
        }

        return tier;
    }

    determinePriceForComparison<T extends ScalePricingScaleAmts>(row: T): CustomerPricesApprovalColumns {
        const customerPricesApprovalColumns: CustomerPricesApprovalColumns = {
            recommendedPrice: (row.recommendedPrice ?? 0) / (row.perQuantity ?? 1),
            regionalMinimum: row.regionalMinimum,
            globalMinimum: row.globalMinimum,
            updatedFloorPrice: row.updatedFloorPrice,
            perQuantity: row.perQuantity,
            dataSource: row.dataSource,
            revisedPriceWithoutFreight: row.isScalePrice
                ? Math.max(
                      this.findLowestNonZeroAmt(row) / (row.perQuantity ?? 1) - (row.lastInvoiceFreightCost ?? 0),
                      0
                  )
                : row.revisedPriceWithoutFreight,
        };

        return customerPricesApprovalColumns;
    }

    private findLowestNonZeroAmt<T extends ScalePricingScaleAmts>(row: T): number {
        const amtGreaterThanZeroValues = [
            row.amount01,
            row.amount02,
            row.amount03,
            row.amount04,
            row.amount05,
            row.amount06,
        ].filter(amt => !!amt) as number[];

        return amtGreaterThanZeroValues.length > 0 ? Math.min(...amtGreaterThanZeroValues) : 0;
    }

    private isAutoApproved(requiredTier: number, userApproverTier: number): boolean {
        return requiredTier <= userApproverTier;
    }

    private determineApprover(tier: number): string {
        if (tier === 0) return 'None';
        if (tier === 1) return 'Sales Manager';
        if (tier === 2) return 'Regional Marketing';
        if (tier === 3) return 'Global Marketing';
        if (tier === 4) return 'Product Line Management';
        return 'Error getting tier';
    }

    private determineStatusDisplay(isAutoApproved: boolean) {
        if (isAutoApproved) {
            return customerPriceStatuses.APPROVED;
        }

        return customerPriceStatuses.APPROVAL_REQUIRED;
    }
}
