import { createContext, useContext } from 'react';
import { useCustomerPricesSummaryQuery } from 'shared/queries/customerPricesSummaryQuery';
import { NoPropsJustChildren } from 'shared/types';

export const customerPricesSummaryContext = createContext<ReturnType<typeof useCustomerPricesSummaryQuery>>({
    data: undefined,
    isLoading: false,
    isFetching: false,
    invalidateQuery: async () => {},
    error: null,
});

export function ProvideCustomerPricesSummary({ children }: NoPropsJustChildren) {
    const customerPricesSummary = useCustomerPricesSummaryQuery();

    return (
        <customerPricesSummaryContext.Provider value={customerPricesSummary}>
            {children}
        </customerPricesSummaryContext.Provider>
    );
}

export function useCustomerPricesSummary() {
    return useContext(customerPricesSummaryContext);
}
