import { useReducer } from 'react';
import { IAddExistingSAPCustomerPrices } from 'shared/types';

type AddNewExistingSAPCustomerPricesAction =
    | { type: 'add'; newViewRow: IAddExistingSAPCustomerPrices }
    | { type: 'copy'; newRowId: string; copiedRowId: string }
    | { type: 'delete'; id: string }
    | { type: 'edit'; newViewRow: IAddExistingSAPCustomerPrices };

function newExisitingCustomerPricesReducer(
    state: IAddExistingSAPCustomerPrices[],
    action: AddNewExistingSAPCustomerPricesAction
) {
    switch (action.type) {
        case 'delete':
            const remainingRows = state.filter(aRow => {
                return aRow.id !== action.id;
            });
            return remainingRows;
        case 'add':
            return [...state, action.newViewRow];
        case 'edit':
            const editedRows = state.map(aRow => {
                if (aRow.id === action.newViewRow.id) {
                    return {
                        ...aRow,
                        businessLine: action.newViewRow.businessLine,
                        soldTo: action.newViewRow.soldTo,
                        shipTo: action.newViewRow.shipTo,
                        material: action.newViewRow.material,
                        orgRegion: action.newViewRow.orgRegion,
                        application: action.newViewRow.application,
                        marketSegment: action.newViewRow.marketSegment,
                        salesOrganization: action.newViewRow.salesOrganization,
                        customerServiceRep: action.newViewRow.customerServiceRep,
                        accountManager: action.newViewRow.accountManager,
                    };
                }
                return aRow;
            });
            return editedRows;
        case 'copy':
            const originalRow = state.find(aRow => {
                return aRow.id === action.copiedRowId;
            });
            if (!originalRow) return state;
            const copiedRow = {
                ...originalRow,
                id: action.newRowId,
            };
            return [...state, copiedRow];
        default:
            return state;
    }
}

export function useNewExistingCustomerPricesReducer() {
    const placeHolder = useReducer(newExisitingCustomerPricesReducer, [] as IAddExistingSAPCustomerPrices[]);

    return placeHolder;
}
