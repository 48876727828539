import { useDebounce } from '@insight2profit/drive-app';
import { TextField } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useProductPricesMaterialDropdown } from 'shared/queries/productPricesDropdownQueries';
import { IVwDropdownProductPricesMaterials } from 'shared/types';
import { ProductPricesAddNewAutocomplete } from './ProductPricesAddNewAutocomplete';

export function ProductPricesMaterialAutocomplete(params: GridRenderEditCellParams) {
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 500);
    const selectedBusinessLine = params?.row?.businessLine;
    const { data, isLoading, isFetching } = useProductPricesMaterialDropdown({
        searchValue: debouncedSearchValue,
        selectedBusinessLine,
    });
    const apiRef = useGridApiContext();
    return (
        <ProductPricesAddNewAutocomplete
            isLoading={isLoading}
            setEditCellValue={apiRef.current.setEditCellValue}
            rowId={params.row.id}
            field={params.field}
            value={params.value}
            options={debouncedSearchValue && data ? data : []}
            error={params.error}
            getOptionLabel={(option: IVwDropdownProductPricesMaterials) => {
                if (typeof option === 'object' && typeof option.search === 'string') return option.search;
                if (typeof option === 'string') return option;
                return '';
            }}
            isOptionEqualToValue={(
                option: IVwDropdownProductPricesMaterials,
                value: IVwDropdownProductPricesMaterials | string
            ) => {
                if (typeof value === 'string') {
                    return option.materialId === value;
                }
                return option.materialId === value.materialId;
            }}
            placeholder={'Material'}
            onChange={(_, value) => {
                setSearchValue(value?.search ?? '');
                apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: value });
            }}
            onBlur={event => setSearchValue('')}
            loading={(isLoading || isFetching) && !!debouncedSearchValue}
            onInputChange={(event, value, reason) => {
                if (value?.length >= 3) setSearchValue(value);
            }}
            renderInput={params => <TextField {...params} placeholder='Material' />}
        />
    );
}
