import { Button, LinearProgress, Modal } from '@mui/material';
import { Box } from '@mui/system';
import { DataGridPremium, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { useI2pSimpleDataGrid } from '@price-for-profit/data-grid';
import { MASS_ACTION_TYPES } from 'shared/constants';
import { DATA_GRID_STYLE } from 'shared/constants/dataGrid';
import { ExchangeRatesContext, useExchangeRates } from 'shared/providers';
import { ICustomerPricesMassActionParameters, ICustomerPricesMassActionValidation } from 'shared/types';
import { customerPricesForeignCurrencyValueGetterCurried } from 'shared/utility';
import { SHORT_DATE_COLUMN } from '../DataGridCells';
import { ModalHeader } from '../mass-actions';
import { CustomerPricesForeignCurrencyRenderCell } from './CustomerPricesForeignCurrencyRenderCell';

interface CustomerPricesMassActionValidationProps {
    isChildModalOpen: boolean;
    handleCloseChild: () => void;
    validationRows: ICustomerPricesMassActionValidation[];
    selectedType: ICustomerPricesMassActionParameters['Type'];
}

export function CustomerPricesMassActionValidation({
    isChildModalOpen,
    handleCloseChild,
    validationRows,
    selectedType,
}: CustomerPricesMassActionValidationProps) {
    const exchangeRatesContext = useExchangeRates();

    const columns = [
        {
            headerName: 'ID',
            field: 'massActionId',
            hide: true,
        },
        {
            headerName: 'material',
            field: 'material',
        },
        {
            headerName: 'Material ID',
            field: 'materialId',
            width: 200,
        },
        {
            headerName: 'Sold To ID',
            field: 'soldToId',
            width: 200,
        },
        {
            headerName: 'Sold To',
            field: 'soldTo',
            width: 200,
        },
        {
            headerName: 'Ship To ID',
            field: 'shipToId',
            width: 200,
        },
        {
            headerName: 'Ship To',
            field: 'shipTo',
            width: 200,
        },
        getValueColumn({
            headerName: 'Old Value',
            fieldType: 'old',
            selectedType,
            exchangeRatesContext,
        }),
        getValueColumn({
            headerName: 'New Value',
            fieldType: 'new',
            selectedType,
            exchangeRatesContext,
        }),
        {
            headerName: 'Invalidation Reason',
            field: 'invalidationReason',
            maxWidth: 300,
        },
    ];

    const { getDataGridProps } = useI2pSimpleDataGrid({
        name: 'CustomerPricesMassActionValidation',
        columns: columns,
        getData: async () => {
            return validationRows;
        },
        dataGridInitialState: {
            pinnedColumns: { left: ['__check__', 'material'] },
        },
        rowsPerPageOptions: [10, 20, 50, 100],
    });

    const dataGridProps = getDataGridProps();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75vw',
        height: '95vh',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 1,
        px: 4,
        pb: 3,
    };

    return (
        <Modal open={isChildModalOpen} onClose={handleCloseChild}>
            <Box sx={{ ...style }}>
                <Box p={2} gap={2} sx={{ minWidth: '400px', height: '100%', flexDirection: 'column', display: 'flex' }}>
                    <ModalHeader handleClose={handleCloseChild}>Problem Records</ModalHeader>

                    <Box flexGrow={1}>
                        <DataGridPremium
                            {...dataGridProps}
                            rows={validationRows}
                            columns={columns}
                            sx={{ ...DATA_GRID_STYLE, height: '100%' }}
                            disableSelectionOnClick
                            components={{
                                LoadingOverlay: LinearProgress,
                            }}
                            componentsProps={{
                                panel: {
                                    sx: {
                                        '& .MuiDataGrid-filterFormColumnInput': {
                                            width: 'auto',
                                        },
                                    },
                                },
                            }}
                            disableVirtualization={true}
                            getRowId={row => row.massActionId}
                        />
                    </Box>

                    <Box display='flex' justifyContent='space-between'>
                        <Button sx={{ color: 'secondary.main' }} onClick={handleCloseChild}>
                            Back
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

const getValueColumn = ({
    headerName,
    fieldType,
    selectedType,
    exchangeRatesContext: { isForeignCurrency, exchangeRates },
}: {
    headerName: string;
    fieldType: 'old' | 'new';
    selectedType: ICustomerPricesMassActionParameters['Type'];
    exchangeRatesContext: ExchangeRatesContext;
}) => {
    const isValidityDatesType = selectedType === MASS_ACTION_TYPES.VALIDITY_DATES;

    const numberColDef = {
        type: 'number',
        valueGetter: (params: GridValueGetterParams<number, ICustomerPricesMassActionValidation>) => {
            if (params.row.field.includes('scaleQty')) return params.value;
            return customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates })(params);
        },
        renderCell: (params: GridRenderCellParams) => {
            if (params.row.field.includes('scaleQty')) return <div>{params.value}</div>;
            return <CustomerPricesForeignCurrencyRenderCell {...params} />;
        },
    };

    return {
        headerName,
        field: isValidityDatesType
            ? (fieldType === 'old' && 'oldDate') || 'newDate'
            : (fieldType === 'old' && 'oldValue') || 'newValue',
        ...(isValidityDatesType ? SHORT_DATE_COLUMN : numberColDef),
        minWidth: 150,
    };
};
