import { DataAccessResponse } from '@price-for-profit/micro-services';
import { ITableProductPricesPersistedAttributes, IViewProductPrices } from 'shared/types';
import { ITableCrudService } from './generic/tableCrudService';

type SoftEditPersistedAttributesParams = {
    newViewRow: IViewProductPrices;
    oldViewRow: IViewProductPrices;
    userDisplayName?: string;
};

export interface IProductPricesPersistedAttributesService {
    softEditPersistedAttributes({
        newViewRow,
        oldViewRow,
        userDisplayName,
    }: SoftEditPersistedAttributesParams): Promise<{
        editOriginalRowResult?: DataAccessResponse<ITableProductPricesPersistedAttributes>;
        addRowResult: DataAccessResponse<ITableProductPricesPersistedAttributes>;
        newViewRow: IViewProductPrices;
    }>;
}

export class ProductPricesPersistedAttributesService implements IProductPricesPersistedAttributesService {
    constructor(
        private productPricesPersistedAttributesTableCrudService: ITableCrudService<ITableProductPricesPersistedAttributes>
    ) {}

    async softEditPersistedAttributes({
        newViewRow,
        oldViewRow,
        userDisplayName,
    }: SoftEditPersistedAttributesParams): Promise<{
        editOriginalRowResult?: DataAccessResponse<ITableProductPricesPersistedAttributes>;
        addRowResult: DataAccessResponse<ITableProductPricesPersistedAttributes>;
        newViewRow: IViewProductPrices;
    }> {
        if (!newViewRow.uom) throw Error('New UOM cannot be blank');
        if (!userDisplayName) throw Error('Username must be provided');

        let editOriginalRowResult: DataAccessResponse<ITableProductPricesPersistedAttributes> | undefined = undefined;
        let addRowResult: DataAccessResponse<ITableProductPricesPersistedAttributes> | undefined = undefined;

        if (oldViewRow.productPricesPersistedAttributesId) {
            const softEditResponse = await this.productPricesPersistedAttributesTableCrudService.softEditViaTermOriginal(
                userDisplayName,
                { uom: newViewRow.uom || undefined },
                oldViewRow.productPricesPersistedAttributesId
            );
            editOriginalRowResult = softEditResponse.editOriginalRowResult;
            addRowResult = softEditResponse.addRowResult;
        } else {
            addRowResult = await this.productPricesPersistedAttributesTableCrudService.addRow(userDisplayName, {
                businessLine: oldViewRow.businessLine,
                materialId: oldViewRow.materialId,
                orgRegion: oldViewRow.orgRegion,
                application: oldViewRow.application,
                uom: newViewRow.uom,
            });
        }

        return { editOriginalRowResult, addRowResult, newViewRow };
    }
}
