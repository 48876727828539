import { CLIENT_ID } from '@insight2profit/drive-app';
import { GridValidRowModel } from '@mui/x-data-grid-premium';
import { ServerSideState, generateCollectionFilter } from '@price-for-profit/data-grid';
import {
    DataAccessPaginatedResponse,
    IDataAccessService,
    IFilter,
    NoInfer,
    getData,
} from '@price-for-profit/micro-services';
import { DATABASE_LABEL } from 'shared/constants';

type GetParams<TableOrView> = {
    state: ServerSideState;
    baseFilter?: IFilter<TableOrView>;
};

export interface IGridService<TableOrView extends GridValidRowModel> {
    get({ state, baseFilter }: GetParams<TableOrView>): Promise<DataAccessPaginatedResponse<TableOrView>>;
    getAll({ state, baseFilter }: GetParams<TableOrView>): Promise<TableOrView[]>;
}

export class GridService<TableOrView extends GridValidRowModel> implements IGridService<TableOrView> {
    constructor(protected dasService: IDataAccessService, private viewId: string) {}

    async get({ state, baseFilter }: GetParams<TableOrView>): Promise<DataAccessPaginatedResponse<TableOrView>> {
        return await this.dasService.getCollection<TableOrView, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: this.viewId,
            page: state.pageNumber,
            pageSize: state.pageSize,
            sortBy: state.sortModel[0]?.field as NoInfer<keyof TableOrView>,
            sortDescending: state.sortModel[0]?.sort === 'desc',
            collectionFilter: generateCollectionFilter(state.filterModel, baseFilter),
        });
    }

    async getAll({ state, baseFilter }: GetParams<TableOrView>): Promise<TableOrView[]> {
        return await getData((page: number) => this.get({ state: { ...state, pageNumber: page }, baseFilter }));
    }
}
