import { DataAccessPaginatedResponse } from '@price-for-profit/micro-services';
import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERY_KEYS } from 'shared/constants';

type useI2PQueryProps<Response> = {
    errorMessage: string;
    queryFn: () => Promise<DataAccessPaginatedResponse<Response>>;
    queryName: typeof QUERY_KEYS[keyof typeof QUERY_KEYS];
    additionalQueryKeys?: QueryKey;
    refetchInterval?: number;
    onError?: () => void;
    enabled?: boolean;
};

export function useI2PQuery<Response>({
    queryName,
    additionalQueryKeys = [],
    queryFn,
    errorMessage,
    refetchInterval,
    onError,
    enabled = true,
}: useI2PQueryProps<Response>) {
    const { data, isLoading, isFetching, invalidateQuery } = useI2PAllQuery({
        queryName,
        additionalQueryKeys,
        queryFn,
        errorMessage,
        refetchInterval,
        onError,
        enabled,
    });
    return {
        data: data?.data,
        metaData: data?.metadata,
        isLoading,
        isFetching,
        invalidateQuery,
    };
}

type useI2PAllQueryProps<Response> = {
    errorMessage: string;
    queryFn: () => Promise<Response>;
    queryName: typeof QUERY_KEYS[keyof typeof QUERY_KEYS];
    additionalQueryKeys?: QueryKey;
    refetchInterval?: number;
    onError?: () => void;
    enabled?: boolean;
    keepPreviousData?: boolean;
};

export function useI2PAllQuery<Response>({
    queryName,
    additionalQueryKeys = [],
    queryFn,
    errorMessage,
    refetchInterval,
    onError,
    enabled = true,
    keepPreviousData = true,
}: useI2PAllQueryProps<Response>) {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const combinedQueryKey: QueryKey = [queryName, ...additionalQueryKeys];

    const { data, isLoading, isFetching } = useQuery({
        queryKey: combinedQueryKey,
        queryFn,
        onError: () => {
            if (onError) {
                onError();
                return;
            }
            enqueueSnackbar(errorMessage, {
                variant: 'error',
            });
        },
        keepPreviousData,
        refetchInterval,
        enabled,
    });

    return {
        data: data,
        isLoading,
        isFetching,
        invalidateQuery: () => queryClient.invalidateQueries(combinedQueryKey),
    } as const;
}
