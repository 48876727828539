import { CLIENT_ID } from '@insight2profit/drive-app';
import { generateCollectionFilter, ServerSideState } from '@price-for-profit/data-grid';
import {
    DataAccessPaginatedResponse,
    DataAccessResponse,
    IDataAccessService,
    NoInfer,
} from '@price-for-profit/micro-services';
import { DATABASE_LABEL, TABLE_PRODUCT_PRICES_COMMENTS } from 'shared/constants';
import { ITableProductPricesComments } from 'shared/types';

export interface IProductPricesCommentsService {
    getComments(state: ServerSideState): Promise<DataAccessPaginatedResponse<ITableProductPricesComments>>;
    addComment(newRow: ITableProductPricesComments): Promise<DataAccessResponse<ITableProductPricesComments>>;
}

export class ProductPricesCommentsService implements IProductPricesCommentsService {
    constructor(private dasService: IDataAccessService) {}

    async getComments(state: ServerSideState): Promise<DataAccessPaginatedResponse<ITableProductPricesComments>> {
        return await this.dasService.getCollection<ITableProductPricesComments, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_PRODUCT_PRICES_COMMENTS,
            page: state.pageNumber,
            pageSize: state.pageSize,
            sortBy: state.sortModel[0]?.field as NoInfer<keyof ITableProductPricesComments>,
            sortDescending: true,
            collectionFilter: generateCollectionFilter<ITableProductPricesComments>(state.filterModel),
        });
    }
    async addComment(newRow: ITableProductPricesComments): Promise<DataAccessResponse<ITableProductPricesComments>> {
        return await this.dasService.addRow<ITableProductPricesComments, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_PRODUCT_PRICES_COMMENTS,
            payload: newRow,
        });
    }
}
