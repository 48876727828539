import { useService } from '@insight2profit/drive-app';
import { useQuery } from '@tanstack/react-query';

export function useCompanyAddressesDropdown() {
    const { productPricesCompanyAddressDropdownService } = useService();

    return useQuery({
        queryKey: ['getCompanyAddresses'],
        queryFn: async () => productPricesCompanyAddressDropdownService.get(),
        keepPreviousData: true,
        select: data =>
            data.map(data => {
                return data.addresses;
            }),
    });
}
