import { useService, useUser } from '@insight2profit/drive-app';
import { DataAccessResponse } from '@price-for-profit/micro-services';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { usePricesInvalidator } from 'shared/providers';
import { ITableCustomerPrices, IViewCustomerPrices } from 'shared/types';

export function useCustomerPriceRejectMutation() {
    const { customerPricesService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { invalidateQuery } = usePricesInvalidator();
    const user = useUser();
    const mutation = useMutation<
        {
            editResponse: DataAccessResponse<ITableCustomerPrices>;
            addResponse: DataAccessResponse<ITableCustomerPrices>;
            newViewRow: IViewCustomerPrices;
        },
        Error,
        IViewCustomerPrices
    >({
        mutationKey: ['RejectCustomerPrice'],
        mutationFn: async row => {
            return await customerPricesService.rejectRow(row, user?.displayName || '');
        },
        onSuccess: () => {
            enqueueSnackbar('Approval submitted', { variant: 'success' });
            invalidateQuery();
        },
        onError: error => {
            enqueueSnackbar('There was an error submitting the approval', { variant: 'error' });
        },
    });
    return mutation;
}
