import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useExchangeRates } from 'shared/providers';

export const CustomerPricesForeignCurrencyRenderCell = (params: GridRenderCellParams) => {
    const { isForeignCurrency } = useExchangeRates();
    const currencyCode = params.row?.documentCurrency ?? 'USD';
    const valueToFormat = params?.value ?? 0;

    const formattedValue = new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency: isForeignCurrency ? currencyCode : 'USD',
    }).format(valueToFormat);
    const editableCell = params.colDef.editable && params.isEditable ? 'editable-cell' : '';

    return <div className={editableCell}>{formattedValue}</div>;
};
