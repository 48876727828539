import { ContentCopy } from '@mui/icons-material';
import { GridActionsCellItem, useGridApiContext } from '@mui/x-data-grid-premium';

interface AddRecordCopyButtonProps {
    copyHandler: (copiedRowId: string) => void;
    rowId: string;
}

export function AddRecordCopyButton({ copyHandler, rowId }: AddRecordCopyButtonProps) {
    const gridContext = useGridApiContext();
    const isRowinEditMode = gridContext.current.getRowMode(rowId) === 'edit';

    return (
        <GridActionsCellItem
            icon={<ContentCopy />}
            label='Copy'
            onClick={() => {
                copyHandler(rowId);
            }}
            disabled={isRowinEditMode}
        />
    );
}
