import { createContext, useContext } from 'react';
import { useProductPricesSummaryQuery } from 'shared/queries';
import { NoPropsJustChildren } from 'shared/types';

export const productPricesSummaryContext = createContext<ReturnType<typeof useProductPricesSummaryQuery>>({
    data: undefined,
    isLoading: false,
    isFetching: false,
    invalidateQuery: async () => {},
    error: null,
});

export function ProvideProductPricesSummary({ children }: NoPropsJustChildren) {
    const productPricesSummary = useProductPricesSummaryQuery();

    return (
        <productPricesSummaryContext.Provider value={productPricesSummary}>
            {children}
        </productPricesSummaryContext.Provider>
    );
}

export function useProductPricesSummary() {
    return useContext(productPricesSummaryContext);
}
