import { Button, ButtonProps, CircularProgress, SxProps, Theme } from '@mui/material';

type SaveButtonProps = {
    submitHandler: React.MouseEventHandler<HTMLButtonElement>;
    isLoading?: boolean;
    text?: string;
    isDisabled?: boolean;
    sx?: SxProps<Theme>;
};

export function SaveButton({ submitHandler, isLoading = false, text = 'Save', isDisabled, sx = {} }: SaveButtonProps) {
    return (
        <Button
            id={text}
            sx={{
                minWidth: '8rem',
                backgroundColor: 'secondary.main',
                color: 'black',

                '&:hover': {
                    backgroundColor: 'secondary.light',
                    color: 'black',
                },
                ...sx,
            }}
            variant='contained'
            onClick={submitHandler}
            disabled={isLoading || isDisabled}
        >
            {isLoading ? <CircularProgress size={30} /> : text}
        </Button>
    );
}

type LoadingButtonProps = ButtonProps & {
    isLoading: boolean;
    loadingSize?: number;
    disabled?: boolean;
} & (
        | {
              id?: string;
              text: string;
          }
        | {
              id: string;
              text?: string;
          }
    );

export function LoadingButton({ isLoading, loadingSize, text, disabled, id, ...buttonProps }: LoadingButtonProps) {
    return (
        <Button id={id ?? text} variant={'contained'} disabled={disabled || isLoading} {...buttonProps}>
            {isLoading ? <CircularProgress size={loadingSize ?? 30} /> : buttonProps?.children ?? text}
        </Button>
    );
}
