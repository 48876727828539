import { CLIENT_ID } from '@insight2profit/drive-app';
import { DataAccessPaginatedResponse, DataAccessResponse, IDataAccessService } from '@price-for-profit/micro-services';
import { DATABASE_LABEL, TABLE_PRODUCT_PRICES_LOCKS, VIEW_PRODUCT_PRICES_LOCKS } from 'shared/constants';
import { ITableProductPricesLocks, IViewProductPricesLocks } from 'shared/types';

export type ProductPricesLocksSoftEditParams = {
    isLocked: boolean;
    userDisplayName: string;
    oldViewRow?: IViewProductPricesLocks;
};

export interface IProductPricesLocksService {
    get(permittedBusinessLines: string[]): Promise<DataAccessPaginatedResponse<IViewProductPricesLocks>>;
    softEdit({
        isLocked,
        userDisplayName,
        oldViewRow,
    }: ProductPricesLocksSoftEditParams): Promise<{
        editResponse: DataAccessResponse<ITableProductPricesLocks>;
        addResponse: DataAccessResponse<ITableProductPricesLocks>;
    }>;
}

export class ProductPricesLocksService implements IProductPricesLocksService {
    constructor(private dasService: IDataAccessService) {}

    async get(permittedBusinessLines: string[]): Promise<DataAccessPaginatedResponse<IViewProductPricesLocks>> {
        if (!permittedBusinessLines.length)
            return {
                data: [],
                metadata: {
                    hasPrevious: false,
                    hasNext: false,
                    operationId: 'no permitted business lines',
                    requestUnits: 0.0,
                    elapsedTime: 0,
                    totalCount: 0,
                    totalPages: 0,
                    currentPage: 0,
                    pageSize: 0,
                },
            };
        return await this.dasService.getCollection<IViewProductPricesLocks, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: VIEW_PRODUCT_PRICES_LOCKS,
            page: 0,
            pageSize: 100,
            collectionFilter: {
                logicalOperator: 'or',
                filters: permittedBusinessLines.map(businessLine => ({
                    property: 'businessLine',
                    operator: 'eq',
                    value: businessLine,
                })),
            },
        });
    }

    async softEdit({
        isLocked,
        userDisplayName,
        oldViewRow,
    }: ProductPricesLocksSoftEditParams): Promise<{
        editResponse: DataAccessResponse<ITableProductPricesLocks>;
        addResponse: DataAccessResponse<ITableProductPricesLocks>;
    }> {
        const now = new Date().toISOString();

        if (!oldViewRow) throw new Error('Missing original lock status');

        const termedOldTableRow: ITableProductPricesLocks = this.mapViewToTable({
            viewRow: oldViewRow,
            effectiveEnd: now,
        });

        const modifiedNewTableRow = this.createNewRow({
            termedOldTableRow,
            isLocked,
            userDisplayName,
            effectiveStart: now,
        });

        this.softEditValidation({ newTableRow: modifiedNewTableRow, oldTableRow: termedOldTableRow });

        const editResponse = await this.dasService.updateRow<ITableProductPricesLocks, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_PRODUCT_PRICES_LOCKS,
            payload: termedOldTableRow,
        });

        const addResponse = await this.dasService.addRow<ITableProductPricesLocks, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_PRODUCT_PRICES_LOCKS,
            payload: modifiedNewTableRow,
        });

        return {
            editResponse,
            addResponse,
        };
    }

    private mapViewToTable({
        viewRow,
        effectiveEnd,
    }: {
        viewRow: IViewProductPricesLocks;
        effectiveEnd?: string;
    }): ITableProductPricesLocks {
        const newTableRow: ITableProductPricesLocks = {
            ...viewRow,
            effectiveEnd,
            deleted: false,
        };
        return newTableRow;
    }

    private createNewRow({
        termedOldTableRow,
        isLocked,
        userDisplayName,
        effectiveStart,
    }: {
        termedOldTableRow: ITableProductPricesLocks;
        isLocked: boolean;
        userDisplayName: string;
        effectiveStart: string;
    }): ITableProductPricesLocks {
        const newTableRow: ITableProductPricesLocks = {
            ...termedOldTableRow,
            id: 0,
            isLocked,
            modifiedBy: userDisplayName,
            effectiveStart: effectiveStart,
            effectiveEnd: undefined,
            deleted: false,
        };
        return newTableRow;
    }

    private softEditValidation({
        newTableRow,
        oldTableRow,
    }: {
        newTableRow: ITableProductPricesLocks;
        oldTableRow: ITableProductPricesLocks;
    }) {
        if (newTableRow.isLocked === oldTableRow.isLocked) {
            throw new Error('No change in lock status');
        }

        if (!newTableRow.modifiedBy) {
            throw new Error('Missing user name');
        }
    }
}
