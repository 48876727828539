import { Button, Fade, Menu, MenuItem, SxProps, Theme } from '@mui/material';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuOption } from 'shared/types';

interface DropdownMenuProps {
    defaultDisplay: string;
    title?: string;
    disabled?: boolean;
    menuOptions: MenuOption[];
    buttonSx?: SxProps<Theme> | undefined;
}

export function DropdownMenu({ defaultDisplay, title, disabled, menuOptions, buttonSx }: DropdownMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 'auto' }}>
            <Button
                id='dropdown-button'
                aria-controls={open ? 'dropdown-menu' : undefined}
                aria-haspopup='true'
                sx={buttonSx}
                variant='contained'
                disabled={disabled}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {title || defaultDisplay}
            </Button>
            <Menu
                id='dropdown-menu'
                MenuListProps={{
                    'aria-labelledby': 'dropdown-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {menuOptions.map((option, i) => (
                    <MenuItem
                        key={i}
                        disabled={option.disabled}
                        onClick={() => {
                            handleClose();
                            option.action(option.key);
                        }}
                    >
                        {option.value}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
