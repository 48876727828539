import { useService } from '@insight2profit/drive-app';
import { CircularProgress } from '@mui/material';
import { GridRenderEditCellParams, GridRowId, useGridApiContext } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { ObjectTyped } from '@price-for-profit/micro-services';
import { useMaterialUom } from 'shared/hooks';
import { IExchangeRates, useExchangeRates, useProductPricesLocks } from 'shared/providers';
import { IUomConversionService } from 'shared/services';
import { ITableMaterialUOMConversion } from 'shared/types';
import { convertProductPricesToForeignCurrencyValue, debounce, priceFromCMPercent } from 'shared/utility';
import { ValidatedRenderEditCell } from './ValidatedRenderEditCell';
import { DynamicEditableRenderCell } from './columnDefs';

type FieldKey =
    | 'regionalRevisedMinimum'
    | 'regionalRevisedTarget'
    | 'globalRevisedMinimum'
    | 'globalRevisedTarget'
    | 'regionalRevisedMinimumCMPercent'
    | 'regionalRevisedTargetCMPercent'
    | 'globalRevisedMinimumCMPercent'
    | 'globalRevisedTargetCMPercent';

export const ProductPricesInterdependentColumnsRenderEditCell = (params: GridRenderEditCellParams) => {
    const { id, field } = params;
    const apiRef = useGridApiContext();
    const { isForeignCurrency, exchangeRates } = useExchangeRates();
    const { isBusinessLineLocked } = useProductPricesLocks();
    const { uomConversionService } = useService();

    const { materialUomList, isLoading: isMaterialUomLoading } = useMaterialUom(params.row.materialId);

    const handleValueChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(event.target.value);
        if (!newValue || isNaN(newValue)) return;

        setInterdependentCellValues({
            newValue: newValue,
            id,
            field,
            apiRef,
            params,
            isForeignCurrency,
            exchangeRates,
            uomConversionService,
            materialUomList,
        });
    };
    const globalFields = [
        'globalRevisedMinimum',
        'globalRevisedTarget',
        'globalRevisedMinimumCMPercent',
        'globalRevisedTargetCMPercent',
    ];
    if (isBusinessLineLocked(params.row.businessLine).isLocked && globalFields.includes(params.field)) {
        return <DynamicEditableRenderCell {...params} />;
    }
    if (isMaterialUomLoading && params.field.includes('CMPercent')) {
        return <CircularProgress size={20} />;
    }
    return <ValidatedRenderEditCell {...params} onValueChange={handleValueChange} />;
};

interface SetInterdependentCellValuesParams {
    newValue: number;
    id: GridRowId;
    field: string;
    apiRef: React.MutableRefObject<GridApiPremium>;
    params: GridRenderEditCellParams;
    isForeignCurrency: boolean;
    exchangeRates: IExchangeRates;
    uomConversionService: IUomConversionService;
    materialUomList: ITableMaterialUOMConversion;
}

const setInterdependentCellValues = debounce(
    async ({
        newValue,
        id,
        field,
        apiRef,
        params,
        isForeignCurrency,
        exchangeRates,
        uomConversionService,
        materialUomList,
    }: SetInterdependentCellValuesParams) => {
        await setCmPercentRelatedFields({
            newValue,
            id,
            field,
            apiRef,
            params,
            isForeignCurrency,
            exchangeRates,
            uomConversionService,
            materialUomList,
        });

        await setValueOverrideField({ field, id, newValue, apiRef });
    },
    500
);

interface SetCmPercentRelatedFields {
    newValue: number;
    id: GridRowId;
    field: string;
    apiRef: React.MutableRefObject<GridApiPremium>;
    params: GridRenderEditCellParams;
    isForeignCurrency: boolean;
    exchangeRates: IExchangeRates;
    uomConversionService: IUomConversionService;
    materialUomList: ITableMaterialUOMConversion;
}

const setCmPercentRelatedFields = async ({
    newValue,
    id,
    field,
    apiRef,
    params,
    isForeignCurrency,
    exchangeRates,
    uomConversionService,
    materialUomList,
}: SetCmPercentRelatedFields) => {
    const isCmPercentField = cmPercentFields.includes(field as FieldKey);

    if (!isCmPercentField) return;
    if (!params.row.updatedCost) return;

    const cmPercentRelatedFields = cmPercentFieldMap[field as FieldKey];
    if (!cmPercentRelatedFields?.length) return;

    const latestUomDuringEdit = params.row.uom;

    const updatedCostKaiUsd = params.row.updatedCost;

    for (const cmPercentRelatedField of cmPercentRelatedFields) {
        const cmPercentRelatedFieldLatestValueDuringEdit = params.row[cmPercentRelatedField];
        if (!cmPercentRelatedFieldLatestValueDuringEdit) break;

        const currencyCorrectedupdatedCostKai = convertProductPricesToForeignCurrencyValue({
            usdPrice: updatedCostKaiUsd,
            exchangeRates,
            orgRegion: params.row.orgRegion,
            isForeignCurrency,
        });

        const currencyUomCorrectedUpdatedCost = uomConversionService.convertKaiToAnyUom({
            kaiPrice: currencyCorrectedupdatedCostKai,
            uom: latestUomDuringEdit,
            materialKaiKgConversionFactor: params.row.materialKaiKgConversionFactor,
            materialUomList,
        });

        const calculatedValue = priceFromCMPercent({
            updatedCost: currencyUomCorrectedUpdatedCost,
            cmPercent: newValue,
        });

        await apiRef.current.setEditCellValue({
            id,
            field: cmPercentRelatedField,
            value: calculatedValue,
        });
    }
};

interface SetValueOverrideFieldParams {
    field: string;
    id: GridRowId;
    newValue: number;
    apiRef: React.MutableRefObject<GridApiPremium>;
}

const setValueOverrideField = async ({ field, id, newValue, apiRef }: SetValueOverrideFieldParams) => {
    const isValueOverrideField = valueOverrideFields.includes(field as FieldKey);
    if (isValueOverrideField) {
        const valueOverrideField = valueOverrideFieldMap[field as FieldKey];
        if (!valueOverrideField) return;

        await apiRef.current.setEditCellValue({
            id,
            field: valueOverrideField,
            value: newValue,
        });
    }
};

const cmPercentFieldMap: Partial<Record<FieldKey, string[]>> = {
    regionalRevisedMinimumCMPercent: ['regionalRevisedMinimum'],
    regionalRevisedTargetCMPercent: ['regionalRevisedTarget'],
    globalRevisedMinimumCMPercent: ['globalRevisedMinimum', 'regionalRevisedMinimum'],
    globalRevisedTargetCMPercent: ['globalRevisedTarget', 'regionalRevisedTarget'],
};
const cmPercentFields = ObjectTyped.keys(cmPercentFieldMap);

const valueOverrideFieldMap: Partial<Record<FieldKey, FieldKey>> = {
    globalRevisedMinimum: 'regionalRevisedMinimum',
    globalRevisedTarget: 'regionalRevisedTarget',
    globalRevisedMinimumCMPercent: 'regionalRevisedMinimumCMPercent',
    globalRevisedTargetCMPercent: 'regionalRevisedTargetCMPercent',
};
const valueOverrideFields = ObjectTyped.keys(valueOverrideFieldMap);
