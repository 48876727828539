import { UseFormWatch } from 'react-hook-form';
import { ICustomerPricesMassActionForm } from 'shared/types';
import {
    isAmtChangeMassActionCheck,
    isPriceChangeMassActionCheck,
    isScaleChangeMassActionCheck,
    isValidityDatesChangeMassActionCheck,
} from './use-customer-prices-mass-action-field';

export function useCustomerPricesMassActionIsDisabled({
    watch,
}: {
    watch: UseFormWatch<ICustomerPricesMassActionForm>;
}) {
    const type = watch('type');
    const field = watch('field');
    const value = watch('value');

    const isPriceChangeMassAction = isPriceChangeMassActionCheck(type);
    const isScaleChangeMassAction = isScaleChangeMassActionCheck(type);
    const isAmtChangeMassAction = isAmtChangeMassActionCheck(type);
    const isValidityDatesChangeMassAction = isValidityDatesChangeMassActionCheck(type);

    if (!type) return true;
    if (
        isPriceChangeMassAction ||
        isScaleChangeMassAction ||
        isAmtChangeMassAction ||
        isValidityDatesChangeMassAction
    ) {
        if (!field) return true;
        if (!value) return true;
    }

    return false;
}
