import { SelectChangeEvent } from '@mui/material';
import { Control, FieldError } from 'react-hook-form';
import { FormNumberField, MuiSelect } from 'shared/components/forms';
import { useMassActionFormLogic } from 'shared/hooks';
import { FieldOption } from 'shared/hooks/massActions';
import { IProductPricesMassActionForm } from 'shared/types';

type PriceChangeFormsProps = {
    allowedFieldOptions: FieldOption[];
    selectedField: string;
    selectedType: string;
    fieldOnChangeHandler: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
    control: Control<IProductPricesMassActionForm, any>;
    errors: Partial<Record<keyof IProductPricesMassActionForm, FieldError | undefined>>;
    isValidated: boolean;
    isLoading: boolean;
};

export function PriceChangeForms({
    allowedFieldOptions,
    selectedField,
    selectedType,
    fieldOnChangeHandler,
    control,
    errors,
    isValidated,
    isLoading,
}: PriceChangeFormsProps) {
    const { determineLabel, validatePrice, determineNumberFieldType } = useMassActionFormLogic({
        selectedType,
        selectedField,
    });

    return (
        <>
            <MuiSelect
                options={allowedFieldOptions}
                width={'100%'}
                label='Field'
                value={selectedField}
                onChange={fieldOnChangeHandler}
                isDisabled={isValidated || isLoading}
            />
            {selectedField === 'globalRevisedMinimum' && (
                <FormNumberField
                    name={'globalRevisedMinimum'}
                    label={determineLabel('Global Revised Minimum')}
                    control={control}
                    rules={{
                        validate: validatePrice,
                        required: 'Value is required',
                    }}
                    fieldError={errors['globalRevisedMinimum']}
                    type={determineNumberFieldType()}
                    isDisabled={isValidated || isLoading}
                />
            )}
            {selectedField === 'globalRevisedTarget' && (
                <FormNumberField
                    name={'globalRevisedTarget'}
                    label={determineLabel('Global Revised Target')}
                    control={control}
                    rules={{
                        validate: validatePrice,
                        required: 'Value is required',
                    }}
                    fieldError={errors['globalRevisedTarget']}
                    type={determineNumberFieldType()}
                    isDisabled={isValidated || isLoading}
                />
            )}
            {selectedField === 'regionalRevisedMinimum' && (
                <FormNumberField
                    name={'regionalRevisedMinimum'}
                    label={determineLabel('Regional Revised Minimum')}
                    control={control}
                    rules={{
                        validate: validatePrice,
                        required: 'Value is required',
                    }}
                    fieldError={errors['regionalRevisedMinimum']}
                    type={determineNumberFieldType()}
                    isDisabled={isValidated || isLoading}
                />
            )}
            {selectedField === 'regionalRevisedTarget' && (
                <FormNumberField
                    name={'regionalRevisedTarget'}
                    label={determineLabel('Regional Revised Target')}
                    control={control}
                    rules={{
                        validate: validatePrice,
                        required: 'Value is required',
                    }}
                    fieldError={errors['regionalRevisedTarget']}
                    type={determineNumberFieldType()}
                    isDisabled={isValidated || isLoading}
                />
            )}
            {selectedField === 'globalRevisedMinimumCMPercent' && (
                <FormNumberField
                    name={'globalRevisedMinimumCMPercent'}
                    label={determineLabel('Global Revised Adjusted Margin %')}
                    control={control}
                    rules={{
                        required: 'Value is required',
                    }}
                    fieldError={errors['globalRevisedMinimumCMPercent']}
                    type={determineNumberFieldType()}
                    isDisabled={isValidated || isLoading}
                />
            )}
            {selectedField === 'globalRevisedTargetCMPercent' && (
                <FormNumberField
                    name={'globalRevisedTargetCMPercent'}
                    label={determineLabel('Global Revised Target Adjusted Margin %')}
                    control={control}
                    rules={{
                        required: 'Value is required',
                    }}
                    fieldError={errors['globalRevisedTargetCMPercent']}
                    type={determineNumberFieldType()}
                    isDisabled={isValidated || isLoading}
                />
            )}
            {selectedField === 'regionalRevisedMinimumCMPercent' && (
                <FormNumberField
                    name={'regionalRevisedMinimumCMPercent'}
                    label={determineLabel('Regional Revised Minimum Adjusted Margin %')}
                    control={control}
                    rules={{
                        required: 'Value is required',
                    }}
                    fieldError={errors['regionalRevisedMinimumCMPercent']}
                    type={determineNumberFieldType()}
                    isDisabled={isValidated || isLoading}
                />
            )}
            {selectedField === 'regionalRevisedTargetCMPercent' && (
                <FormNumberField
                    name={'regionalRevisedTargetCMPercent'}
                    label={determineLabel('Regional Revised Target Adjusted Margin %')}
                    control={control}
                    rules={{
                        required: 'Value is required',
                    }}
                    fieldError={errors['regionalRevisedTargetCMPercent']}
                    type={determineNumberFieldType()}
                    isDisabled={isValidated || isLoading}
                />
            )}
        </>
    );
}
