import { InputAdornment } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { FormTextField, FormTextFieldProps } from './FormTextField';

type FormNumberFieldProps<T extends FieldValues> = Omit<FormTextFieldProps<T>, 'gridColumn'> & {
    type?: 'money' | 'percent' | 'days' | 'number';
};

export function FormNumberField<T extends FieldValues>({
    label,
    name,
    control,
    rules,
    fieldError,
    type,
    isDisabled = false,
}: FormNumberFieldProps<T>) {
    return (
        <FormTextField
            name={name}
            label={label}
            control={control}
            rules={rules}
            componentProps={{
                type: 'number',
                startAdornment: type === 'money' ? <InputAdornment position='start'>$</InputAdornment> : undefined,
                endAdornment:
                    type === 'percent' ? (
                        <InputAdornment position={'end'}>%</InputAdornment>
                    ) : type === 'days' ? (
                        <InputAdornment position='end'>Days</InputAdornment>
                    ) : undefined,
            }}
            required={!!rules?.required}
            fieldError={fieldError}
            isDisabled={isDisabled}
        />
    );
}
