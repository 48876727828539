import { Box, useTheme } from '@mui/system';
import { PropsWithChildren } from 'react';
import { StyledTooltip } from 'shared/components/app/DataGridCells/StyledTooltip';

export function DropdownErrorToolTip({
    error,
    children,
    zIndexOverride = 0,
}: PropsWithChildren<{ error: string | boolean; zIndexOverride?: number }>) {
    const theme = useTheme();
    const determineCellStyle = () => {
        if (error) {
            return {
                backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
                color: theme.palette.error.main,
                minHeight: '100%',
            };
        }
        return {};
    };
    return (
        <StyledTooltip
            open={!!error}
            title={error ? error : ''}
            placement='bottom-end'
            arrow
            PopperProps={{ style: { zIndex: zIndexOverride } }}
        >
            <Box
                sx={{
                    minWidth: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...determineCellStyle(),
                }}
            >
                {children}
            </Box>
        </StyledTooltip>
    );
}
