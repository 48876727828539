import { Box, LinearProgress } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useI2pServerDataGrid } from '@price-for-profit/data-grid';
import { QUERY_KEYS } from 'shared/constants';
import { HEADER_HEIGHT, SLIM_DATA_GRID_STYLE } from 'shared/constants/dataGrid';
import { useService } from '@insight2profit/drive-app';
import { ITableCustomerPricesComments, IViewCustomerPrices } from 'shared/types';
import { longDateValueFormatter } from 'shared/utility';
import { CustomerPriceDrawerAddCommentForm } from './CustomerPriceDrawerAddCommentForm';

interface CustomerPriceDrawerCommentsProps {
    selectedCustomerPrice?: IViewCustomerPrices;
}

export function CustomerPriceDrawerComments({ selectedCustomerPrice }: CustomerPriceDrawerCommentsProps) {
    const { customerPricesCommentsService } = useService();
    const { getDataGridProps, invalidate } = useI2pServerDataGrid<ITableCustomerPricesComments>({
        columns: columns,
        name: `${QUERY_KEYS.customerPriceComments}.${selectedCustomerPrice?.materialId}.${selectedCustomerPrice?.orgRegion}.${selectedCustomerPrice?.shipToId}.${selectedCustomerPrice?.soldToId}`,
        queryOptions: {
            enabled: Boolean(
                selectedCustomerPrice?.materialId &&
                    selectedCustomerPrice?.orgRegion &&
                    selectedCustomerPrice?.shipToId &&
                    selectedCustomerPrice?.soldToId
            ),
        },
        getData: async state => {
            return customerPricesCommentsService.getComments({
                ...state,
                filterModel: {
                    items: [
                        {
                            columnField: 'materialId',
                            value: selectedCustomerPrice?.materialId,
                            operatorValue: 'equals',
                        },
                        { columnField: 'orgRegion', value: selectedCustomerPrice?.orgRegion, operatorValue: 'equals' },
                        { columnField: 'shipToId', value: selectedCustomerPrice?.shipToId, operatorValue: 'equals' },
                        { columnField: 'soldToId', value: selectedCustomerPrice?.soldToId, operatorValue: 'equals' },
                    ],
                },
            });
        },
        initialState: {
            pageSize: 10,
            sortModel: [{ field: 'effectiveStart', sort: 'desc' }],
        },
        dataGridInitialState: {
            pinnedColumns: { left: ['__check__', 'material'] },
        },
        rowsPerPageOptions: [10, 20, 50, 100],
    });
    const dataGridProps = getDataGridProps();

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <CustomerPriceDrawerAddCommentForm selectedCustomerPrice={selectedCustomerPrice} onSuccess={invalidate} />

            <DataGridPremium
                {...dataGridProps}
                sx={SLIM_DATA_GRID_STYLE}
                disableSelectionOnClick
                components={{
                    LoadingOverlay: LinearProgress,
                }}
                componentsProps={{
                    panel: {
                        sx: {
                            '& .MuiDataGrid-filterFormColumnInput': {
                                width: 'auto',
                            },
                        },
                    },
                }}
                disableVirtualization={false}
                headerHeight={HEADER_HEIGHT}
            />
        </Box>
    );
}
const columns = [
    {
        headerName: 'User Name',
        field: 'modifiedBy',
        width: 200,
    },
    {
        headerName: 'Comment',
        field: 'comment',
        width: 500,
    },
    {
        headerName: 'Date',
        field: 'effectiveStart',
        width: 200,
        valueFormatter: longDateValueFormatter,
    },
];
