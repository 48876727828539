import { useUser } from '@insight2profit/drive-app';
import { Alert, Button, Stack } from '@mui/material';
import Box from '@mui/system/Box';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormSelect, SaveButton } from 'shared/components/forms';
import { MASS_ACTION_TYPES } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { useProductPricesMassActionField } from 'shared/hooks/productPrices/use-product-prices-mass-action-field';
import { useProductPricesMassActionIsDisabled } from 'shared/hooks/productPrices/use-product-prices-mass-action-is-disabled';
import { useProductPricesMassActionResetForm } from 'shared/hooks/productPrices/use-product-prices-mass-action-reset-form';
import {
    useProductPricesApprovalMassActionMutation,
    useProductPricesPriceChangeMassActionMutation,
    useProductPricesPriceChangeMassActionValidationMutation,
    useProductPricesSubmitMassActionMutation,
} from 'shared/mutations';
import { IProductPricesMassActionForm } from 'shared/types';
import { formatCommaSeparatedNumber } from 'shared/utility';
import { GridToolbarMassAction, ModalHeader } from '../../mass-actions';
import { PriceChangeForms } from './PriceChangeForms';
import { ProductPricesApprovalChangeForms } from './ProductPricesApprovalChangeForms';
import { ProductPricesMassActionValidation } from './ProductPricesMassActionValidation';

type ProductPricesMassActionModalProps = {
    state: ServerSideState;
    invalidate: () => void;
    rowCount: number;
};

export function ProductPricesMassActionModal({ state, invalidate, rowCount }: ProductPricesMassActionModalProps) {
    const user = useUser();
    const { enqueueSnackbar } = useSnackbar();
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState<string>('');
    const { userApproverTier } = useUserPermissions();

    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
        resetField,
        reset,
    } = useForm<IProductPricesMassActionForm>({
        defaultValues: {
            type: MASS_ACTION_TYPES.AMOUNT,
            globalRevisedMinimum: '',
            globalRevisedTarget: '',
            regionalRevisedMinimum: '',
            regionalRevisedTarget: '',
            globalRevisedMinimumCMPercent: '',
            globalRevisedTargetCMPercent: '',
            regionalRevisedMinimumCMPercent: '',
            regionalRevisedTargetCMPercent: '',
            comment: '',
            modifiedBy: user?.displayName || '',
        },
    });

    const selectedType = watch('type');

    const { allowedFieldOptions, fieldOnChangeHandler, selectedField } = useProductPricesMassActionField(selectedType);

    const isDisabled = useProductPricesMassActionIsDisabled({ watch, selectedField, selectedType });
    useProductPricesMassActionResetForm({ resetField, selectedField, selectedType });

    const {
        mutation: massActionValidationMutation,
        onSubmitValidate,
        setValidationRows,
        validationRows,
        isValidated,
        setIsValidated,
    } = useProductPricesPriceChangeMassActionValidationMutation({
        productPricesState: state,
    });

    const {
        onSubmit: onSubmitPriceChangeMassAction,
        isLoading: isPriceChangeLoading,
    } = useProductPricesPriceChangeMassActionMutation({
        state: state,
        onSuccess: () => {
            handleCloseBothModals();
            setError('');
            invalidate();
            reset();
        },
        onError: error => {
            setError(error.message);
        },
    });

    const {
        onSubmit: onSubmitApprovalMassAction,
        isLoading: isApprovalLoading,
    } = useProductPricesApprovalMassActionMutation({
        state: state,
        onSuccess: () => {
            handleCloseBothModals();
            setError('');
            invalidate();
            reset();
        },
        onError: error => {
            setError(error.message);
        },
    });

    const {
        onSubmit: onSubmitSubmissionMassAction,
        isLoading: isSubmitMassActionLoading,
    } = useProductPricesSubmitMassActionMutation({
        state: state,
        onSuccess: () => {
            handleCloseBothModals();
            setError('');
            invalidate();
            reset();
        },
        onError: error => {
            setError(error.message);
        },
    });

    const handleCloseParentModal = () => {
        setIsOpen(false);
        reset();
        setIsValidated(false);
    };

    const handleCloseBothModals = () => {
        handleCloseParentModal();
        handleCloseChildModal();
    };

    const handleCloseChildModal = () => {
        setIsValidated(false);
        reset();
        setValidationRows([]);
    };

    const determineSubmitHandler: (data: IProductPricesMassActionForm) => void = data => {
        if (([MASS_ACTION_TYPES.APPROVE, MASS_ACTION_TYPES.REJECT] as string[]).includes(data.type)) {
            onSubmitApprovalMassAction(data);
        } else if (data.type === MASS_ACTION_TYPES.SUBMIT) {
            if (isValidated && validationRows.length === 0) {
                onSubmitSubmissionMassAction();
            }
        } else {
            onSubmitPriceChangeMassAction(data);
        }
    };

    const isPriceChangeMassAction = priceChangeMassActions.includes(selectedType);
    const isApprovalChangeMassAction = approvalChangeMassActions.includes(selectedType);
    const isSubmitMassAction = selectedType === MASS_ACTION_TYPES.SUBMIT;

    const allowedTypeOptions =
        userApproverTier >= 3
            ? typeOptions
            : typeOptions.filter(typeOption => !approvalChangeMassActions.includes(typeOption.value));

    return (
        <GridToolbarMassAction isOpen={isOpen} handleOpen={() => setIsOpen(true)} handleClose={handleCloseParentModal}>
            <ModalHeader handleClose={handleCloseParentModal}>Mass Action</ModalHeader>
            <Stack p={2} gap={2} sx={{ minWidth: '400px' }}>
                <FormSelect
                    options={allowedTypeOptions}
                    width={'100%'}
                    label='Price Change Type'
                    name='type'
                    control={control}
                    fieldError={errors['type']}
                    rules={{ required: 'Required' }}
                    isDisabled={isValidated || massActionValidationMutation.isLoading}
                />
                {isPriceChangeMassAction && (
                    <PriceChangeForms
                        {...{
                            allowedFieldOptions,
                            selectedField,
                            selectedType,
                            fieldOnChangeHandler,
                            control,
                            errors,
                            isValidated,
                            isLoading: massActionValidationMutation.isLoading,
                        }}
                    />
                )}
                {isApprovalChangeMassAction && (
                    <ProductPricesApprovalChangeForms control={control} errors={errors} isLoading={false} />
                )}
                <Box display='flex' justifyContent='space-between'>
                    <Box>Affected Records: </Box>
                    <Box>{formatCommaSeparatedNumber(rowCount)}</Box>
                </Box>
                {isApprovalChangeMassAction && (
                    <Alert severity='info'>Only rows requiring approval will be updated.</Alert>
                )}
                {isSubmitMassAction && <Alert severity='info'>Only unsubmitted rows will be submitted.</Alert>}
                {error && <Alert severity='error'>Save failed. Changes reverted. Please try again.</Alert>}
                {isValidated && validationRows.length === 0 && !error && (
                    <Alert severity='success'> Mass action successfully validated </Alert>
                )}
                <Box display='flex' justifyContent='space-between'>
                    <Button sx={{ color: 'secondary.main' }} onClick={handleCloseParentModal}>
                        Cancel
                    </Button>

                    {(isValidated && validationRows.length === 0) || isApprovalChangeMassAction ? (
                        <SaveButton
                            text='Submit'
                            submitHandler={handleSubmit(determineSubmitHandler)}
                            isLoading={isPriceChangeLoading || isApprovalLoading || isSubmitMassActionLoading}
                            isDisabled={
                                (validationRows.length > 0 || isDisabled) &&
                                !(isApprovalChangeMassAction || isSubmitMassAction)
                            }
                        />
                    ) : (
                        <SaveButton
                            text='validate'
                            submitHandler={handleSubmit((data: IProductPricesMassActionForm) => {
                                enqueueSnackbar('Validating mass action...', { variant: 'info' });
                                onSubmitValidate(data);
                            })}
                            isLoading={massActionValidationMutation.isLoading}
                        />
                    )}
                </Box>
            </Stack>
            <ProductPricesMassActionValidation
                isChildModalOpen={!massActionValidationMutation.isLoading && validationRows.length > 0}
                handleCloseChild={handleCloseChildModal}
                validationRows={validationRows}
            />
        </GridToolbarMassAction>
    );
}

const typeOptions = [
    MASS_ACTION_TYPES.AMOUNT,
    MASS_ACTION_TYPES.CHANGE_DOLLAR,
    MASS_ACTION_TYPES.CHANGE_PERCENT,
    MASS_ACTION_TYPES.INCREASE_PERCENT,
    MASS_ACTION_TYPES.APPROVE,
    MASS_ACTION_TYPES.REJECT,
    MASS_ACTION_TYPES.SUBMIT,
].map(value => ({
    value: value,
    displayText: value,
}));

const approvalChangeMassActions = [MASS_ACTION_TYPES.APPROVE, MASS_ACTION_TYPES.REJECT] as string[];

const priceChangeMassActions = [
    MASS_ACTION_TYPES.AMOUNT,
    MASS_ACTION_TYPES.CHANGE_DOLLAR,
    MASS_ACTION_TYPES.CHANGE_PERCENT,
    MASS_ACTION_TYPES.INCREASE_PERCENT,
] as string[];
