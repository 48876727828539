import { useEffect } from 'react';
import { UseFormResetField } from 'react-hook-form';
import { IProductPricesMassActionForm } from 'shared/types';

export function useProductPricesMassActionResetForm({
    resetField,
    selectedType,
    selectedField,
}: {
    resetField: UseFormResetField<IProductPricesMassActionForm>;
    selectedType: string;
    selectedField: string;
}) {
    useEffect(() => {
        resetField('globalRevisedMinimum');
        resetField('globalRevisedTarget');
        resetField('regionalRevisedMinimum');
        resetField('regionalRevisedTarget');
        resetField('globalRevisedMinimumCMPercent');
        resetField('globalRevisedTargetCMPercent');
        resetField('regionalRevisedMinimumCMPercent');
        resetField('regionalRevisedTargetCMPercent');
        resetField('comment');
    }, [selectedType, selectedField, resetField]);
}
