import { BUSINESS_LINE, productPriceStatuses } from 'shared/constants';
import { ITableProductPrices, IViewProductPrices } from 'shared/types';

type GetNewStatusParams = {
    oldTableRow: ITableProductPrices;
    userApproverTier: number;
    isLocked: boolean;
};

type CanCurrentUserApproveParams = {
    currentViewRow: IViewProductPrices;
    userApproverTier: number;
    isLocked: boolean;
};

export interface IProductPriceApprovalService {
    getSubmitStatus({
        oldTableRow,
        userApproverTier,
        isLocked,
    }: GetNewStatusParams): {
        isAutoApproved: boolean;
        approver: string;
        status: string;
    };
    canCurrentUserApprove({ currentViewRow, userApproverTier, isLocked }: CanCurrentUserApproveParams): boolean;
    determineStatusChange(changeType: string, status: string): string;
    getStatusFromEdit(
        oldRow: ITableProductPrices | IViewProductPrices,
        newRow: ITableProductPrices | IViewProductPrices
    ): string;
}

export class ProductPriceApprovalService implements IProductPriceApprovalService {
    private changeTypes = {
        APPROVED: 'Approved',
        REJECTED: 'Rejected',
        EDIT_GLOBAL: 'Edit-Global',
        EDIT_REGIONAL: 'Edit-Regional',
    };

    getSubmitStatus({ oldTableRow, userApproverTier, isLocked }: GetNewStatusParams) {
        const tier = this.determineTier({ row: oldTableRow, isLocked });
        const approver = this.determineApprover(tier);
        const isAutoApproved = this.isAutoApproved(tier, userApproverTier);
        const status = this.determineStatusDisplay(isLocked, isAutoApproved);

        return { isAutoApproved, approver, status };
    }

    canCurrentUserApprove({ currentViewRow, userApproverTier, isLocked }: CanCurrentUserApproveParams) {
        try {
            const tier = this.determineTier({ row: currentViewRow, isLocked });
            const updatedTier = userApproverTier >= tier ? 0 : tier;

            return updatedTier === 0;
        } catch (e) {}
        return false;
    }

    determineStatusChange(changeType: string, status: string = '') {
        if (changeType === this.changeTypes.APPROVED) {
            if (status === productPriceStatuses.GLOBAL_APPROVAL_REQUIRED) {
                return productPriceStatuses.GLOBAL_APPROVED;
            }
            if (status === productPriceStatuses.REGIONAL_APPROVAL_REQUIRED) {
                return productPriceStatuses.REGIONAL_APPROVED;
            }
        }

        if (changeType === this.changeTypes.REJECTED) {
            if (status === productPriceStatuses.GLOBAL_APPROVAL_REQUIRED) {
                return productPriceStatuses.GLOBAL_REJECTED;
            }
            if (status === productPriceStatuses.REGIONAL_APPROVAL_REQUIRED) {
                return productPriceStatuses.REGIONAL_REJECTED;
            }
        }

        if (changeType === this.changeTypes.EDIT_GLOBAL) {
            return productPriceStatuses.NEEDS_REVIEW;
        }

        if (changeType === this.changeTypes.EDIT_REGIONAL) {
            return productPriceStatuses.GLOBAL_APPROVED;
        }

        return status;
    }

    getStatusFromEdit(
        oldRow: ITableProductPrices | IViewProductPrices,
        newRow: ITableProductPrices | IViewProductPrices
    ) {
        if (
            oldRow.regionalRevisedMinimum !== newRow.regionalRevisedMinimum ||
            oldRow.regionalRevisedMinimumCMPercent !== newRow.regionalRevisedMinimumCMPercent ||
            oldRow.regionalRevisedTarget !== newRow.regionalRevisedTarget ||
            oldRow.regionalRevisedTargetCMPercent !== newRow.regionalRevisedTargetCMPercent
        ) {
            return this.determineStatusChange('Edit-Regional');
        }

        if (
            oldRow.globalRevisedMinimum !== newRow.globalRevisedMinimum ||
            oldRow.globalRevisedMinimumCMPercent !== newRow.globalRevisedMinimumCMPercent ||
            oldRow.globalRevisedTarget !== newRow.globalRevisedTarget ||
            oldRow.globalRevisedTargetCMPercent !== newRow.globalRevisedTargetCMPercent
        ) {
            return this.determineStatusChange('Edit-Global');
        }

        return oldRow.status || 'Error getting status from edit';
    }

    private determineTier({
        row,
        isLocked,
    }: {
        row: ITableProductPrices | IViewProductPrices;
        isLocked: boolean;
    }): number {
        const businessLineUpperCase = row.businessLine?.toUpperCase();

        let tier = 0;

        if (row.globalRevisedMinimum === undefined || row.globalRevisedMinimum === null)
            throw Error('Error determining required approval level. Missing Global Revised Minimum.');
        if (row.globalRevisedTarget === undefined || row.globalRevisedTarget === null)
            throw Error('Error determining required approval level. Missing Global Revised Target.');

        if (isLocked) {
            if (row.regionalRevisedMinimum === undefined || row.regionalRevisedMinimum === null)
                throw Error('Error determining required approval level. Missing Regional Revised Minimum.');
            if (row.regionalRevisedTarget === undefined || row.regionalRevisedTarget === null)
                throw Error('Error determining required approval level. Missing Regional Revised Target.');

            if (Number(row.regionalRevisedMinimum.toFixed(2)) < Number((row.updatedFloorPrice ?? 0).toFixed(2)))
                tier = Math.max(tier, 4);
            if (Number(row.regionalRevisedTarget.toFixed(2)) < Number((row.updatedFloorPrice ?? 0).toFixed(2)))
                tier = Math.max(tier, 4);

            if (!businessLineUpperCase)
                throw Error('Error determining required approval level. Missing Business Line.');
            if (businessLineUpperCase === BUSINESS_LINE.agAndFood) {
                if (Number(row.regionalRevisedMinimum.toFixed(2)) < Number(row.globalRevisedMinimum.toFixed(2)))
                    tier = Math.max(tier, 3);
                if (Number(row.regionalRevisedTarget.toFixed(2)) < Number(row.globalRevisedMinimum.toFixed(2)))
                    tier = Math.max(tier, 3);
            }
        } else {
            if (Number(row.globalRevisedMinimum.toFixed(2)) < Number((row.updatedFloorPrice ?? 0).toFixed(2)))
                tier = Math.max(tier, 4);
            if (Number(row?.globalRevisedTarget.toFixed(2)) < Number((row.updatedFloorPrice ?? 0).toFixed(2)))
                tier = Math.max(tier, 4);
        }

        return tier;
    }

    private isAutoApproved(requiredTier: number, userApproverTier: number): boolean {
        return requiredTier <= userApproverTier;
    }

    private determineApprover(tier: number): string {
        if (tier === 0) return 'None';
        if (tier === 1) return 'Regional Sales';
        if (tier === 2) return 'Regional Marketing';
        if (tier === 3) return 'Global Marketing';
        if (tier === 4) return 'Product Line Management';
        return 'Error getting tier';
    }

    private determineStatusDisplay(isLocked: boolean, isAutoApproved: boolean) {
        if (isLocked && isAutoApproved) {
            return productPriceStatuses.REGIONAL_APPROVED;
        }

        if (isLocked && !isAutoApproved) {
            return productPriceStatuses.REGIONAL_APPROVAL_REQUIRED;
        }

        if (!isLocked && isAutoApproved) {
            return productPriceStatuses.GLOBAL_APPROVED;
        }

        return productPriceStatuses.GLOBAL_APPROVAL_REQUIRED;
    }
}
