import { SxProps, TextField, Theme } from '@mui/material';
import { useController } from 'react-hook-form';
import { getCurrencySymbol } from 'shared/utility';

type FormMoneyFieldProps = {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
    label: string;
    prefix?: string;
    suffix?: string;
    rules?: Object;
    readOnly?: boolean;
    defaultValue?: string;
    fullWidth?: boolean;
    multiline?: boolean;
    rows?: number;
    disabled?: boolean;
    placeholder?: string;
    sx?: SxProps<Theme>;
    currency?: string;
    matchingExchangeRate?: number;
};

export function FormMoneyField({
    name,
    label,
    rules,
    rows,
    fullWidth = true,
    readOnly = false,
    defaultValue = '',
    multiline = false,
    disabled,
    placeholder,
    sx,
    currency,
    matchingExchangeRate,
}: FormMoneyFieldProps) {
    const {
        field: { value, ref, ...inputProps },
        fieldState: { error },
    } = useController({
        name,
        rules,
        defaultValue,
    });

    const documentCurrencyPlaceHolder = currency ?? 'USD';
    const regionalCurrencyStartAdornment = getCurrencySymbol(documentCurrencyPlaceHolder, true);

    const getConvertedValue = () => {
        if (documentCurrencyPlaceHolder !== 'USD' && matchingExchangeRate) {
            return value * matchingExchangeRate;
        }
        return value;
    };

    return (
        <TextField
            {...inputProps}
            id={name}
            inputRef={ref}
            type='number'
            variant='outlined'
            fullWidth={fullWidth}
            value={Number(getConvertedValue()).toFixed(2)}
            label={`${label}${rules && Object.keys(rules).includes('required') ? ' *' : ''}`}
            error={Boolean(error)}
            helperText={error?.message}
            multiline={multiline}
            rows={rows}
            disabled={disabled}
            placeholder={placeholder}
            InputProps={{
                readOnly,
                startAdornment: <span>{regionalCurrencyStartAdornment}</span>,
            }}
            sx={sx}
        />
    );
}
