import { Button, LinearProgress, Modal } from '@mui/material';
import { Box } from '@mui/system';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useI2pSimpleDataGrid } from '@price-for-profit/data-grid';
import { DATA_GRID_STYLE } from 'shared/constants/dataGrid';
import { IProductPricesPriceChangeMassActionValidation } from 'shared/types';
import { ModalHeader } from '../../mass-actions';

interface ProductPricesMassActionValidationProps {
    isChildModalOpen: boolean;
    handleCloseChild: () => void;
    validationRows: IProductPricesPriceChangeMassActionValidation[];
}

export function ProductPricesMassActionValidation({
    isChildModalOpen,
    handleCloseChild,
    validationRows,
}: ProductPricesMassActionValidationProps) {
    const { getDataGridProps } = useI2pSimpleDataGrid({
        name: 'ProductPricesMassActionValidation',
        columns: columns,
        getData: async () => {
            return validationRows;
        },
        dataGridInitialState: {
            pinnedColumns: { left: ['__check__', 'material'] },
        },
        rowsPerPageOptions: [10, 20, 50, 100],
    });

    const dataGridProps = getDataGridProps();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75vw',
        height: '95vh',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 1,
        px: 4,
        pb: 3,
    };

    return (
        <Modal open={isChildModalOpen} onClose={handleCloseChild}>
            <Box sx={{ ...style }}>
                <Box p={2} gap={2} sx={{ minWidth: '400px', height: '100%', flexDirection: 'column', display: 'flex' }}>
                    <ModalHeader handleClose={handleCloseChild}>Problem Records</ModalHeader>

                    <Box flexGrow={1}>
                        <DataGridPremium
                            {...dataGridProps}
                            rows={validationRows}
                            columns={columns}
                            sx={{ ...DATA_GRID_STYLE, height: '100%' }}
                            disableSelectionOnClick
                            components={{
                                LoadingOverlay: LinearProgress,
                                // NoRowsOverlay: ProductPricesValidationNoRowsOverlay
                            }}
                            componentsProps={{
                                panel: {
                                    sx: {
                                        '& .MuiDataGrid-filterFormColumnInput': {
                                            width: 'auto',
                                        },
                                    },
                                },
                            }}
                            disableVirtualization={true}
                            getRowId={row => row.massActionId}
                        />
                    </Box>

                    <Box display='flex' justifyContent='space-between'>
                        <Button sx={{ color: 'secondary.main' }} onClick={handleCloseChild}>
                            Back
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

const columns = [
    {
        headerName: 'ID',
        field: 'massActionId',
        hide: true,
    },
    {
        headerName: 'material',
        field: 'material',
    },
    {
        headerName: 'Material ID',
        field: 'materialId',
        width: 200,
    },
    {
        headerName: 'Org Region',
        field: 'orgRegion',
        width: 200,
    },
    {
        headerName: 'Application',
        field: 'application',
        width: 200,
    },
    {
        headerName: 'Old Value',
        field: 'oldValue',
    },
    {
        headerName: 'New Value',
        field: 'newValue',
    },
    {
        headerName: 'Invalidation Reason',
        field: 'invalidationReason',
        width: 200,
    },
];
