import { useService } from '@insight2profit/drive-app';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { ICustomerPricesQuoteForm } from 'shared/components/app/customerPrices/customerPricesQuote/CustomerPricesQuoteForm';
import { IViewCustomerPrices } from 'shared/types';
import { formatMoney } from 'shared/utility';

export function useCustomerPricesQuoteMutation() {
    const { customerPricesQuoteService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const mutation = useMutation<
        void,
        Error,
        { customerPricesQuoteForm: ICustomerPricesQuoteForm; allRows: IViewCustomerPrices[] }
    >({
        mutationKey: ['CustomerPricesQuoteMutation'],
        mutationFn: async ({ customerPricesQuoteForm, allRows }) => {
            const customerPricesQuote = customerPricesQuoteService.mapICustomerPricesQuoteFormToICustomerPricesQuote({
                customerPricesQuoteForm,
                totalValue: formatMoney(calcTotalValue(allRows)),
            });
            return await customerPricesQuoteService.generateQuote({ customerPricesQuote });
        },
        onSuccess: () => {
            enqueueSnackbar('Quote generated successfully', { variant: 'success' });
        },
        onError: error => {
            enqueueSnackbar('There was an error generating the quote', { variant: 'error' });
        },
    });
    return mutation;
}

const calcTotalValue = (allRows: IViewCustomerPrices[]) =>
    allRows.reduce((accum, row) => {
        return accum + (row?.revisedPrice ?? 0);
    }, 0);
