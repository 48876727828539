import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Modal, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

type GridToolbarMassActionProps = {
    isOpen: boolean;
    handleOpen: () => void;
    handleClose: () => void;
};

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxSizing: 'border-box',
    boxShadow: 24,
    p: '0.5',
    m: 0,
};

export function GridToolbarMassAction({
    children,
    isOpen,
    handleOpen,
    handleClose,
}: PropsWithChildren<GridToolbarMassActionProps>) {
    return (
        <>
            <Box display='flex' gap={1}>
                <Button
                    type='button'
                    size='small'
                    id='mass-action-button'
                    onClick={handleOpen}
                    startIcon={<EditIcon />}
                >
                    Mass Action
                </Button>
            </Box>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Stack sx={modalStyle}>
                    <Stack sx={{ p: '1rem', gap: 2 }}>{children}</Stack>
                </Stack>
            </Modal>
        </>
    );
}
