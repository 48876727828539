import { CLIENT_ID } from '@insight2profit/drive-app';
import { GridValidRowModel } from '@mui/x-data-grid-premium';
import {
    DataGetCollectionProps,
    IDataAccessService,
    IFilter,
    NoInfer,
    getData,
} from '@price-for-profit/micro-services';
import { DATABASE_LABEL } from 'shared/constants';

export type GetDropdownParams<T> =
    | (Pick<DataGetCollectionProps<T, typeof DATABASE_LABEL>, 'collectionFilter'> & {
          sortBy?: NoInfer<keyof T>;
          sortDescending?: boolean;
      })
    | undefined;

type GetSinglePageDropdownParams<T> =
    | (Pick<DataGetCollectionProps<T, typeof DATABASE_LABEL>, 'collectionFilter'> & {
          sortBy?: NoInfer<keyof T>;
          sortDescending?: boolean;
          pageSize?: number;
          page?: number;
      })
    | undefined;

export interface IDropDownService<T extends GridValidRowModel> {
    get(getDropdownParams?: GetDropdownParams<T>): Promise<T[]>;
    getSearchResults(searchColumn: string[], searchValue: string, additionalFilters?: IFilter<T>[]): Promise<T[]>;
}

export class DropDownService<T extends GridValidRowModel> {
    constructor(private dasService: IDataAccessService, private viewTableId: string) {}

    async get(getDropdownParams: GetDropdownParams<T> = undefined): Promise<T[]> {
        return await getData((page: number) => this.getSinglePage({ ...getDropdownParams, page }));
    }

    async getSearchResults(
        searchColumn: string[],
        searchValue: string,
        additionalFilters: IFilter<T>[] = []
    ): Promise<T[]> {
        const searchObj: IFilter<T>[] = searchColumn.map(column => {
            return {
                property: column,
                operator: 'contains',
                value: searchValue,
            };
        });

        const response = await this.getSinglePage({
            page: 0,
            pageSize: 20,
            sortBy: searchColumn[0],
            sortDescending: false,
            collectionFilter: {
                logicalOperator: 'and',
                filters: [
                    ...additionalFilters,
                    {
                        logicalOperator: 'or',
                        filters: [...searchObj],
                    },
                ],
            },
        });

        return response?.data || [];
    }

    private async getSinglePage(getSinglePageDropdownParams: GetSinglePageDropdownParams<T> = undefined) {
        return this.dasService.getCollection<T, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: this.viewTableId,
            page: getSinglePageDropdownParams?.page,
            pageSize: getSinglePageDropdownParams?.pageSize ?? 1000,
            sortBy: getSinglePageDropdownParams?.sortBy,
            sortDescending: getSinglePageDropdownParams?.sortDescending,
            collectionFilter: getSinglePageDropdownParams?.collectionFilter,
        });
    }
}
