import { CircularProgress } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useUserPermissions } from 'shared/hooks';
import { useCustomerPricesAccountManagerDropdown } from 'shared/queries/customerPricesDropdownQueries';
import { CustomerPricesAddNewAutocomplete } from './customerPricesAddNewAutocomplete';

export function CustomerPricesAccountManagerAutocomplete(params: GridRenderEditCellParams) {
    const { data, isLoading } = useCustomerPricesAccountManagerDropdown();
    const apiRef = useGridApiContext();

    const { customerPricesLevel } = useUserPermissions();

    const isDisabled = customerPricesLevel === 'row';

    if (isLoading) return <CircularProgress size={20} />;

    return (
        <CustomerPricesAddNewAutocomplete
            isLoading={isLoading}
            setEditCellValue={apiRef.current.setEditCellValue}
            rowId={params.row.id}
            field={params.field}
            value={params.value}
            options={data || []}
            error={params.error}
            placeholder='Account Manager'
            disabled={isDisabled}
        />
    );
}
