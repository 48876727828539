import { GridEditInputCell, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { DropdownErrorToolTip } from 'pages/productPrices/productPricesAddRecord/DropdownErrorToolTip';
import { PropsWithChildren } from 'react';

export const ValidatedRenderEditCell = ({ error, ...params }: GridRenderEditCellParams) => {
    return (
        <ValidatedRenderEditCellWrapper error={error}>
            <GridEditInputCell {...params} />
        </ValidatedRenderEditCellWrapper>
    );
};

export const ValidatedRenderEditCellWrapper = ({
    error,
    zIndexOverride,
    children,
}: PropsWithChildren<{ error: GridRenderEditCellParams['error']; zIndexOverride?: number }>) => {
    return (
        <DropdownErrorToolTip error={error} zIndexOverride={zIndexOverride}>
            {children}
        </DropdownErrorToolTip>
    );
};
