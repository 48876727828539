import { Control, FieldError } from 'react-hook-form';
import { FormTextField } from 'shared/components/forms';
import { IProductPricesMassActionForm } from 'shared/types';

type ProductPricesApprovalChangeFormsProps = {
    control: Control<IProductPricesMassActionForm, any>;
    errors: Partial<Record<keyof IProductPricesMassActionForm, FieldError | undefined>>;
    isLoading: boolean;
};

export function ProductPricesApprovalChangeForms({
    control,
    errors,
    isLoading,
}: ProductPricesApprovalChangeFormsProps) {
    return (
        <FormTextField
            name={'comment'}
            label={'Comment'}
            control={control}
            rules={{ maxLength: 1000, required: 'cannot be blank' }}
            fieldError={errors['comment']}
            isDisabled={isLoading}
        />
    );
}
