import { UseFormWatch } from 'react-hook-form';
import { IProductPricesMassActionForm } from 'shared/types';

export function useProductPricesMassActionIsDisabled({
    watch,
    selectedType,
    selectedField,
}: {
    watch: UseFormWatch<IProductPricesMassActionForm>;
    selectedType: string;
    selectedField: string;
}) {
    const globalRevisedMinimum = watch('globalRevisedMinimum');
    const globalRevisedTarget = watch('globalRevisedTarget');
    const regionalRevisedMinimum = watch('regionalRevisedMinimum');
    const regionalRevisedTarget = watch('regionalRevisedTarget');
    const globalRevisedMinimumCMPercent = watch('globalRevisedMinimumCMPercent');
    const globalRevisedTargetCMPercent = watch('globalRevisedTargetCMPercent');
    const regionalRevisedMinimumCMPercent = watch('regionalRevisedMinimumCMPercent');
    const regionalRevisedTargetCMPercent = watch('regionalRevisedTargetCMPercent');

    const isDisabled =
        !selectedType ||
        !selectedField ||
        (selectedField === 'globalRevisedMinimum' && !globalRevisedMinimum) ||
        (selectedField === 'globalRevisedTarget' && !globalRevisedTarget) ||
        (selectedField === 'regionalRevisedMinimum' && !regionalRevisedMinimum) ||
        (selectedField === 'regionalRevisedTarget' && !regionalRevisedTarget) ||
        (selectedField === 'globalRevisedMinimumCMPercent' && !globalRevisedMinimumCMPercent) ||
        (selectedField === 'globalRevisedTargetCMPercent' && !globalRevisedTargetCMPercent) ||
        (selectedField === 'regionalRevisedMinimumCMPercent' && !regionalRevisedMinimumCMPercent) ||
        (selectedField === 'regionalRevisedTargetCMPercent' && !regionalRevisedTargetCMPercent);
    return isDisabled;
}
