import { format } from 'date-fns/fp';

const moneyFormatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    minimumIntegerDigits: 1,
    currencyDisplay: 'symbol',
});

const MoneyNoDecimalFormatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumIntegerDigits: 1,
    currencyDisplay: 'symbol',
});

const numberNoDecimalFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0,
    minimumIntegerDigits: 1,
});

const commaSeparatedNumberFormatter = new Intl.NumberFormat('en-US');

const percentFormatter = new Intl.NumberFormat('en', {
    style: 'percent',
    minimumFractionDigits: 1,
    minimumIntegerDigits: 1,
});
const percentFormatter2Decimal = new Intl.NumberFormat('en', {
    style: 'percent',
    minimumFractionDigits: 2,
    minimumIntegerDigits: 1,
});

export const formatMoney = (value: number) => moneyFormatter.format(value);
export const formatMoneyNoDecimal = (value: number) => MoneyNoDecimalFormatter.format(value);
export const formatPercent = (value: number) => percentFormatter.format(value);
export const formatPercent2Decimal = (value: number) => percentFormatter2Decimal.format(value);
export const formatCommaSeparatedNumber = (value: number) => commaSeparatedNumberFormatter.format(value);
export const formatNumberNoDecimal = (value: number) => numberNoDecimalFormatter.format(value);

export const formatDate: (value: number | Date) => string = format('M/dd/yyyy');

export const formatDateWithTime: (value: number | Date) => string = format('MMddyyhhmm');

export const formatDateTimeSeconds: (value: number | Date) => string = format('yyyyMMddhhmmss');

export const determineColor = (value?: number) => {
    if (value === null || value === undefined) return 'black';
    if (value > 0) return 'green';
    if (value < 0) return 'red';
    return 'black';
};

export const formatLongDateString = (dateParam: string | undefined): string => {
    if (!dateParam) {
        return '';
    }
    const date = new Date(`${dateParam}Z`);
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
    });
    return `${dateString} ${timeString}`;
};

export const formatPmi = (value: number) => {
    if (value === undefined || value === null) {
        return '0.00';
    }
    return value.toFixed(2);
};

export const getCurrencySymbol = (currencyCode: string, isForeignCurrency: boolean) => {
    if (!isForeignCurrency) return '$';
    const formattedValue = new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency: currencyCode,
    }).formatToParts(0);

    let symbol = '';

    for (let part of formattedValue) {
        if (part.type === 'currency') {
            symbol += part.value;
        }
    }

    return symbol;
};

export function round(value: number, decimalPlaces = 0) {
    if (isNaN(value)) {
        return NaN;
    }

    const isNegative = value < 0;
    const valueToConvert = Math.abs(value);
    let roundingMultiplierString = '1';
    for (let i = 0; i < decimalPlaces; i++) {
        roundingMultiplierString = roundingMultiplierString.concat('0');
    }
    const roundingMultiplier = parseInt(roundingMultiplierString);
    const roundedAbsValue = Math.round((valueToConvert + Number.EPSILON) * roundingMultiplier) / roundingMultiplier;
    const roundedNumber = isNegative ? -roundedAbsValue : roundedAbsValue;

    return Number(roundedNumber.toFixed(decimalPlaces));
}
