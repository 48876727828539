import { createContext, useCallback, useContext } from 'react';
import { useCustomerProductEngineBatchMetaDataQuery } from 'shared/queries';

import { NoPropsJustChildren } from 'shared/types';

export const customerProductEngineBatchMetaDataContext = createContext<
    ReturnType<typeof useCustomerProductEngineBatchMetaDataQuery>
>({
    data: [],
    isLoading: false,
    invalidateQuery: async () => {},
});

export function ProvideCustomerProductEngineBatchMetaDatas({ children }: NoPropsJustChildren) {
    const customerProductEngineBatchMetaDatas = useCustomerProductEngineBatchMetaDataQuery();

    return (
        <customerProductEngineBatchMetaDataContext.Provider value={customerProductEngineBatchMetaDatas}>
            {children}
        </customerProductEngineBatchMetaDataContext.Provider>
    );
}

export function useCustomerProductEngineBatchMetaDatas() {
    const context = useContext(customerProductEngineBatchMetaDataContext);

    const isInProgress = useCallback(
        (businessLine: string): boolean => {
            const matchingBatch = context.data?.find(batch => {
                return batch.businessLine.toUpperCase() === businessLine.toUpperCase();
            });

            if (!matchingBatch) return false;
            return true;
        },
        [context.data]
    );

    return {
        ...context,
        isInProgress,
    };
}
