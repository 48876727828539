import { CustomerPricesMaterialDropDownObject } from './customerPrices';
import { ProductPricesMaterialDropDownObject } from './productPrices';

export type MapType<T, M> = {
    [I in keyof T]: M;
};

export type NoPropsJustChildren = { children: React.ReactNode };

export type DropDownOption = { key: string; value: string };

export type MenuOption = DropDownOption & {
    action: ({ ...args }?: any) => void;
    disabled: boolean;
};

export type KeyOfType<T, V> = keyof {
    [P in keyof T as T[P] extends V ? P : never]: any;
};

export const getCustomerPriceMaterialId = (material: CustomerPricesMaterialDropDownObject | string) => {
    if (typeof material === 'string') {
        return material.split('|')[0];
    } else {
        return material?.materialId;
    }
};

export const getProductPriceMaterialId = (material: ProductPricesMaterialDropDownObject | string) => {
    if (typeof material === 'string') {
        return material.split('|')[0];
    } else {
        return material?.materialId;
    }
};
