import { useService } from '@insight2profit/drive-app';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { QUERY_KEYS } from 'shared/constants';
import { ITableUOMConversion } from 'shared/types';

export function useUomDropdown() {
    const { enqueueSnackbar } = useSnackbar();
    const { uomConversionService } = useService();

    const { data, ...restOfQuery } = useQuery<ITableUOMConversion[]>({
        queryKey: [QUERY_KEYS.uomDropdown],
        queryFn: async () => {
            return uomConversionService.getUomConversions();
        },
        onError: () => {
            enqueueSnackbar('Failed to load UOM dropdown options', { variant: 'error' });
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });

    const uoms = useMemo(() => data ?? ([] as ITableUOMConversion[]), [data]);

    const uomValueOptions = useMemo(
        () =>
            uoms.map(({ uom, displayUnit }) => ({
                value: uom,
                label: displayUnit || uom,
            })),
        [uoms]
    );

    return {
        ...restOfQuery,
        data: data ?? ([] as ITableUOMConversion[]),
        uomValueOptions,
    };
}
