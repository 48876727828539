import { useService } from '@insight2profit/drive-app';
import { useMemo } from 'react';
import { QUERY_KEYS } from 'shared/constants';
import { useI2PQuery } from 'shared/hooks/use-i2p-query';
import { IViewCustomerProductEngineBatchMetaData } from 'shared/types';

const errorMessage = 'Failed to load product prices Locks';

export function useCustomerProductEngineBatchMetaDataQuery() {
    const { batchMetaDataService } = useService();

    const { data, isLoading, invalidateQuery } = useI2PQuery<IViewCustomerProductEngineBatchMetaData>({
        queryName: QUERY_KEYS.customerProductEngineBatchMetaDatas,
        queryFn: async () => {
            return batchMetaDataService.getCustomerProductEngineBatchMetaData();
        },
        errorMessage,
        refetchInterval: 5000,
    });

    return useMemo(() => {
        return {
            data,
            isLoading,
            invalidateQuery,
        };
    }, [data, isLoading, invalidateQuery]);
}
