import {
    ChangeReturn,
    DataAccessPaginatedResponse,
    DataExecuteNamedQueryProps,
    IDataAccessService,
} from '@price-for-profit/micro-services';

export interface INamedQueryService {
    executeNamedQuery<T, R = undefined>(
        params: Omit<DataExecuteNamedQueryProps<T>, 'clientId' | 'databaseLabel'>
    ): Promise<DataAccessPaginatedResponse<ChangeReturn<unknown, R>>>;
}

export class NamedQueryService implements INamedQueryService {
    constructor(private dasService: IDataAccessService, private clientId: string, private databaseLabel: string) {}

    public executeNamedQuery<T, R>(params: Omit<DataExecuteNamedQueryProps<T>, 'clientId' | 'databaseLabel'>) {
        return this.dasService.executeNamedQuery<T, R>({
            clientId: this.clientId,
            databaseLabel: this.databaseLabel,
            ...params,
        });
    }
}
