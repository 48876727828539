import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    CircularProgress,
    FilterOptionsState,
    SxProps,
    TextField,
    Theme,
} from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';
export interface CommonControlProps<T = unknown> {
    name: string;
    label: string;
    rules?: Object;
    helperText?: string;
    defaultValue?: string | number | boolean | T;
    fullWidth?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    add?: boolean;
}

interface SelectInputProps<T> extends CommonControlProps<T> {
    options: T[];
    getOptionLabel(option: T): string;
    getOptionSelected(option: T, value: T): boolean;
    getOptionDisabled?(option: T): boolean;
    renderTags?(value: T[], getTagProps: AutocompleteRenderGetTagProps): React.ReactNode;
    multiple?: boolean;
    loading?: boolean;
    disableCloseOnSelect?: boolean;
    disableClearable?: boolean;
    filterSelectedOptions?: boolean;
    initialValue?: T;
    noOptionsText?: string;
    sx?: SxProps<Theme>;
    filterOptions?(option: T[], params: FilterOptionsState<T>): T[];
}

export function SelectInput<T>({
    name,
    label,
    defaultValue,
    rules,
    helperText,
    options,
    fullWidth,
    multiple,
    loading,
    disabled,
    disableCloseOnSelect,
    disableClearable,
    filterSelectedOptions,
    initialValue,
    noOptionsText,
    getOptionLabel,
    getOptionSelected,
    getOptionDisabled,
    renderTags,
    sx,
    filterOptions,
}: SelectInputProps<T>) {
    const {
        field: { ref, value, onChange },
        fieldState: { error },
    } = useController({
        name,
        defaultValue,
        rules,
    });

    return (
        <Autocomplete
            ref={ref}
            value={value}
            multiple={multiple}
            id={name}
            onChange={(_, value, reason) => {
                if (value) {
                    onChange(value);
                } else if (reason === 'clear') {
                    onChange(multiple ? value : initialValue);
                }
            }}
            filterOptions={filterOptions}
            disabled={disabled}
            disableCloseOnSelect={disableCloseOnSelect}
            options={options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            getOptionDisabled={getOptionDisabled}
            noOptionsText={noOptionsText}
            disableClearable={disableClearable}
            renderTags={renderTags}
            filterSelectedOptions={filterSelectedOptions}
            fullWidth={fullWidth}
            renderInput={params => (
                <TextField
                    {...params}
                    sx={sx}
                    label={`${label}${rules && Object.keys(rules).includes('required') ? ' *' : ''}`}
                    error={Boolean(error)}
                    helperText={error?.message || helperText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: loading ? (
                            <CircularProgress color='inherit' size={20} />
                        ) : (
                            params.InputProps.endAdornment
                        ),
                    }}
                />
            )}
        />
    );
}
