import { useService } from '@insight2profit/drive-app';
import { LogicalFilter, RelationalFilter } from '@price-for-profit/micro-services';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERY_KEYS } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import {
    CustomerPricesMaterialDropDownObject,
    IVwDropDownCustomerPricesShipTo,
    IVwDropDownCustomerPricesSoldTo,
    IVwDropdownCustomerPricesAccountManagers,
    IVwDropdownCustomerPricesAddExistingSalesOrganizations,
    IVwDropdownCustomerPricesAddProspectiveSalesOrganizations,
    IVwDropdownCustomerPricesApplications,
    IVwDropdownCustomerPricesMarketSegments,
    IVwDropdownCustomerPricesOrgRegions,
} from 'shared/types';

export function useCustomerPricesSoldToDropdown({ searchValue }: { searchValue: string }) {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { customerPricesSoldToDropdownService } = useService();

    const { data, isLoading, isFetching } = useQuery<IVwDropDownCustomerPricesSoldTo[]>({
        queryKey: [QUERY_KEYS.customerPricesSoldToDropdown, searchValue],
        queryFn: async () =>
            customerPricesSoldToDropdownService.get({
                sortBy: 'soldTo',
                collectionFilter: {
                    logicalOperator: 'and',
                    filters: [
                        {
                            property: 'search',
                            operator: 'contains',
                            value: searchValue,
                        },
                    ],
                },
            }),
        onError: () => {
            enqueueSnackbar('Failed to load sold to customers');
        },
        keepPreviousData: true,
        enabled: !!searchValue,
    });

    return {
        data,
        isLoading,
        isFetching,
        invalidateQuery: () => queryClient.invalidateQueries([QUERY_KEYS.customerPricesSoldToDropdown]),
    };
}

export function useCustomerPricesShipToDropdown({ searchValue }: { searchValue: string }) {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { customerPricesShipToDropdownService } = useService();

    const { data, isLoading, isFetching } = useQuery<IVwDropDownCustomerPricesShipTo[]>({
        queryKey: [QUERY_KEYS.customerPricesShipToDropdown, searchValue],
        queryFn: async () =>
            customerPricesShipToDropdownService.get({
                sortBy: 'shipTo',
                collectionFilter: {
                    logicalOperator: 'and',
                    filters: [
                        {
                            property: 'search',
                            operator: 'contains',
                            value: searchValue,
                        },
                    ],
                },
            }),
        onError: () => {
            enqueueSnackbar('Failed to load ship to customers');
        },
        keepPreviousData: true,
        enabled: !!searchValue,
    });

    return {
        data,
        isLoading,
        isFetching,
        invalidateQuery: () => queryClient.invalidateQueries([QUERY_KEYS.customerPricesSoldToDropdown]),
    };
}

export function useCustomerPricesMaterialDropdown({
    searchValue,
    selectedBusinessLine,
}: {
    searchValue: string;
    selectedBusinessLine: string;
}) {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { customerPricesMaterialDropdownService } = useService();
    const {
        customerPricesPermittedRowLevels: {
            permitted: { orgRegions },
        },
    } = useUserPermissions();

    const { data, isLoading, isFetching } = useQuery<CustomerPricesMaterialDropDownObject[]>({
        queryKey: [QUERY_KEYS.customerPricesMaterialDropdown, searchValue],
        queryFn: async () => {
            if (!orgRegions.length) throw new Error('OrgRegion permissions missing');

            const vwDropdownResults = await customerPricesMaterialDropdownService.get({
                sortBy: 'materialId',
                collectionFilter: {
                    logicalOperator: 'and',
                    filters: [
                        {
                            property: 'search',
                            operator: 'contains',
                            value: searchValue,
                        },
                        {
                            property: 'businessLine',
                            operator: 'eq',
                            value: selectedBusinessLine,
                        },
                        {
                            logicalOperator: 'or',
                            filters: orgRegions.map(orgRegion => ({
                                property: 'orgRegion',
                                operator: 'eq',
                                value: orgRegion,
                            })),
                        },
                    ],
                },
            });

            const dropdownObjectStrings = vwDropdownResults.map(({ search, material, materialId }) =>
                JSON.stringify({ search, material, materialId })
            );
            const dropdownObjectStringsSet = new Set(dropdownObjectStrings);
            const dropdownOptions: CustomerPricesMaterialDropDownObject[] = Array.from(dropdownObjectStringsSet).map(
                dropdownObjectString => JSON.parse(dropdownObjectString) as CustomerPricesMaterialDropDownObject
            );

            return dropdownOptions;
        },
        onError: () => {
            enqueueSnackbar('Failed to load materials', {
                variant: 'error',
            });
        },
        keepPreviousData: true,
        enabled: !!searchValue && !!selectedBusinessLine && !!orgRegions.length,
    });

    return {
        data,
        isLoading,
        isFetching,
        invalidateQuery: () => queryClient.invalidateQueries([QUERY_KEYS.customerPricesMaterialDropdown]),
    };
}

export function useCustomerPricesOrgRegionDropdown({
    selectedBusinessLine,
    selectedMaterialId,
}: {
    selectedBusinessLine: string;
    selectedMaterialId: string;
}) {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { customerPricesOrgRegionDropdownService } = useService();
    const {
        permittedRowLevels: {
            permitted: { businessLines, orgRegions },
        },
    } = useUserPermissions();

    const { data, isLoading, isFetching } = useQuery<IVwDropdownCustomerPricesOrgRegions[]>({
        queryKey: [QUERY_KEYS.customerPriceOrgRegionDropdown, selectedBusinessLine, selectedMaterialId],
        queryFn: async () => {
            if (!businessLines.length || !orgRegions.length)
                return Promise.resolve([] as IVwDropdownCustomerPricesOrgRegions[]);

            return customerPricesOrgRegionDropdownService.get({
                sortBy: 'orgRegion',
                collectionFilter: {
                    logicalOperator: 'and',
                    filters: [
                        {
                            logicalOperator: 'or',
                            filters: [
                                {
                                    property: 'businessLine',
                                    operator: 'eq',
                                    value: selectedBusinessLine,
                                },
                            ],
                        },
                        {
                            logicalOperator: 'or',
                            filters: orgRegions.map(orgRegion => ({
                                property: 'orgRegion',
                                operator: 'eq',
                                value: orgRegion,
                            })),
                        },
                        {
                            property: 'materialId',
                            operator: 'eq',
                            value: selectedMaterialId,
                        },
                    ],
                },
            });
        },
        onError: () => {
            enqueueSnackbar('Failed to load org regions', {
                variant: 'error',
            });
        },
        keepPreviousData: true,
        enabled: !!selectedBusinessLine,
    });

    const orgRegionStrings =
        data?.map(anObject => {
            return anObject.orgRegion;
        }) ?? [];

    return {
        data: orgRegionStrings,
        isLoading,
        isFetching,
        invalidateQuery: () =>
            queryClient.invalidateQueries([
                QUERY_KEYS.customerPriceOrgRegionDropdown,
                selectedBusinessLine,
                selectedMaterialId,
            ]),
    };
}

export function useCustomerPricesApplicationDropdown({
    selectedMaterialId,
    selectedOrgRegion,
    selectedBusinessLine,
}: {
    selectedMaterialId: string;
    selectedOrgRegion: string;
    selectedBusinessLine: string;
}) {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { customerPricesApplicationDropdownService } = useService();

    const { data, isLoading, isFetching } = useQuery<IVwDropdownCustomerPricesApplications[]>({
        queryKey: [
            QUERY_KEYS.customerPriceApplicationDropdown,
            selectedMaterialId,
            selectedOrgRegion,
            selectedBusinessLine,
        ],
        queryFn: async () => {
            if (!selectedBusinessLine || !selectedOrgRegion || !selectedMaterialId)
                return Promise.resolve([] as IVwDropdownCustomerPricesApplications[]);

            return customerPricesApplicationDropdownService.get({
                sortBy: 'application',
                collectionFilter: {
                    logicalOperator: 'and',
                    filters: [
                        {
                            property: 'materialId',
                            operator: 'eq',
                            value: selectedMaterialId,
                        },
                        {
                            property: 'orgRegion',
                            operator: 'eq',
                            value: selectedOrgRegion,
                        },
                        {
                            property: 'businessLine',
                            operator: 'eq',
                            value: selectedBusinessLine,
                        },
                    ],
                },
            });
        },
        onError: () => {
            enqueueSnackbar('Failed to load applications', {
                variant: 'error',
            });
        },
        enabled: !!selectedMaterialId && !!selectedOrgRegion,
    });

    const applicationStrings =
        data?.map(anObject => {
            return anObject.application;
        }) ?? [];

    return {
        data: applicationStrings,
        isLoading,
        isFetching,
        invalidateQuery: () => queryClient.invalidateQueries([QUERY_KEYS.customerPriceApplicationDropdown]),
    };
}

export function useCustomerPricesMarketSegmentDropdown({
    materialId,
    orgRegion,
}: {
    materialId: string;
    orgRegion: string;
}) {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { customerPricesMarketSegmentDropdownService } = useService();

    const {
        permittedRowLevels: {
            permitted: { marketSegments },
        },
        marketRegionType: { isMarketRegionTypeExclusive, isMarketRegionTypeInclusive },
    } = useUserPermissions();

    const { data, isLoading, isFetching } = useQuery<IVwDropdownCustomerPricesMarketSegments[]>({
        queryKey: [QUERY_KEYS.customerPricesMarketSegmentsDropdown, materialId, orgRegion],
        queryFn: async () => {
            if ((isMarketRegionTypeExclusive || isMarketRegionTypeInclusive) && marketSegments.length === 0)
                return Promise.resolve([]);

            const selectedMaterialFilter: RelationalFilter<IVwDropdownCustomerPricesMarketSegments> = {
                property: 'materialId',
                operator: 'eq',
                value: materialId,
            };

            const selectedOrgRegionFilter: RelationalFilter<IVwDropdownCustomerPricesMarketSegments> = {
                property: 'orgRegion',
                operator: 'eq',
                value: orgRegion,
            };

            const marketSegmentFilters: LogicalFilter<IVwDropdownCustomerPricesMarketSegments> | undefined =
                isMarketRegionTypeExclusive || isMarketRegionTypeInclusive
                    ? {
                          logicalOperator: 'or',
                          filters: marketSegments.map(marketSegment => ({
                              property: 'marketSegment',
                              operator: 'eq',
                              value: marketSegment,
                          })),
                      }
                    : undefined;

            return customerPricesMarketSegmentDropdownService.get({
                sortBy: 'marketSegment',
                collectionFilter: {
                    logicalOperator: 'and',
                    filters: marketSegmentFilters
                        ? [selectedMaterialFilter, selectedOrgRegionFilter, marketSegmentFilters]
                        : [selectedOrgRegionFilter, selectedMaterialFilter],
                },
            });
        },
        onError: () => {
            enqueueSnackbar('Failed to load market segments', {
                variant: 'error',
            });
        },
        enabled: !!materialId && !!orgRegion,
    });

    const marketSegmentStrings =
        data?.map(anObject => {
            return anObject.marketSegment;
        }) ?? [];

    return {
        data: marketSegmentStrings,
        isLoading,
        isFetching,
        invalidateQuery: () => queryClient.invalidateQueries([QUERY_KEYS.customerPricesMarketSegmentsDropdown]),
    };
}

export function useCustomerPricesAddExistingSalesOrganizationDropdown({
    shipToId,
    soldToId,
}: {
    shipToId: string;
    soldToId: string;
}) {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { customerPricesAddExistingSalesOrganizationDropdownService } = useService();

    const { data, isLoading, isFetching } = useQuery<IVwDropdownCustomerPricesAddExistingSalesOrganizations[]>({
        queryKey: [QUERY_KEYS.customerPricesSalesOrganizationsAddExistingDropdown, shipToId, soldToId],
        queryFn: async () => {
            const uniqueOrganizations = new Set();
            const response = await customerPricesAddExistingSalesOrganizationDropdownService.get({
                sortBy: 'salesOrganization',
                collectionFilter: {
                    logicalOperator: 'or',
                    filters: [
                        {
                            property: 'customerNumber',
                            operator: 'eq',
                            value: shipToId,
                        },
                        {
                            property: 'customerNumber',
                            operator: 'eq',
                            value: soldToId,
                        },
                    ],
                },
            });

            const filteredResponse = response?.filter(org => {
                const isUnique = !uniqueOrganizations.has(org.salesOrganization);
                uniqueOrganizations.add(org.salesOrganization);
                return isUnique;
            });

            return filteredResponse;
        },
        onError: () => {
            enqueueSnackbar('Failed to load sales organizations');
        },
        keepPreviousData: true,
        enabled: !!shipToId && !!soldToId,
    });

    const salesOrgStrings =
        data?.map(anObject => {
            return anObject.salesOrganization;
        }) ?? [];

    return {
        data: salesOrgStrings,
        isLoading,
        isFetching,
        invalidateQuery: () =>
            queryClient.invalidateQueries([QUERY_KEYS.customerPricesSalesOrganizationsAddExistingDropdown]),
    };
}

export function useCustomerPricesAddProspectiveSalesOrganizationDropdown() {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { customerPricesAddProspectiveSalesOrganizationDropdownService } = useService();

    const { data, isLoading, isFetching } = useQuery<IVwDropdownCustomerPricesAddProspectiveSalesOrganizations[]>({
        queryKey: [QUERY_KEYS.customerPricesSalesOrganizationsAddProspectiveDropdown],
        queryFn: async () =>
            customerPricesAddProspectiveSalesOrganizationDropdownService.get({
                sortBy: 'salesOrganization',
            }),
        onError: () => {
            enqueueSnackbar('Failed to load sales organizations');
        },
        keepPreviousData: true,
    });

    const salesOrgStrings =
        data?.map(anObject => {
            return anObject.salesOrganization;
        }) ?? [];

    return {
        data: salesOrgStrings,
        isLoading,
        isFetching,
        invalidateQuery: () =>
            queryClient.invalidateQueries([QUERY_KEYS.customerPricesSalesOrganizationsAddProspectiveDropdown]),
    };
}

export function useCustomerPricesAccountManagerDropdown() {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { customerPricesAccountManagerDropdownService } = useService();

    const { data, isLoading, isFetching } = useQuery<IVwDropdownCustomerPricesAccountManagers[]>({
        queryKey: [QUERY_KEYS.customerPricesAccountManagersDropdown],
        queryFn: async () =>
            customerPricesAccountManagerDropdownService.get({
                sortBy: 'accountManager',
            }),
        onError: () => {
            enqueueSnackbar('Failed to load account managers');
        },
        keepPreviousData: true,
    });
    const accountManagerStrings = data?.map(aManager => {
        return aManager.accountManager;
    });

    return {
        data: accountManagerStrings,
        isLoading,
        isFetching,
        invalidateQuery: () => queryClient.invalidateQueries([QUERY_KEYS.customerPricesAccountManagersDropdown]),
    };
}
