import { useService, useUser, CLIENT_ID } from '@insight2profit/drive-app';
import { DataAccessResponse } from '@price-for-profit/micro-services';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { usePricesInvalidator } from 'shared/providers';
import { ITableCustomerPrices, IViewCustomerPrices } from 'shared/types';

export function useCustomerPriceApproveMutation() {
    const { customerPricesService, activityTrackingService, appConfig } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { invalidateQuery } = usePricesInvalidator();
    const user = useUser();
    const mutation = useMutation<
        {
            editResponse: DataAccessResponse<ITableCustomerPrices>;
            addResponse: DataAccessResponse<ITableCustomerPrices>;
            newViewRow: IViewCustomerPrices;
        },
        Error,
        IViewCustomerPrices
    >({
        mutationKey: ['ApproveCustomerPrice'],
        mutationFn: async row => {
            return await customerPricesService.approveRow(row, user?.displayName || '');
        },
        onSuccess: () => {
            enqueueSnackbar('Approval submitted', { variant: 'success' });
            invalidateQuery();
            activityTrackingService.trackStandardUserActivityEvent({
                action: 'ApprovePrice',
                user: {
                    displayName: user.displayName,
                    email: user.email,
                    id: user.id,
                },
                correlationId: '',
                eventType: 'ButtonClick',
                applicationId: appConfig.appId,
                clientId: CLIENT_ID,
            });
        },

        onError: error => {
            enqueueSnackbar('There was an error submitting the approval', { variant: 'error' });
        },
    });
    return mutation;
}
