import { useService } from '@insight2profit/drive-app';
import { LogicalFilter, RelationalFilter } from '@price-for-profit/micro-services';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERY_KEYS } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { useProductPricesLocks } from 'shared/providers';
import {
    IVwDropdownProductPricesApplications,
    IVwDropdownProductPricesMarketSegments,
    IVwDropdownProductPricesMaterials,
    IVwDropdownProductPricesOrgRegions,
} from 'shared/types';

export function useBusinessLineDropdown() {
    const {
        permittedRowLevels: {
            permitted: { businessLines },
        },
    } = useUserPermissions();

    const { isLoading, isFetching, invalidateQuery, isBusinessLineLocked } = useProductPricesLocks();

    return {
        data: businessLines
            .filter(businessLine => {
                const data = isBusinessLineLocked(businessLine);
                return !data.isLocked;
            })
            .map(businessLine => ({
                value: businessLine,
                label: businessLine.toUpperCase(),
            })),
        isLoading,
        isFetching,
        invalidateQuery,
    };
}

export function useProductPricesMaterialDropdown({
    searchValue,
    selectedBusinessLine,
}: {
    searchValue: string;
    selectedBusinessLine: string;
}) {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { productPricesMaterialDropdownService } = useService();

    const { data, isLoading, isFetching } = useQuery<IVwDropdownProductPricesMaterials[]>({
        queryKey: [QUERY_KEYS.productPricesMaterialDropdown, searchValue, selectedBusinessLine],
        queryFn: async () =>
            productPricesMaterialDropdownService.get({
                sortBy: 'materialId',
                collectionFilter: {
                    logicalOperator: 'and',
                    filters: [
                        {
                            property: 'search',
                            operator: 'contains',
                            value: searchValue,
                        },
                        {
                            property: 'businessLine',
                            operator: 'contains',
                            value: selectedBusinessLine,
                        },
                    ],
                },
            }),
        onError: () => {
            enqueueSnackbar('Failed to load materials', {
                variant: 'error',
            });
        },
        keepPreviousData: true,
        enabled: !!searchValue && !!selectedBusinessLine,
    });

    return {
        data,
        isLoading,
        isFetching,
        invalidateQuery: () => queryClient.invalidateQueries([QUERY_KEYS.productPricesMaterialDropdown]),
    };
}

export function useProductPricesMarketSegmentDropdown({ selectedBusinessLine }: { selectedBusinessLine: string }) {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { productPricesMarketSegmentDropdownService } = useService();
    const {
        permittedRowLevels: {
            permitted: { marketSegments },
        },
        marketRegionType: { isMarketRegionTypeExclusive, isMarketRegionTypeInclusive },
    } = useUserPermissions();

    const { data, isLoading, isFetching } = useQuery<IVwDropdownProductPricesMarketSegments[]>({
        queryKey: [QUERY_KEYS.productPricesMarketSegmentDropdown, selectedBusinessLine],
        queryFn: async () => {
            if ((isMarketRegionTypeExclusive || isMarketRegionTypeInclusive) && marketSegments.length === 0)
                return Promise.resolve([]);

            const selectedBusinessLineFilter: RelationalFilter<IVwDropdownProductPricesMarketSegments> = {
                property: 'businessLine',
                operator: 'eq',
                value: selectedBusinessLine,
            };

            const marketSegmentFilters: LogicalFilter<IVwDropdownProductPricesMarketSegments> | undefined =
                isMarketRegionTypeExclusive || isMarketRegionTypeInclusive
                    ? {
                          logicalOperator: 'or',
                          filters: marketSegments.map(marketSegment => ({
                              property: 'marketSegment',
                              operator: 'eq',
                              value: marketSegment,
                          })),
                      }
                    : undefined;

            return productPricesMarketSegmentDropdownService.get({
                sortBy: 'marketSegment',
                collectionFilter: {
                    logicalOperator: 'and',
                    filters: marketSegmentFilters
                        ? [selectedBusinessLineFilter, marketSegmentFilters]
                        : [selectedBusinessLineFilter],
                },
            });
        },
        onError: () => {
            enqueueSnackbar('Failed to load market segments', {
                variant: 'error',
            });
        },
        enabled: !!selectedBusinessLine,
    });

    const marketSegmentStrings =
        data?.map(anObject => {
            return anObject.marketSegment;
        }) ?? [];

    return {
        data: marketSegmentStrings,
        isLoading,
        isFetching,
        invalidateQuery: () => queryClient.invalidateQueries([QUERY_KEYS.productPricesMarketSegmentDropdown]),
    };
}

export function useProductPricesApplicationDropdown({ selectedBusinessLine }: { selectedBusinessLine: string }) {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { productPricesApplicationDropdownService } = useService();

    const { data, isLoading, isFetching } = useQuery<IVwDropdownProductPricesApplications[]>({
        queryKey: [QUERY_KEYS.productPricesApplicationDropdown, selectedBusinessLine],
        queryFn: async () =>
            productPricesApplicationDropdownService.get({
                sortBy: 'application',
                collectionFilter: {
                    logicalOperator: 'and',
                    filters: [
                        {
                            logicalOperator: 'or',
                            filters: [
                                {
                                    property: 'businessLine',
                                    operator: 'eq',
                                    value: selectedBusinessLine,
                                },
                            ],
                        },
                    ],
                },
            }),
        onError: () => {
            enqueueSnackbar('Failed to load applications', {
                variant: 'error',
            });
        },
        enabled: !!selectedBusinessLine,
    });

    const applicationStrings =
        data?.map(anObject => {
            return anObject.application;
        }) ?? [];

    return {
        data: applicationStrings,
        isLoading,
        isFetching,
        invalidateQuery: () => queryClient.invalidateQueries([QUERY_KEYS.productPricesApplicationDropdown]),
    };
}

export function useProductPricesOrgRegionDropdown({ selectedBusinessLine }: { selectedBusinessLine: string }) {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { productPricesOrgRegionDropdownService } = useService();
    const {
        permittedRowLevels: {
            permitted: { businessLines, orgRegions },
        },
    } = useUserPermissions();

    const { data, isLoading, isFetching } = useQuery<IVwDropdownProductPricesOrgRegions[]>({
        queryKey: [QUERY_KEYS.productPricesOrgRegionDropdown, selectedBusinessLine],
        queryFn: async () => {
            if (!businessLines.length || !orgRegions.length)
                return Promise.resolve([] as IVwDropdownProductPricesOrgRegions[]);

            return productPricesOrgRegionDropdownService.get({
                sortBy: 'orgRegion',
                collectionFilter: {
                    logicalOperator: 'and',
                    filters: [
                        {
                            logicalOperator: 'or',
                            filters: [
                                {
                                    property: 'businessLine',
                                    operator: 'eq',
                                    value: selectedBusinessLine,
                                },
                            ],
                        },
                        {
                            logicalOperator: 'or',
                            filters: orgRegions.map(orgRegion => ({
                                property: 'orgRegion',
                                operator: 'eq',
                                value: orgRegion,
                            })),
                        },
                    ],
                },
            });
        },
        onError: () => {
            enqueueSnackbar('Failed to load org regions', {
                variant: 'error',
            });
        },
        keepPreviousData: true,
        enabled: !!selectedBusinessLine,
    });

    const orgRegionStrings =
        data?.map(anObject => {
            return anObject.orgRegion;
        }) ?? [];

    return {
        data: orgRegionStrings,
        isLoading,
        isFetching,
        invalidateQuery: () =>
            queryClient.invalidateQueries([QUERY_KEYS.productPricesOrgRegionDropdown, selectedBusinessLine]),
    };
}
