import { CLIENT_ID } from '@insight2profit/drive-app';
import { DataAccessPaginatedResponse, IDataAccessService } from '@price-for-profit/micro-services';
import { DATABASE_LABEL, QUERY_NAME_PRODUCT_PRICES_SUMMARY } from 'shared/constants';
import { IProductPricesSummaryResponse } from 'shared/types/productPricesSummary';

export interface IGetProductPricesSummaryProps {
    regions: string;
    businessLines: string;
    marketSegments: string;
    marketRegionType: string;
}

export interface IProductPricesSummaryService {
    getProductPricesSummary(
        parameters: IGetProductPricesSummaryProps
    ): Promise<DataAccessPaginatedResponse<IProductPricesSummaryResponse>>;
}

export class ProductPricesSummaryService implements IProductPricesSummaryService {
    constructor(private dasService: IDataAccessService) {}

    async getProductPricesSummary(
        parameters: IGetProductPricesSummaryProps
    ): Promise<DataAccessPaginatedResponse<IProductPricesSummaryResponse>> {
        return await this.dasService.executeNamedQuery<{}, IProductPricesSummaryResponse>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            queryName: QUERY_NAME_PRODUCT_PRICES_SUMMARY,
            parameters,
        });
    }
}
