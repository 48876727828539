import { TextField } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

export function GridEditDateCell({
    id,
    field,
    value,
    error,
    ...rest
}: GridRenderEditCellParams & Partial<DatePickerProps<any, Date>>) {
    const apiRef = useGridApiContext();

    const handleChange = (newValue: unknown) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
        <DatePicker
            value={value}
            autoFocus
            onChange={handleChange}
            renderInput={params => <TextField {...params} error={error} />}
            {...rest}
        />
    );
}
