import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PropsWithChildren } from 'react';

export const ModalHeader = ({
    title,
    children,
    handleClose,
}: PropsWithChildren<{ title?: string; handleClose: () => void }>) => {
    return (
        <Box display='flex' alignItems={'center'} justifyContent='space-between'>
            <Typography
                id='modal-modal-title'
                variant='h6'
                sx={{
                    fontWeight: '700',
                    padding: '0.25rem',
                }}
            >
                {title ? title : children}
            </Typography>
            <IconButton onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </Box>
    );
};
