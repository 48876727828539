import { CircularProgress } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';

import { useCustomerPricesAddExistingSalesOrganizationDropdown } from 'shared/queries/customerPricesDropdownQueries';
import { CustomerPricesAddNewAutocomplete } from '../customerPricesAddNewAutocomplete';
import { getShipToId } from '../customerPricesShipToAutocomplete';
import { getSoldToId } from '../customerPricesSoldToAutocomplete';

export function CustomerPricesAddExistingSalesOrganizationAutoComplete(params: GridRenderEditCellParams) {
    const soldToId = getSoldToId(params?.row?.soldTo);
    const shipToId = getShipToId(params?.row?.shipTo);

    const { data, isLoading } = useCustomerPricesAddExistingSalesOrganizationDropdown({
        shipToId,
        soldToId,
    });
    const apiRef = useGridApiContext();
    if (!soldToId || !shipToId) return <div></div>;

    if (isLoading) return <CircularProgress size={20} />;

    return (
        <CustomerPricesAddNewAutocomplete
            isLoading={isLoading}
            setEditCellValue={apiRef.current.setEditCellValue}
            rowId={params.row.id}
            field={params.field}
            value={params.value}
            options={data}
            error={params.error}
            placeholder='Sales Organization'
        />
    );
}
