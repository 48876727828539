import { useService } from '@insight2profit/drive-app';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useUserPermissions } from 'shared/hooks';
import { useProductPricesSummary } from 'shared/providers';
import { IProductPricesMassActionForm, IProductPricesPriceChangeMassActionParameters } from 'shared/types';

export type ProductPricesPriceChangeMassActionMutationParams = {
    state: ServerSideState;
    parameters: IProductPricesPriceChangeMassActionParameters;
};

export type UseProductPricesPriceChangeMassActionMutationProps = {
    state: ServerSideState;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

export function useProductPricesPriceChangeMassActionMutation({
    state,
    onSuccess,
    onError,
}: UseProductPricesPriceChangeMassActionMutationProps) {
    const { productPricesService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { invalidateQuery: invalidateSummaryQuery } = useProductPricesSummary();
    const { permittedRowLevels } = useUserPermissions();

    const mutation = useMutation<void, Error, ProductPricesPriceChangeMassActionMutationParams>({
        mutationKey: ['productPricesPriceChangeMassAction'],
        mutationFn: async ({ state, parameters }) => {
            return await productPricesService.priceChangeMassAction({
                state,
                permittedRowLevels,
                parameters,
            });
        },
        onSuccess: () => {
            enqueueSnackbar('Mass action successfully completed', { variant: 'success' });
            onSuccess?.();
            invalidateSummaryQuery();
        },
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            onError?.(error);
        },
    });

    const onSubmit = (form: IProductPricesMassActionForm) => {
        mutation.mutate({
            state,
            parameters: productPricesFormToPriceChangeParameterMapper(form),
        });
    };

    return { onSubmit, isLoading: mutation.isLoading };
}

export const productPricesFormToPriceChangeParameterMapper = (
    form: IProductPricesMassActionForm
): IProductPricesPriceChangeMassActionParameters =>
    ({
        Type: form.type as IProductPricesPriceChangeMassActionParameters['Type'],
        GlobalRevisedMinimum: form.globalRevisedMinimum ? Number(form.globalRevisedMinimum) : undefined,
        GlobalRevisedTarget: form.globalRevisedTarget ? Number(form.globalRevisedTarget) : undefined,
        RegionalRevisedMinimum: form.regionalRevisedMinimum ? Number(form.regionalRevisedMinimum) : undefined,
        RegionalRevisedTarget: form.regionalRevisedTarget ? Number(form.regionalRevisedTarget) : undefined,
        GlobalRevisedMinimumCMPercent: form.globalRevisedMinimumCMPercent
            ? Number(form.globalRevisedMinimumCMPercent)
            : undefined,
        GlobalRevisedTargetCMPercent: form.globalRevisedTargetCMPercent
            ? Number(form.globalRevisedTargetCMPercent)
            : undefined,
        RegionalRevisedMinimumCMPercent: form.regionalRevisedMinimumCMPercent
            ? Number(form.regionalRevisedMinimumCMPercent)
            : undefined,
        RegionalRevisedTargetCMPercent: form.regionalRevisedTargetCMPercent
            ? Number(form.regionalRevisedTargetCMPercent)
            : undefined,
        ModifiedBy: form.modifiedBy,
    } as IProductPricesPriceChangeMassActionParameters);
