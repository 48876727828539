import { TextField } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { Control, FieldError, FieldValues, Path, RegisterOptions, useController } from 'react-hook-form';

type FormDateFieldProps<T extends FieldValues> = {
    label: string;
    name: Path<T>;
    control: Control<T>;
    componentProps?: {
        type?: string;
        startAdornment?: JSX.Element;
        endAdornment?: JSX.Element;
    };
    rules?: Omit<RegisterOptions<T>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    required?: boolean;
    fieldError?: FieldError;
    isDisabled?: boolean;
};

export function FormDateField<T extends FieldValues>({
    label,
    name,
    control,
    rules = {},
    fieldError,
    isDisabled = false,
}: FormDateFieldProps<T> & Partial<DatePickerProps<any, Date>>) {
    const { field } = useController({
        name,
        control,
        rules,
    });

    return (
        <DatePicker
            {...field}
            autoFocus
            disabled={isDisabled}
            renderInput={params => (
                <TextField
                    sx={{ width: 1 }}
                    {...params}
                    error={!!fieldError}
                    label={label + (!!rules.required ? ' *' : '')}
                    helperText={fieldError?.message ? (label || 'This') + ' ' + fieldError?.message : null}
                    disabled={isDisabled}
                />
            )}
        />
    );
}
