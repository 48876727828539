import { useMemo } from 'react';
import { PERMISSIONS, REGION } from 'shared/constants';
import { AppPermission } from 'shared/types';

export function useRegions({ hasPermission }: { hasPermission: (appPermission: AppPermission) => boolean }) {
    const isPfEmea = useMemo(() => hasPermission(PERMISSIONS.region.pfEmea), [hasPermission]);
    const isPfEurope = useMemo(() => hasPermission(PERMISSIONS.region.pfEurope), [hasPermission]);
    const isPfMea = useMemo(() => hasPermission(PERMISSIONS.region.pfMea), [hasPermission]);
    const isPfIndia = useMemo(() => hasPermission(PERMISSIONS.region.pfIndia), [hasPermission]);
    const isPfNeAsia = useMemo(() => hasPermission(PERMISSIONS.region.pfNeAsia), [hasPermission]);
    const isPfNorthAmerica = useMemo(() => hasPermission(PERMISSIONS.region.pfNorthAmerica), [hasPermission]);
    const isPfSeAsia = useMemo(() => hasPermission(PERMISSIONS.region.pfSeAsia), [hasPermission]);
    const isPfSouthAmerica = useMemo(() => hasPermission(PERMISSIONS.region.pfSouthAmerica), [hasPermission]);
    const isTsAsiaPacific = useMemo(() => hasPermission(PERMISSIONS.region.tsAsiaPacific), [hasPermission]);
    const isTsEmea = useMemo(() => hasPermission(PERMISSIONS.region.tsEmea), [hasPermission]);
    const isTsNorthAmerica = useMemo(() => hasPermission(PERMISSIONS.region.tsNorthAmerica), [hasPermission]);
    const isTsSouthAmerica = useMemo(() => hasPermission(PERMISSIONS.region.tsSouthAmerica), [hasPermission]);

    const permittedRegions = useMemo(
        () =>
            [
                { name: REGION.pfEmea, isAllowed: isPfEmea },
                { name: REGION.pfEurope, isAllowed: isPfEurope },
                { name: REGION.pfMea, isAllowed: isPfMea },
                { name: REGION.pfIndia, isAllowed: isPfIndia },
                { name: REGION.pfNeAsia, isAllowed: isPfNeAsia },
                { name: REGION.pfNorthAmerica, isAllowed: isPfNorthAmerica },
                { name: REGION.pfSeAsia, isAllowed: isPfSeAsia },
                { name: REGION.pfSouthAmerica, isAllowed: isPfSouthAmerica },
                { name: REGION.tsAsiaPacific, isAllowed: isTsAsiaPacific },
                { name: REGION.tsEmea, isAllowed: isTsEmea },
                { name: REGION.tsNorthAmerica, isAllowed: isTsNorthAmerica },
                { name: REGION.tsSouthAmerica, isAllowed: isTsSouthAmerica },
            ]
                .filter(region => region.isAllowed)
                .map(region => region.name),
        [
            isPfEmea,
            isPfEurope,
            isPfMea,
            isPfIndia,
            isPfNeAsia,
            isPfNorthAmerica,
            isPfSeAsia,
            isPfSouthAmerica,
            isTsAsiaPacific,
            isTsEmea,
            isTsNorthAmerica,
            isTsSouthAmerica,
        ]
    );

    return {
        permittedRegions,
        isPfEmea,
        isPfEurope,
        isPfMea,
        isPfIndia,
        isPfNeAsia,
        isPfNorthAmerica,
        isPfSeAsia,
        isPfSouthAmerica,
        isTsAsiaPacific,
        isTsEmea,
        isTsNorthAmerica,
        isTsSouthAmerica,
    };
}
