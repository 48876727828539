import { useService } from '@insight2profit/drive-app';
import { Box, CircularProgress, SelectChangeEvent } from '@mui/material';
import { GridEditSingleSelectCell, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import { useMaterialUom } from 'shared/hooks';
import { useExchangeRates } from 'shared/providers';

type FieldKey = 'uom' | 'revisedPrice' | 'freightCost';

export const CustomerPriceEditUOM = (props: GridRenderEditCellParams) => {
    const { customerPricesService } = useService();
    const { isForeignCurrency } = useExchangeRates();
    const queryClient = useQueryClient();
    const { materialUomValueOptions, isLoading: isMaterialUomLoading } = useMaterialUom(props.row.materialId);
    const { id, value, field, row } = props;

    const apiRef = useGridApiContext();

    const handleValueChange = async (event: SelectChangeEvent<unknown>) => {
        const newUom = event.target.value as string;

        const oldRow = apiRef.current.getRow(row.id);
        if (!props.row.isNew && oldRow.uom === newUom && field === 'revisedPrice') {
            if (fieldsRelationships[field as FieldKey] && !isForeignCurrency) {
                for (const fieldRelationShip of fieldsRelationships[field as FieldKey]!) {
                    await apiRef.current.setEditCellValue({
                        id,
                        field: fieldRelationShip,
                        value: oldRow[fieldRelationShip],
                    });
                }
            }
        }
        if (!props.row.isNew || (field === 'revisedPrice' && oldRow.uom !== newUom)) {
            const convertUomToUom = await customerPricesService.getConvertUomToUom(
                value,
                newUom,
                props.row.materialId,
                queryClient
            );

            if (fieldsRelationships[field as FieldKey] && !isForeignCurrency) {
                for (const fieldRelationShip of fieldsRelationships[field as FieldKey]!) {
                    const priceValue = row[fieldRelationShip];
                    if (!!priceValue) {
                        let newPrice = convertUomToUom(priceValue);
                        await apiRef.current.setEditCellValue({
                            id,
                            field: fieldRelationShip,
                            value: newPrice,
                        });
                    }
                }
            }
        }
    };

    if (isMaterialUomLoading) {
        return (
            <Box display='flex' justifyContent={'space-between'} alignItems='center' width='100%' p={2}>
                <Box>{props.value}</Box>
                <CircularProgress size={20} />
            </Box>
        );
    }

    return (
        <GridEditSingleSelectCell
            {...props}
            colDef={{ ...props.colDef, valueOptions: materialUomValueOptions }}
            onValueChange={handleValueChange}
        />
    );
};

const fieldsRelationships: Partial<Record<FieldKey, string[]>> = {
    uom: ['revisedPrice', 'amount01', 'amount02', 'amount03', 'amount04', 'amount05', 'amount06'],
};
