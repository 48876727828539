import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Control, FieldValues, Path, RegisterOptions, useController } from 'react-hook-form';
import { SelectOption } from 'shared/types';

export interface FormAutocompleteProps<T extends FieldValues> {
    fullWidth?: boolean;
    width?: string;
    label: string;
    name: Path<T>;
    control: Control<T>;
    rules?: Omit<RegisterOptions<T>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    options: SelectOption[];
    isDisabled?: boolean;
}

export function FormAutocomplete<T extends FieldValues>({
    fullWidth,
    width,
    label,
    options,
    name,
    control,
    rules,
    isDisabled,
}: FormAutocompleteProps<T>) {
    const { field } = useController({
        name,
        control,
        rules,
    });
    const { onChange, onBlur, value } = field;

    const selectedOption = options.find(option => option.value === value) || null;
    return (
        <FormControl sx={{ width: fullWidth ? null : width }} fullWidth={fullWidth}>
            <Autocomplete
                disablePortal
                getOptionLabel={option => option.displayText}
                isOptionEqualToValue={(option, selectedOption) => option.value === selectedOption?.value}
                disabled={isDisabled}
                options={options}
                renderInput={params => <TextField {...params} label={label} />}
                onChange={(_event, newValue) => {
                    onChange(newValue?.value || null);
                }}
                onBlur={onBlur}
                value={selectedOption}
            />
        </FormControl>
    );
}
