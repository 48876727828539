import { CircularProgress } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useCustomerPricesMarketSegmentDropdown } from 'shared/queries/customerPricesDropdownQueries';
import { getCustomerPriceMaterialId } from 'shared/types';
import { CustomerPricesAddNewAutocomplete } from './customerPricesAddNewAutocomplete';

export function CustomerPricesMarketSegmentAutocomplete(params: GridRenderEditCellParams) {
    const materialId = getCustomerPriceMaterialId(params?.row?.material);
    const orgRegion = params?.row?.orgRegion;

    const { data, isLoading } = useCustomerPricesMarketSegmentDropdown({
        materialId,
        orgRegion,
    });
    const apiRef = useGridApiContext();

    if (!materialId || !orgRegion) return <div></div>;

    if (isLoading) return <CircularProgress size={20} />;

    return (
        <CustomerPricesAddNewAutocomplete
            isLoading={isLoading}
            setEditCellValue={apiRef.current.setEditCellValue}
            rowId={params.row.id}
            field={params.field}
            value={params.value}
            options={data}
            error={params.error}
            placeholder='Market Segment'
        />
    );
}
