import { EventData } from '@price-for-profit/micro-services';
import { useMutation } from '@tanstack/react-query';
import { useAnalyticsPrerequisites } from '../providers';
import { TrackViewPageParams } from '../types';

export function useTrackViewPageMutation() {
    const { analyticsReportService } = useAnalyticsPrerequisites();

    return useMutation<EventData, Error, TrackViewPageParams>({
        mutationKey: ['trackViewPage'],
        mutationFn: async params => {
            return await analyticsReportService.trackViewPage(params);
        },
    });
}
