import { Box, Button } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { productPriceStatuses } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { useProductPricesSubmitMutation } from 'shared/mutations';
import { useCustomerProductEngineBatchMetaDatas, useProductPricesLocks } from 'shared/providers';
import { IViewProductPrices } from 'shared/types';

export function ProductPricesSubmitAction({ params }: { params: GridRowParams<IViewProductPrices> }) {
    const { isInProgress, isLoading: batchMetadataIsLoading } = useCustomerProductEngineBatchMetaDatas();

    const { isBusinessLineLocked, isLoading } = useProductPricesLocks();
    const { isLocked } = isBusinessLineLocked(params.row.businessLine || 'Problem getting businessLine');
    const { userApproverTier } = useUserPermissions();

    const { onSubmit, isLoading: isBusinessLineLockLoading } = useProductPricesSubmitMutation({ isLocked });

    if (params.row.status === 'Regional Approved') return <Box>Submitted</Box>;

    const isMissingPrices =
        !params.row.globalRevisedMinimum ||
        !params.row.globalRevisedTarget ||
        !params.row.regionalRevisedMinimum ||
        !params.row.regionalRevisedTarget;
    const isSubmitAllowed =
        !isMissingPrices &&
        !isInProgress(params.row.businessLine) &&
        !batchMetadataIsLoading &&
        ((params.row.status === productPriceStatuses.NEEDS_REVIEW && !isLocked && userApproverTier >= 3) ||
            (params.row.status === productPriceStatuses.GLOBAL_APPROVED && isLocked && userApproverTier >= 2));

    return (
        <Button
            variant='contained'
            onClick={() => {
                onSubmit(params.row);
            }}
            disabled={isLoading || isBusinessLineLockLoading || !isSubmitAllowed}
        >
            Submit
        </Button>
    );
}
