import { useService, useUser } from '@insight2profit/drive-app';
import { Delete } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import {
    DataGridPremium,
    GridActionsCellItem,
    GridEnrichedColDef,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    getGridSingleSelectOperators,
} from '@mui/x-data-grid-premium';
import { useI2pDataGridEdit } from '@price-for-profit/data-grid';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { ROUTES } from 'routing';
import { AddRecordCopyButton } from 'shared/components/app/AddRecordCopyButton';
import { AddRecordToolbar } from 'shared/components/app/AddRecordToolbar';
import { DATA_GRID_STYLE, HEADER_HEIGHT } from 'shared/constants/dataGrid';
import { useUserPermissions } from 'shared/hooks';
import { IAddExistingSAPCustomerPrices, getCustomerPriceMaterialId } from 'shared/types';
import { distinctObjectArray } from 'shared/utility';
import {
    CustomerPricesAccountManagerAutocomplete,
    CustomerPricesApplicationAutocomplete,
    CustomerPricesMarketSegmentAutocomplete,
    CustomerPricesMaterialAutocomplete,
    CustomerPricesOrgRegionAutocomplete,
    CustomerPricesShipToAutocomplete,
    CustomerPricesSoldToAutocomplete,
    getShipToId,
    getSoldToId,
} from '..';
import { CustomerPricesEditBusinessLineCell } from '../customerPricesEditBusinessLineCell';
import { CustomerPricesAddExistingSalesOrganizationAutoComplete } from './customerPricesAddExistingSalesOrganizationAutocomplete';
import { useNewExistingCustomerPricesReducer } from './useNewExistingCustomerPricesReducer';

export function CustomerPriceAddExisting() {
    const [rows, dispatchRows] = useNewExistingCustomerPricesReducer();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [isAddNewRow, setIsAddNewRow] = useState<string | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const {
        permittedRowLevels: {
            permitted: { businessLines },
        },
        marketRegionType: { isMarketRegionTypeInclusive },
    } = useUserPermissions();
    const { customerPricesLevel } = useUserPermissions();
    const { customerPricesAddExistingSAPService } = useService();

    const isAccountManager = customerPricesLevel === 'row';
    const user = useUser();

    const distinctRows = distinctObjectArray(
        rows.map(aRow => {
            return {
                ...aRow,
                id: '',
                material: getCustomerPriceMaterialId(aRow.material ?? ''),
                shipTo: getShipToId(aRow.shipTo ?? ''),
                soldTo: getSoldToId(aRow.soldTo ?? ''),
            };
        })
    );
    const containsDuplicateRows = distinctRows.length !== rows.length;

    const newSAPCustomerPrice: IAddExistingSAPCustomerPrices = {
        id: 'new',
        businessLine: '',
        newRecordId: null,
        soldTo: null,
        shipTo: null,
        material: null,
        orgRegion: null,
        application: null,
        marketSegment: null,
        salesOrganization: null,
        customerServiceRep: null,
        accountManager: isAccountManager ? user.displayName : null,
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleAddRecord = () => {
        const newProductPriceId = `new-${getNewId()}`;
        setIsAddNewRow(newProductPriceId);
        setRowModesModel(oldModel => ({
            [newProductPriceId]: { mode: GridRowModes.Edit, fieldToFocus: 'businessLine' },
        }));
    };

    const handleCopyRecord = (copiedRowId: string) => {
        const copiedRecordNewRowId = `new-${getNewId()}`;
        dispatchRows({ type: 'copy', newRowId: copiedRecordNewRowId, copiedRowId });
        setIsAddNewRow(null);
        setRowModesModel(oldModel => ({
            [copiedRecordNewRowId]: { mode: GridRowModes.Edit, fieldToFocus: 'businessLine' },
        }));
    };

    const columns: GridEnrichedColDef<IAddExistingSAPCustomerPrices>[] = [
        {
            headerName: 'ID',
            field: 'dataGridId',
            hide: true,
            headerAlign: 'center',
            align: 'center',
            description: 'Unique ID identifier for each specific row in the application',
        },
        {
            headerName: 'Business Line',
            field: 'businessLine',
            flex: 1,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            type: 'singleSelect',
            filterOperators: getGridSingleSelectOperators().filter(operator => ['is', 'not'].includes(operator.value)),
            valueOptions: businessLines.map(businessLine => ({
                value: businessLine,
                label: businessLine.toUpperCase(),
            })),
            preProcessEditCellProps: params => {
                return {
                    ...params.props,
                    error: !params.props.value ? 'Business Line is required' : false,
                };
            },
            renderEditCell: params => {
                return <CustomerPricesEditBusinessLineCell {...params} />;
            },
            renderCell: params => params.row.businessLine?.toUpperCase(),
        },
        {
            headerName: 'Sold to Customer',
            field: 'soldTo',
            flex: 1,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            valueGetter: params => {
                if (typeof params.value === 'string') return params.value;
                return params.value?.search;
            },
            preProcessEditCellProps: params => ({
                ...params.props,
                error: !params.props.value ? 'Sold to Customer is required' : false,
            }),
            renderEditCell: params => <CustomerPricesSoldToAutocomplete {...params} />,
        },
        {
            headerName: 'Ship to Customer',
            field: 'shipTo',
            flex: 1,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            valueGetter: params => {
                if (typeof params.value === 'string') return params.value;
                return params.value?.search;
            },
            preProcessEditCellProps: params => ({
                ...params.props,
                error: !params.props.value ? 'Ship to Customer is required' : false,
            }),
            renderEditCell: params => <CustomerPricesShipToAutocomplete {...params} />,
        },
        {
            headerName: 'Material',
            field: 'material',
            flex: 1,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            valueGetter: params => {
                if (typeof params.value === 'string') return params.value;
                return params.value?.search;
            },
            preProcessEditCellProps: params => ({
                ...params.props,
                error: !params.props.value ? 'Material is required' : false,
            }),
            renderEditCell: params => <CustomerPricesMaterialAutocomplete {...params} />,
        },
        {
            headerName: 'Org Region',
            field: 'orgRegion',
            flex: 1,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            type: 'singleSelect',
            filterOperators: getGridSingleSelectOperators().filter(operator => ['is', 'not'].includes(operator.value)),
            valueOptions: [],
            preProcessEditCellProps: params => ({
                ...params.props,
                error: !params.props.value ? 'Org Region is required' : false,
            }),
            renderEditCell: params => {
                return <CustomerPricesOrgRegionAutocomplete {...params} />;
            },
        },
        {
            headerName: 'Application',
            field: 'application',
            flex: 1,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            preProcessEditCellProps: params => ({
                ...params.props,
                error: !params.props.value ? 'Application is required' : false,
            }),
            renderEditCell: params => {
                return <CustomerPricesApplicationAutocomplete {...params} />;
            },
        },
        {
            headerName: 'Market Segment',
            field: 'marketSegment',
            flex: 1,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            preProcessEditCellProps: params => ({
                ...params.props,
                error: isMarketRegionTypeInclusive && !params.props.value ? 'Market Segment is required' : false,
            }),
            renderEditCell: params => {
                return <CustomerPricesMarketSegmentAutocomplete {...params} />;
            },
        },
        {
            headerName: 'Sales Organization',
            field: 'salesOrganization',
            flex: 1,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            preProcessEditCellProps: params => ({
                ...params.props,
                error: !params.props.value ? 'Sales Organization is required' : false,
            }),
            renderEditCell: params => {
                return <CustomerPricesAddExistingSalesOrganizationAutoComplete {...params} />;
            },
        },
        {
            headerName: 'Customer Service Rep',
            field: 'customerServiceRep',
            flex: 1,
            editable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            headerName: 'Account Manager',
            field: 'accountManager',
            flex: 1,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            preProcessEditCellProps: params => ({
                ...params.props,
                error: !params.props.value ? 'Account Manager is required' : false,
            }),
            renderEditCell: params => {
                return <CustomerPricesAccountManagerAutocomplete {...params} />;
            },
        },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params: GridRowParams<IAddExistingSAPCustomerPrices>) => [
                <GridActionsCellItem
                    icon={<Delete />}
                    label='Delete'
                    onClick={() => {
                        if (params.row.id === isAddNewRow) {
                            setIsAddNewRow(null);
                        }
                        dispatchRows({ type: 'delete', id: params.row.id });
                    }}
                />,
                <AddRecordCopyButton copyHandler={handleCopyRecord} rowId={params.row.id} />,
            ],
        },
    ];

    const rowsPlusNew = [...rows, { ...newSAPCustomerPrice, id: isAddNewRow }];

    const updatedRows = isAddNewRow ? rowsPlusNew : rows;

    const { getDataGridEditProps } = useI2pDataGridEdit<IAddExistingSAPCustomerPrices>({
        onEdit: async (newViewRow, oldRow) => {
            if (newViewRow.id.startsWith('new') && isAddNewRow) {
                if (JSON.stringify(newSAPCustomerPrice) === JSON.stringify(newViewRow)) {
                    setIsAddNewRow(null);
                    enqueueSnackbar('Add record cancelled', { variant: 'warning' });
                    return newViewRow;
                }
                if (!isValid(newViewRow, isAddNewRow)) {
                    enqueueSnackbar('Record invalid', { variant: 'warning' });
                    return newViewRow;
                }
                dispatchRows({ type: 'add', newViewRow });
                setIsAddNewRow(null);
                return newViewRow;
            }
            dispatchRows({ type: 'edit', newViewRow });
            return newViewRow;
        },
    });
    const dataGridEditProps = getDataGridEditProps();

    return (
        <DataGridPremium
            {...dataGridEditProps}
            rows={updatedRows}
            sx={DATA_GRID_STYLE}
            columns={columns}
            disableSelectionOnClick
            components={{
                LoadingOverlay: LinearProgress,
                Toolbar: AddRecordToolbar,
            }}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            componentsProps={{
                panel: {
                    sx: {
                        '& .MuiDataGrid-filterFormColumnInput': {
                            width: 'auto',
                        },
                    },
                },
                toolbar: {
                    isAddNewRow,
                    setIsAddNewRow,
                    handleAddRecord,
                    rows,
                    isLoading: false,
                    addNewRecordsService: customerPricesAddExistingSAPService,
                    successRoute: ROUTES.customerPrices,
                    name: 'customer-prices-existing',
                    containsDuplicateRows,
                },
            }}
            disableVirtualization={false}
            headerHeight={HEADER_HEIGHT}
            getRowId={row => row.id}
            experimentalFeatures={{ newEditingApi: true }}
            editMode='row'
        />
    );
}
const getNewId = () => {
    let now = new Date();
    let stringDateId = now.toISOString();
    return stringDateId;
};

function isValid(newViewRow: IAddExistingSAPCustomerPrices, newSAPCustomerPrice: string) {
    if (newViewRow.id !== newSAPCustomerPrice) return false;
    if (!newViewRow.businessLine) return false;
    if (!newViewRow.material) return false;
    if (!newViewRow.orgRegion) return false;
    if (!newViewRow.application) return false;
    if (!newViewRow.salesOrganization) return false;
    if (!newViewRow.accountManager) return false;

    return true;
}
