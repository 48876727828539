import { useMemo } from 'react';
import { MARKET_SEGMENT, PERMISSIONS } from 'shared/constants';
import { AppPermission } from 'shared/types';

export function useMarketSegments({ hasPermission }: { hasPermission: (appPermission: AppPermission) => boolean }) {
    const isNotAssigned = useMemo(() => hasPermission(PERMISSIONS.marketSegment.notAssigned), [hasPermission]);
    const isTsCatalyst = useMemo(() => hasPermission(PERMISSIONS.marketSegment.tsCatalyst), [hasPermission]);
    const isTsCoatings = useMemo(() => hasPermission(PERMISSIONS.marketSegment.tsCoatings), [hasPermission]);
    const isTsConstruction = useMemo(() => hasPermission(PERMISSIONS.marketSegment.tsConstruction), [hasPermission]);
    const isTsElectronics = useMemo(() => hasPermission(PERMISSIONS.marketSegment.tsElectronics), [hasPermission]);
    const isTsFoundry = useMemo(() => hasPermission(PERMISSIONS.marketSegment.tsFoundry), [hasPermission]);
    const isTsHpmo = useMemo(() => hasPermission(PERMISSIONS.marketSegment.tsHpmo), [hasPermission]);
    const isTsOilWellService = useMemo(() => hasPermission(PERMISSIONS.marketSegment.tsOilWellService), [
        hasPermission,
    ]);
    const isTsOther = useMemo(() => hasPermission(PERMISSIONS.marketSegment.tsOther), [hasPermission]);
    const isTsPackaging = useMemo(() => hasPermission(PERMISSIONS.marketSegment.tsPackaging), [hasPermission]);
    const isTsPharma = useMemo(() => hasPermission(PERMISSIONS.marketSegment.tsPharma), [hasPermission]);
    const isTsPorousCeramics = useMemo(() => hasPermission(PERMISSIONS.marketSegment.tsPorousCeramics), [
        hasPermission,
    ]);

    const permittedMarketSegments = useMemo(
        () =>
            [
                { name: MARKET_SEGMENT.notAssigned, isAllowed: isNotAssigned },
                { name: MARKET_SEGMENT.tsCatalyst, isAllowed: isTsCatalyst },
                { name: MARKET_SEGMENT.tsCoatings, isAllowed: isTsCoatings },
                { name: MARKET_SEGMENT.tsConstruction, isAllowed: isTsConstruction },
                { name: MARKET_SEGMENT.tsElectronics, isAllowed: isTsElectronics },
                { name: MARKET_SEGMENT.tsFoundry, isAllowed: isTsFoundry },
                { name: MARKET_SEGMENT.tsHpmo, isAllowed: isTsHpmo },
                { name: MARKET_SEGMENT.tsOilWellService, isAllowed: isTsOilWellService },
                { name: MARKET_SEGMENT.tsOther, isAllowed: isTsOther },
                { name: MARKET_SEGMENT.tsPackaging, isAllowed: isTsPackaging },
                { name: MARKET_SEGMENT.tsPharma, isAllowed: isTsPharma },
                { name: MARKET_SEGMENT.tsPorousCeramics, isAllowed: isTsPorousCeramics },
            ]
                .filter(marketSegment => marketSegment.isAllowed)
                .map(marketSegment => marketSegment.name),
        [
            isNotAssigned,
            isTsCatalyst,
            isTsCoatings,
            isTsConstruction,
            isTsElectronics,
            isTsFoundry,
            isTsHpmo,
            isTsOilWellService,
            isTsOther,
            isTsPackaging,
            isTsPharma,
            isTsPorousCeramics,
        ]
    );

    return {
        permittedMarketSegments,
        isNotAssigned,
        isTsCatalyst,
        isTsCoatings,
        isTsConstruction,
        isTsElectronics,
        isTsFoundry,
        isTsHpmo,
        isTsOilWellService,
        isTsOther,
        isTsPackaging,
        isTsPharma,
        isTsPorousCeramics,
    };
}
