import { useState } from 'react';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { getAppConfig, useService } from '@insight2profit/drive-app';
import { AppConfig } from 'shared/types';

interface UseTrackLoginProps {
    enableTokenTracking?: boolean;
}

export const useTrackLogin = ({ enableTokenTracking }: UseTrackLoginProps = { enableTokenTracking: false }) => {
    const { instance } = useMsal();
    const { activityTrackingService } = useService();
    const [appConfig, setAppConfig] = useState<AppConfig | null>(null);

    useEffect(() => {
        const fetchAppConfig = async () => {
            const baseUrl = process.env.PUBLIC_URL;
            const config = await getAppConfig<AppConfig>(baseUrl);
            setAppConfig(config);
        };
        fetchAppConfig();
    }, []);

    useEffect(() => {
        if (!appConfig) return; // Wait for appConfig to be loaded
        const callbackId = instance.addEventCallback((event: EventMessage) => {
            switch (event.eventType) {
                case EventType.LOGIN_SUCCESS:
                    const loginPayload = event.payload as AuthenticationResult;
                    activityTrackingService.trackStandardUserActivityEvent({
                        action: 'Login',
                        eventType: 'ButtonClick',
                        applicationId: appConfig.appId,
                        clientId: 'Nouryon PMT',
                        user: {
                            displayName: loginPayload.account.name,
                            email: loginPayload.account.idTokenClaims?.email,
                            id: loginPayload.account.tenantId,
                        },
                    });
                    break;
                case EventType.ACQUIRE_TOKEN_SUCCESS:
                    const tokenPayload = event.payload as AuthenticationResult;
                    if (!tokenPayload.fromCache && Boolean(enableTokenTracking)) {
                        activityTrackingService.trackStandardUserActivityEvent({
                            action: 'Token',
                            eventType: 'ButtonClick',
                            applicationId: appConfig.appId,
                            clientId: 'Nouryon PMT',
                            user: {
                                displayName: tokenPayload.account.name,
                                email: tokenPayload.account.idTokenClaims?.email,
                                id: tokenPayload.account.tenantId,
                            },
                        });
                    }
                    break;
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance, enableTokenTracking, appConfig, activityTrackingService]);

    return null;
};
