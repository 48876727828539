import { CLIENT_ID, useService, useUser } from '@insight2profit/drive-app';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { APP_NAME } from 'shared/constants';

export function useAnalyticsAppInfo() {
    const {
        eventService,
        authService,
        powerBiService,
        appConfig: { appId },
    } = useService();
    const user = useUser();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const prerequisites = {
        services: {
            eventService,
            authService,
            pbiService: powerBiService,
        },
        appInfo: {
            applicationId: appId,
            clientId: CLIENT_ID,
            userId: user?.id || '',
            appName: APP_NAME,
        },
        navigate,
    };
    const options = {
        snackbar: {
            enqueueSnackbar,
        },
    };
    return {
        prerequisites,
        options,
    };
}
