import { useService } from '@insight2profit/drive-app';
import { Box, LinearProgress } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useI2pServerDataGrid } from '@price-for-profit/data-grid';
import { QUERY_KEYS } from 'shared/constants';
import { HEADER_HEIGHT, SLIM_DATA_GRID_STYLE } from 'shared/constants/dataGrid';
import { ITableProductPricesComments, IViewProductPrices } from 'shared/types';
import { longDateValueFormatter } from 'shared/utility';
import { ProductPriceDrawerAddCommentForm } from './ProductPriceDrawerAddCommentForm';

interface ProductPriceDrawerCommentsProps {
    selectedProduct: IViewProductPrices | null;
}

export function ProductPriceDrawerComments({ selectedProduct }: ProductPriceDrawerCommentsProps) {
    const { productPricesCommentsService } = useService();
    const { getDataGridProps, invalidate } = useI2pServerDataGrid<ITableProductPricesComments>({
        columns: columns,
        name: `${QUERY_KEYS.productPriceComments}.${selectedProduct?.materialId}.${selectedProduct?.orgRegion}.${selectedProduct?.application}`,
        queryOptions: {
            enabled: Boolean(selectedProduct?.materialId && selectedProduct?.orgRegion && selectedProduct?.application),
        },
        getData: async state => {
            return productPricesCommentsService.getComments({
                ...state,
                filterModel: {
                    items: [
                        { columnField: 'materialId', value: selectedProduct?.materialId, operatorValue: 'equals' },
                        { columnField: 'orgRegion', value: selectedProduct?.orgRegion, operatorValue: 'equals' },
                        { columnField: 'application', value: selectedProduct?.application, operatorValue: 'equals' },
                    ],
                },
            });
        },
        initialState: {
            pageSize: 10,
            sortModel: [{ field: 'effectiveStart', sort: 'desc' }],
        },
        dataGridInitialState: {
            pinnedColumns: { left: ['__check__', 'material'] },
        },
        rowsPerPageOptions: [10, 20, 50, 100],
    });
    const dataGridProps = getDataGridProps();

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <ProductPriceDrawerAddCommentForm selectedProduct={selectedProduct} onSuccess={invalidate} />

            <DataGridPremium
                {...dataGridProps}
                sx={SLIM_DATA_GRID_STYLE}
                disableSelectionOnClick
                components={{
                    LoadingOverlay: LinearProgress,
                }}
                componentsProps={{
                    panel: {
                        sx: {
                            '& .MuiDataGrid-filterFormColumnInput': {
                                width: 'auto',
                            },
                        },
                    },
                }}
                disableVirtualization={false}
                headerHeight={HEADER_HEIGHT}
            />
        </Box>
    );
}
const columns = [
    {
        headerName: 'User Name',
        field: 'modifiedBy',
        width: 200,
    },
    {
        headerName: 'Comment',
        field: 'comment',
        width: 500,
    },
    {
        headerName: 'Date',
        field: 'effectiveStart',
        width: 200,
        valueFormatter: longDateValueFormatter,
    },
];
