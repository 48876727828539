import { Stack } from '@mui/system';
import { PageLayout } from 'shared/components/layouts';
import { ProductPricesAddRecord } from './productPricesAddRecord';

export function ProductPricesAddRecordResolver() {
    return (
        <PageLayout pageName='Add Records'>
            <Stack gap={2} height='calc(100vh - 300px)' width={'100%'}>
                <ProductPricesAddRecord />
            </Stack>
        </PageLayout>
    );
}
