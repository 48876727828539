import { Button, Stack } from '@mui/material';
import { useCallback } from 'react';
import { DeepPartial, FieldValues, useFormContext } from 'react-hook-form';

interface FormSubmitButtonProps<T> {
    defaultForm?: DeepPartial<T>;
    submitText?: string;
    resetText?: string;
    form?: string;
    isLoading?: boolean;
}

export function FormSubmitButton<T extends FieldValues>({
    form,
    defaultForm,
    resetText = 'reset',
    submitText = 'save',
    isLoading = false,
}: FormSubmitButtonProps<T>) {
    const {
        formState: { isDirty, isSubmitting, errors },
        reset,
    } = useFormContext<T>();

    const resetForm = useCallback(() => {
        reset(defaultForm);
    }, [reset, defaultForm]);

    const hasErrors = !!Object.keys(errors).length;

    return isDirty ? (
        <Stack direction='row' spacing={1}>
            <Button
                disabled={isSubmitting || isLoading || hasErrors}
                form={form}
                type='submit'
                color='primary'
                variant='contained'
            >
                {submitText}
            </Button>
            {Boolean(defaultForm) && (
                <Button disabled={isSubmitting || isLoading} onClick={resetForm} variant='outlined'>
                    {resetText}
                </Button>
            )}
        </Stack>
    ) : null;
}
