import { useService } from '@insight2profit/drive-app';
import { useQuery } from '@tanstack/react-query';

export function useCompanyNamesDropdown() {
    const { productPricesCompanyNameDropdownService } = useService();

    return useQuery({
        queryKey: ['getCompanyNames'],
        queryFn: async () => productPricesCompanyNameDropdownService.get(),
        keepPreviousData: true,
        select: data =>
            data.map(data => {
                return data.companies;
            }),
    });
}
