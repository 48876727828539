import { Stack } from '@mui/system';
import { PageLayout } from 'shared/components/layouts';
import { CustomerPriceAddExisting } from './customerPriceAddExisting';

export function CustomerPricesAddExistingResolver() {
    return (
        <PageLayout pageName='Add Existing SAP Customer'>
            <Stack gap={2} height='calc(100vh - 300px)' width={'100%'}>
                <CustomerPriceAddExisting />
            </Stack>
        </PageLayout>
    );
}
