import { Box, Drawer } from '@mui/material';
import { useState } from 'react';
import {
    COMMENTS_TAB,
    MARKET_PRICE_HISTORY,
    MODEL_DETAILS_TAB,
    PRICING_ANALYTICS_TAB,
    productPricesDrawerTabOptions,
} from 'shared/constants';
import { IViewProductPrices } from 'shared/types';
import { ProductPriceDrawerHeader } from './ProductPriceDrawerHeader';
import { ProductPriceDrawerTabs } from './ProductPriceDrawerTabs';
import { ProductPriceDrawerComments } from './productPriceComments/ProductPriceDrawerComments';
import { ProductPriceDrawerPriceChangeHistory } from './productPriceHistory/ProductPriceDrawerPriceChangeHistory';
import { ProductPriceDrawerModelDetails } from './productPriceModelDetails/ProductPriceDrawerModelDetails';
import { ProductPriceDrawerPricingAnalytics } from './productPricePricingAnalytics/ProductDrawerPricingAnalytics';

interface ProductPriceDrawerProps {
    setSelectedProductRow: React.Dispatch<React.SetStateAction<IViewProductPrices | null>>;
    selectedProduct: IViewProductPrices | null;
}
export function ProductPriceDrawer({ setSelectedProductRow, selectedProduct }: ProductPriceDrawerProps) {
    const [selectedTab, setSelectedTab] = useState(productPricesDrawerTabOptions[0]);

    const onTabChange = (event: React.SyntheticEvent<Element, Event>, tab: string) => {
        setSelectedTab(tab);
    };

    return (
        <Drawer
            open={selectedProduct ? true : false}
            anchor={'bottom'}
            sx={{
                '& .MuiDrawer-paper': {
                    width: 'calc(100vw - 50px)',
                    marginRight: '25px',
                    marginLeft: '25px',
                    borderRadius: '10px 10px 0px 0px',
                    minHeight: '550px',
                    maxHeight: '90vh',
                    height: '50vh',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
            onClose={() => setSelectedProductRow(null)}
        >
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
                <ProductPriceDrawerHeader
                    material={selectedProduct?.material || ''}
                    productLine={selectedProduct?.productLine || ''}
                    orgRegion={selectedProduct?.orgRegion || ''}
                    setSelectedProduct={setSelectedProductRow}
                />
                <ProductPriceDrawerTabs selectedTab={selectedTab} onTabChange={onTabChange} />
                {selectedTab === MODEL_DETAILS_TAB && (
                    <ProductPriceDrawerModelDetails selectedProduct={selectedProduct} />
                )}
                {selectedTab === COMMENTS_TAB && <ProductPriceDrawerComments selectedProduct={selectedProduct} />}
                {
                    <ProductPriceDrawerPricingAnalytics
                        isVisible={selectedTab === PRICING_ANALYTICS_TAB}
                        selectedProduct={selectedProduct}
                    />
                }
                {selectedTab === MARKET_PRICE_HISTORY && (
                    <ProductPriceDrawerPriceChangeHistory selectedProduct={selectedProduct} />
                )}
            </Box>
        </Drawer>
    );
}
