import { useUser } from '@insight2profit/drive-app';
import { Add, Remove } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { GridValidRowModel, useGridApiContext } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from 'shared/components/forms';
import { IPricesAddNewService } from 'shared/services/productPricesAddNewService';

interface AddRecordToolbarProps<NewRowForm extends { id: string }, ITable extends GridValidRowModel> {
    isAddNewRow: string | null;
    setIsAddNewRow: React.Dispatch<React.SetStateAction<string | null>>;
    handleAddRecord: () => void;
    rows: NewRowForm[];
    isLoading: boolean;
    addNewRecordsService: IPricesAddNewService<NewRowForm, ITable>;
    successRoute: string;
    name: string;
    containsDuplicateRows: boolean;
}

export function AddRecordToolbar<NewRowForm extends { id: string }, ITable extends GridValidRowModel>({
    isAddNewRow,
    setIsAddNewRow,
    handleAddRecord,
    rows,
    isLoading,
    addNewRecordsService,
    successRoute,
    name,
    containsDuplicateRows,
}: AddRecordToolbarProps<NewRowForm, ITable>) {
    const gridContext = useGridApiContext();
    const { displayName } = useUser();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const isRowinEditMode = rows.some(aRow => {
        return gridContext.current.getRowMode(aRow.id) === 'edit';
    });

    const handleSaveRecords = async () => {
        const resultArray = await addNewRecordsService.addNewRecords(rows, displayName);

        const failedSaves = resultArray.filter(aResult => {
            return aResult.status === 'rejected';
        });

        const idArray = resultArray
            .filter(aResult => {
                return aResult.status === 'fulfilled' && !!aResult.value.massActionId;
            })
            .map(aSuccessfulResult => {
                return (aSuccessfulResult as PromiseFulfilledResult<ITable>).value.newRecordId;
            });
        if (idArray.length > 0) {
            enqueueSnackbar(`Successfully added ${idArray.length} new row${idArray.length > 1 ? 's' : ''}`, {
                variant: 'success',
            });
            navigate(`${successRoute}?ids=${idArray.join('|')}`);
        }
        if (failedSaves.length > 0) {
            enqueueSnackbar(
                `There was a problem adding ${failedSaves.length} row${failedSaves.length > 1 ? 's' : ''}`,
                { variant: 'warning' }
            );
        }
    };

    const isSaveDisabled =
        gridContext.current.getRowMode(isAddNewRow ?? '') === 'edit' || isRowinEditMode || containsDuplicateRows;

    return (
        <Box display={'flex'} justifyContent={'flex-end'}>
            {isAddNewRow ? (
                <Button color='primary' startIcon={<Remove />} onClick={() => setIsAddNewRow(null)}>
                    Cancel Add
                </Button>
            ) : (
                <LoadingButton
                    id={`${name}-add-new`}
                    variant={'outlined'}
                    startIcon={<Add />}
                    onClick={handleAddRecord}
                    isLoading={false}
                    disabled={isLoading}
                >
                    Add record
                </LoadingButton>
            )}
            <Button onClick={handleSaveRecords} disabled={isSaveDisabled}>
                Save
            </Button>
        </Box>
    );
}
