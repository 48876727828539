import { SxProps, TextField, Theme } from '@mui/material';
import { useController } from 'react-hook-form';

type FormTextFieldProps = {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
    label: string;
    prefix?: string;
    suffix?: string;
    rules?: Object;
    readOnly?: boolean;
    defaultValue?: string;
    fullWidth?: boolean;
    multiline?: boolean;
    rows?: number;
    disabled?: boolean;
    placeholder?: string;
    sx?: SxProps<Theme>;
};

export function FormTextField({
    name,
    label,
    rules,
    rows,
    fullWidth = true,
    readOnly = false,
    defaultValue = '',
    multiline = false,
    disabled,
    placeholder,
    sx,
}: FormTextFieldProps) {
    const {
        field: { ref, ...inputProps },
        fieldState: { error },
    } = useController({
        name,
        rules,
        defaultValue,
    });

    return (
        <TextField
            {...inputProps}
            id={name}
            inputRef={ref}
            variant='outlined'
            fullWidth={fullWidth}
            label={`${label}${rules && Object.keys(rules).includes('required') ? ' *' : ''}`}
            error={Boolean(error)}
            helperText={error?.message}
            multiline={multiline}
            rows={rows}
            disabled={disabled}
            placeholder={placeholder}
            InputProps={{
                readOnly,
            }}
            sx={sx}
        />
    );
}
