import { useReducer } from 'react';
import { IAddNewProductPrices } from 'shared/types';

type AddNewProductPricesAction =
    | { type: 'add'; newViewRow: IAddNewProductPrices }
    | { type: 'copy'; newRowId: string; copiedRowId: string }
    | { type: 'delete'; id: string }
    | { type: 'edit'; newViewRow: IAddNewProductPrices };

function newProductPricesReducer(state: IAddNewProductPrices[], action: AddNewProductPricesAction) {
    switch (action.type) {
        case 'delete':
            const remainingRows = state.filter(aRow => {
                return aRow.id !== action.id;
            });
            return remainingRows;
        case 'add':
            return [...state, action.newViewRow];
        case 'edit':
            const editedRows = state.map(aRow => {
                if (aRow.id === action.newViewRow.id) {
                    return {
                        ...aRow,
                        businessLine: action.newViewRow.businessLine,
                        material: action.newViewRow.material,
                        orgRegion: action.newViewRow.orgRegion,
                        application: action.newViewRow.application,
                        marketSegment: action.newViewRow.marketSegment,
                    };
                }
                return aRow;
            });
            return editedRows;
        case 'copy':
            const originalRow = state.find(aRow => {
                return aRow.id === action.copiedRowId;
            });
            if (!originalRow) return state;
            const copiedRow = {
                ...originalRow,
                id: action.newRowId,
            };
            return [...state, copiedRow];
        default:
            return state;
    }
}

export function useNewProductPricesReducer() {
    const placeHolder = useReducer(newProductPricesReducer, [] as IAddNewProductPrices[]);

    return placeHolder;
}
