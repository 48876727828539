import { useUser } from '@insight2profit/drive-app';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormTextField, SaveButton } from 'shared/components/forms';
import { useProductPriceCommentsMutation } from 'shared/mutations';
import { ITableProductPricesComments, ITableProductPricesCommentsForm, IViewProductPrices } from 'shared/types';

interface ProductPriceDrawerAddCommentProps {
    selectedProduct: IViewProductPrices | null;
    onSuccess: () => void;
}

export function ProductPriceDrawerAddCommentForm({ selectedProduct, onSuccess }: ProductPriceDrawerAddCommentProps) {
    const user = useUser();
    const {
        handleSubmit,
        control,
        formState: { errors },
        resetField,
    } = useForm<ITableProductPricesCommentsForm>({
        defaultValues: {
            comment: '',
        },
    });
    const mutation = useProductPriceCommentsMutation({ onSuccess });
    const onSubmit = (form: ITableProductPricesCommentsForm) => {
        const today = new Date();
        const formattedToday = today.toISOString();
        if (selectedProduct && user) {
            const newCommentPayload: ITableProductPricesComments = {
                id: 0,
                businessLine: selectedProduct.businessLine || '',
                materialId: selectedProduct.materialId || '',
                orgRegion: selectedProduct.orgRegion || '',
                application: selectedProduct.application || '',
                comment: form.comment,
                modifiedBy: user.displayName,
                effectiveStart: formattedToday,
                effectiveEnd: null,
                deleted: false,
            };
            mutation.mutate(newCommentPayload);
            resetField('comment');
        }
    };

    return (
        <Box display='flex' gap={2} alignItems='center' px={2}>
            <FormTextField
                name={'comment'}
                label={'Comment'}
                control={control}
                rules={{ maxLength: 1000, required: 'cannot be blank' }}
                fieldError={errors['comment']}
            />

            <Box width='200px'>
                <SaveButton
                    text='Add Comment'
                    submitHandler={handleSubmit(onSubmit)}
                    isLoading={mutation.isLoading}
                    isDisabled={!!errors['comment']}
                />
            </Box>
        </Box>
    );
}
