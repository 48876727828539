import { Control, FieldError } from 'react-hook-form';
import { FormTextField } from 'shared/components/forms';
import { ICustomerPricesMassActionForm } from 'shared/types';

type CustomerPricesApprovalChangeFormsProps = {
    control: Control<ICustomerPricesMassActionForm, any>;
    errors: Partial<Record<keyof ICustomerPricesMassActionForm, FieldError | undefined>>;
    isLoading: boolean;
};

export function CustomerPricesApprovalChangeForms({
    control,
    errors,
    isLoading,
}: CustomerPricesApprovalChangeFormsProps) {
    return (
        <FormTextField
            name={'comment'}
            label={'Comment'}
            control={control}
            rules={{ maxLength: 1000, required: 'cannot be blank' }}
            fieldError={errors['comment']}
            isDisabled={isLoading}
        />
    );
}
