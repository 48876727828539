import { CLIENT_ID } from '@insight2profit/drive-app';
import { DataAccessResponse } from '@price-for-profit/micro-services';
import { DATABASE_LABEL, TABLE_CUSTOMER_PRICES_PERSISTED_ATTRIBUTES } from 'shared/constants';
import { ITableCustomerPricesPersistedAttributes, IViewCustomerPrices } from 'shared/types';
import { ICustomerPricesService } from './customerPricesService';
import { IExchangeRatesService } from './exchangeRatesService';
import { IRowLevelSecurityDasService } from './rowLevelSecurityDasService';

type SoftEditPersistedAttributesParams = {
    newViewRow: IViewCustomerPrices;
    oldViewRow: IViewCustomerPrices;
    userDisplayName?: string;
    userEmail: string;
    isForeignCurrency?: boolean;
};

export interface ICustomerPricesPersistedAttributesService {
    softEditPersistedAttributes({
        isForeignCurrency,
        newViewRow,
        oldViewRow,
        userDisplayName,
        userEmail,
    }: SoftEditPersistedAttributesParams): Promise<{
        editResponse?: DataAccessResponse<ITableCustomerPricesPersistedAttributes>;
        addResponse: DataAccessResponse<ITableCustomerPricesPersistedAttributes>;
        newViewRow: IViewCustomerPrices;
    }>;
}

export class CustomerPricesPersistedAttributesService implements ICustomerPricesPersistedAttributesService {
    constructor(
        private rlsDasService: IRowLevelSecurityDasService,
        private customerPricesService: ICustomerPricesService,
        private exchangeRatesService: IExchangeRatesService
    ) {}

    async softEditPersistedAttributes({
        isForeignCurrency,
        newViewRow,
        oldViewRow,
        userDisplayName,
        userEmail,
    }: SoftEditPersistedAttributesParams): Promise<{
        editResponse?: DataAccessResponse<ITableCustomerPricesPersistedAttributes>;
        addResponse: DataAccessResponse<ITableCustomerPricesPersistedAttributes>;
        newViewRow: IViewCustomerPrices;
    }> {
        if (!newViewRow.uom) throw Error('New UOM cannot be blank');

        const now = new Date().toISOString();

        let editResponse: DataAccessResponse<ITableCustomerPricesPersistedAttributes> | undefined = undefined;

        if (oldViewRow.customerPricesPersistedAttributesId) {
            // const originalPersistedAttributesRow = this.rlsDasService.getSingle<ITableCustomerPricesPersistedAttributes, typeof DATABASE_LABEL>({
            //     clientId: CLIENT_ID,
            //     databaseLabel: DATABASE_LABEL,
            //     key: oldViewRow.customerPricesPersistedAttributesId.toString(),
            //     tableId: TABLE_CUSTOMER_PRICES_PERSISTED_ATTRIBUTES
            // })

            // const termedOldTableRow: ITableCustomerPricesPersistedAttributes = {
            //     customerPricesPersistedAttributesId: oldViewRow.customerPricesPersistedAttributesId as number,
            //     businessLine: oldViewRow.businessLine,
            //     soldToId: oldViewRow.soldToId,
            //     shipToId: oldViewRow.shipToId,
            //     materialId: oldViewRow.materialId,
            //     documentCurrency: oldViewRow.documentCurrency,
            //     priceTypeAttributes: oldViewRow.priceTypeAttributes,
            //     uom: oldViewRow.uom,
            //     effectiveEnd: now,
            //     deleted: false,
            //     modifiedBy: oldViewRow.modifiedBy,
            //     effectiveStart: originalPersistedAttributesRow.effectiveStart,
            // };

            const currentDocumentCurrencyCode = newViewRow.documentCurrency;
            const exchangeRatesResponse = await this.exchangeRatesService.getExchangeRates();
            const matchingExchangeRate = exchangeRatesResponse?.data?.find(anExchangeRate => {
                return anExchangeRate.fromCurrencyCode === currentDocumentCurrencyCode;
            });
            if (!matchingExchangeRate) {
                throw new Error(`A matching exchange rate was not found for ${currentDocumentCurrencyCode}`);
            }

            editResponse = await this.rlsDasService.patchRow<
                ITableCustomerPricesPersistedAttributes,
                typeof DATABASE_LABEL
            >({
                clientId: CLIENT_ID,
                databaseLabel: DATABASE_LABEL,
                tableId: TABLE_CUSTOMER_PRICES_PERSISTED_ATTRIBUTES,
                key: oldViewRow.customerPricesPersistedAttributesId.toString(),
                operations: [{ op: 'replace', path: '/effectiveEnd', value: now }],
            });
        }

        const modifiedNewTableRow = await this.createNewRow({
            isForeignCurrency,
            newViewRow,
            oldTableRow: oldViewRow,
            userDisplayName: userDisplayName || '',
            now,
        });

        const addResponse = await this.rlsDasService.addRow<
            ITableCustomerPricesPersistedAttributes,
            typeof DATABASE_LABEL
        >({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_CUSTOMER_PRICES_PERSISTED_ATTRIBUTES,
            payload: modifiedNewTableRow,
        });

        await this.customerPricesService.softEditGridRowData({
            newViewRow,
            oldViewRow,
            userDisplayName,
            userEmail,
            isForeignCurrency,
        });

        return { editResponse, addResponse, newViewRow };
    }

    private mapViewCustomerPricesToTableCustomerPricesPersistedAttributes({
        oldViewRow,
        newViewRow,
        effectiveEnd,
    }: {
        oldViewRow: IViewCustomerPrices;
        newViewRow: IViewCustomerPrices;
        effectiveEnd?: string;
    }): ITableCustomerPricesPersistedAttributes {
        return {
            customerPricesPersistedAttributesId: oldViewRow.customerPricesPersistedAttributesId as number,
            businessLine: oldViewRow.businessLine,
            soldToId: oldViewRow.soldToId,
            shipToId: oldViewRow.shipToId,
            materialId: oldViewRow.materialId,
            documentCurrency: newViewRow.documentCurrency,
            priceTypeAttributes: newViewRow.priceTypeAttributes,
            effectiveEnd,
            deleted: false,
            modifiedBy: oldViewRow.modifiedBy,
            effectiveStart: oldViewRow.effectiveStart,
        };
    }

    private async createNewRow({
        isForeignCurrency,
        newViewRow,
        oldTableRow,
        userDisplayName,
        now,
    }: {
        isForeignCurrency?: boolean;
        newViewRow: IViewCustomerPrices;
        oldTableRow: IViewCustomerPrices;
        userDisplayName: string;
        now: string;
    }) {
        const newTableRow: ITableCustomerPricesPersistedAttributes = this.mapViewCustomerPricesToTableCustomerPricesPersistedAttributes(
            {
                oldViewRow: oldTableRow,
                newViewRow: newViewRow,
            }
        );
        if (isForeignCurrency) {
            const currentDocumentCurrencyCode = newViewRow.documentCurrency;
            const exchangeRatesResponse = await this.exchangeRatesService.getExchangeRates();

            const matchingExchangeRate = exchangeRatesResponse?.data?.find(anExchangeRate => {
                return anExchangeRate.fromCurrencyCode === currentDocumentCurrencyCode;
            });
            if (!matchingExchangeRate) {
                throw new Error(`A matching exchange rate was not found for ${currentDocumentCurrencyCode}`);
            }

            const modifiedNewTableRow: ITableCustomerPricesPersistedAttributes = {
                ...newTableRow,
                customerPricesPersistedAttributesId: 0,
                effectiveStart: now,
                modifiedBy: userDisplayName,
                deleted: false,
                effectiveEnd: undefined,
            };
            return modifiedNewTableRow;
        }
        const modifiedNewTableRow: ITableCustomerPricesPersistedAttributes = {
            ...newTableRow,
            customerPricesPersistedAttributesId: 0,
            effectiveStart: now,
            modifiedBy: userDisplayName,
            deleted: false,
            effectiveEnd: undefined,
        };

        return modifiedNewTableRow;
    }
}
