import { ObjectTyped } from '@price-for-profit/micro-services';

export function isValidDate(value: any) {
    return value.toString().toLowerCase() !== 'invalid date' || 'Must be a valid date';
}

export function isNotPastDate(value: any) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const valueDate = new Date(value);
    valueDate.setHours(0, 0, 0, 0);
    return valueDate >= currentDate || 'Past dates are not allowed';
}

export const required = (label: string) => ({
    required: `${label} is required`,
});

export const isBeforeDate = (laterDateName: string, laterDateStr: string | undefined) => (earlierDateStr: string) => {
    if (!laterDateStr) return `${laterDateName} required to compare`;

    const earlierDate = new Date(earlierDateStr);
    earlierDate.setHours(0, 0, 0, 0);

    const laterDate = new Date(laterDateStr);
    laterDate.setHours(0, 0, 0, 0);

    return earlierDate < laterDate || `Date must be before ${laterDateName}`;
};

export const isAfterDate = (earlierDateName: string, earlierDateStr: string | undefined) => (laterDateStr: string) => {
    if (!earlierDateStr) return `${earlierDateName} required to compare`;

    const earlierDate = new Date(earlierDateStr);
    earlierDate.setHours(0, 0, 0, 0);

    const laterDate = new Date(laterDateStr);
    laterDate.setHours(0, 0, 0, 0);

    return earlierDate < laterDate || `Date must be after ${earlierDateName}`;
};

export function distinctObjectArray<T extends {}>(objectArray: T[]) {
    const sortedEntryJsonStrings = objectArray.map(obj => {
        const sortedEntries = ObjectTyped.entries(obj).sort(([aKey, aValue], [bKey, bValue]) => {
            if (typeof aKey === 'number' && typeof bKey === 'number') {
                return aKey - bKey;
            }

            return aKey.toString().localeCompare(bKey.toString());
        });
        return JSON.stringify(sortedEntries);
    });
    const sortedEntryJsonStringsSet = new Set(sortedEntryJsonStrings);

    return Array.from(sortedEntryJsonStringsSet).map(sortedEntryJsonString =>
        ObjectTyped.fromEntries(JSON.parse(sortedEntryJsonString))
    );
}
