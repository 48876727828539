import {
    AppLogo,
    AuthenticationRequired,
    LoginLayout,
    LoginPage,
    NavigationLayout,
    UnauthorizedPage,
    useIsDemoMode,
} from '@insight2profit/drive-app';
import clientLogo from 'assets/client-logo.png';
import insightLogo from 'assets/insight-logo.png';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { ROUTES } from 'routing';
// import loginClientLogoSrc from '../assets/path-to-client-logo.png';
import insightDriveAccountLogo from 'assets/insight-drive-account-logo.svg';
import { CustomerPricesAddExistingResolver } from 'pages/customerPrices/customerPriceAddRecord.tsx/customerPriceAddExisting/customerPriceAddExistingResolver';
import { CustomerPricesAddProspectiveResolver } from 'pages/customerPrices/customerPriceAddRecord.tsx/customerPriceAddProspective/customerPriceAddProspectiveResolver';
import { CustomerPricesResolver } from 'pages/customerPrices/customerPricesResolver';
import { ModuleRedirect } from 'pages/moduleRedirect/moduleRedirect';
import { ProductPricesAddRecordResolver } from 'pages/productPrices/productPricesAddRecord/productPricesAddRecordResolver';
import { ProductPricesResolver } from 'pages/productPrices/productPricesResolver';
import { ProvideExchangeRates, ProvideProductPricesLocks } from 'shared/providers';

const isProd = process.env.NODE_ENV === 'production';

const headerClientLogo = <AppLogo src={clientLogo} alt='client logo' />;
const loginClientLogo = <AppLogo src={`${process.env.PUBLIC_URL}/favicon-32x32.png`} alt='client login logo' />;
const headerInsightLogo = <AppLogo src={insightLogo} alt='client logo' />;
const insightLoginLogo = <AppLogo src={insightDriveAccountLogo} alt='insight logo' maxHeight='30px' />;
const driveToProfitLogo = (
    <AppLogo src={`${process.env.PUBLIC_URL}/DRIVEtoPROFIT_Color.png`} alt='drive to profit logo' maxHeight='50px' />
);

export function AppRoutes() {
    const isDemoMode = useIsDemoMode();
    return useRoutes([
        {
            element: <Login />,
            children: [
                {
                    path: ROUTES.login,
                    element: (
                        <LoginPage
                            loginRedirectRoute={ROUTES.moduleRedirect}
                            tabTitle={isDemoMode ? 'PMT Login' : 'Nouryon PMT Login'}
                            insightLoginLogo={insightLoginLogo}
                            clientLogo={loginClientLogo}
                        />
                    ),
                },
            ],
        },
        {
            element: <Private />,
            children: [
                {
                    path: ROUTES.moduleRedirect,
                    element: <ModuleRedirect />,
                },
                {
                    path: ROUTES.productPrices,
                    element: (
                        <ProvideExchangeRates isForeignCurrencyDefault={false}>
                            <ProvideProductPricesLocks>
                                <ProductPricesResolver />
                            </ProvideProductPricesLocks>
                        </ProvideExchangeRates>
                    ),
                },
                {
                    path: ROUTES.customerPrices,
                    element: (
                        <ProvideExchangeRates isForeignCurrencyDefault={true}>
                            <ProvideProductPricesLocks>
                                <CustomerPricesResolver />
                            </ProvideProductPricesLocks>
                        </ProvideExchangeRates>
                    ),
                },
                {
                    path: ROUTES.productPricesAddRecord,
                    element: (
                        // Added to retain foreign currency state when switchin between add new and data grid pages
                        <ProvideExchangeRates isForeignCurrencyDefault={false}>
                            <ProvideProductPricesLocks>
                                <ProductPricesAddRecordResolver />
                            </ProvideProductPricesLocks>
                        </ProvideExchangeRates>
                    ),
                },
                {
                    path: ROUTES.customerPricesAddNewRecord,
                    element: (
                        // Added to retain foreign currency state when switchin between add new and data grid pages
                        <ProvideExchangeRates isForeignCurrencyDefault={true}>
                            <CustomerPricesAddProspectiveResolver />
                        </ProvideExchangeRates>
                    ),
                },
                {
                    path: ROUTES.customerPricesAddExistingSAPRecord,
                    element: (
                        // Added to retain foreign currency state when switchin between add new and data grid pages
                        <ProvideExchangeRates isForeignCurrencyDefault={true}>
                            <CustomerPricesAddExistingResolver />
                        </ProvideExchangeRates>
                    ),
                },
            ],
        },
        {
            path: ROUTES.unauthorized,
            element: <Unauthorized />,
        },
        {
            path: '*',
            element: <Navigate to={ROUTES.login} replace />,
        },
    ]);
}

function Unauthorized() {
    const isDemoMode = useIsDemoMode();
    return <UnauthorizedPage logo={isDemoMode ? headerInsightLogo : headerClientLogo} driveLogo={insightLoginLogo} />;
}

function Login() {
    return (
        <LoginLayout>
            <Outlet />
        </LoginLayout>
    );
}

function Private() {
    const isDemoMode = useIsDemoMode();

    return (
        <AuthenticationRequired>
            <NavigationLayout
                useLocal={!isProd}
                logo={isDemoMode ? headerInsightLogo : headerClientLogo}
                footerLogo={driveToProfitLogo}
            >
                <Outlet />
            </NavigationLayout>
        </AuthenticationRequired>
    );
}
