import { alphaNumericSort } from '@price-for-profit/micro-services';
import { useMemo } from 'react';

import { useNonStandardUomDropdown, useUomDropdown } from 'shared/queries';
import {
    ITableMaterialUOMConversion,
    ITableNonStandardUOMConversion,
    ITableUOMConversion,
    MaterialUOMType,
} from 'shared/types';

export function makeMaterialUomList({
    uomList,
    nonStandardUomList,
}: {
    uomList: ITableUOMConversion[];
    nonStandardUomList: ITableNonStandardUOMConversion[];
}) {
    return [
        ...uomList.map(uom => ({
            ...(uom as ITableUOMConversion),
            type: 'standard' as MaterialUOMType,
        })),
        ...nonStandardUomList.map(nonStandardUom => ({
            ...(nonStandardUom as ITableNonStandardUOMConversion),
            type: 'nonstandard' as MaterialUOMType,
        })),
    ];
}

export function useMaterialUom(materialId: string) {
    const { data: uomList, uomValueOptions, isLoading: isUomLoading } = useUomDropdown();
    const {
        nonStandardUomList,
        nonStandardUOMValueOptions,
        isLoading: isNonStandardUomLoading,
    } = useNonStandardUomDropdown(materialId);

    const { materialUomList, materialUomValueOptions } = useMemo(() => {
        let materialUomList: ITableMaterialUOMConversion = [];
        let materialUomValueOptions: { value: any; label: string }[] = [];

        if (isUomLoading || isNonStandardUomLoading) {
            return {
                materialUomList,
                materialUomValueOptions,
            };
        }

        materialUomList = makeMaterialUomList({
            uomList,
            nonStandardUomList,
        });

        materialUomValueOptions = alphaNumericSort(
            [...uomValueOptions, ...nonStandardUOMValueOptions],
            ({ value }) => value
        );

        return {
            materialUomList,
            materialUomValueOptions,
        };
    }, [
        uomList,
        uomValueOptions,
        isUomLoading,
        nonStandardUomList,
        nonStandardUOMValueOptions,
        isNonStandardUomLoading,
    ]);

    return {
        isLoading: isUomLoading || isNonStandardUomLoading,
        materialUomList,
        materialUomValueOptions,
    };
}
