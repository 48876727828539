import { useService } from '@insight2profit/drive-app';
import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useI2pSimpleDataGrid } from '@price-for-profit/data-grid';
import { NullCell } from 'shared/components/app/DataGridCells';
import { CustomerPricesForeignCurrencyRenderCell } from 'shared/components/app/customerPrices/CustomerPricesForeignCurrencyRenderCell';
import { QUERY_KEYS, SP_GET_CUSTOMER_PRICE_HISTORY } from 'shared/constants';
import { HEADER_HEIGHT, SLIM_DATA_GRID_STYLE } from 'shared/constants/dataGrid';
import { useExchangeRates } from 'shared/providers';
import { ISpGetCustomerPriceHistory, IViewCustomerPrices, SpGetCustomerPriceHistoryParams } from 'shared/types';
import { customerPricesForeignCurrencyValueGetterCurried, longDateValueFormatter } from 'shared/utility';

type CustomerPriceDrawerPriceChangeHistoryProps = {
    selectedCustomerPrice: IViewCustomerPrices | undefined;
};

export function CustomerPriceDrawerPriceChangeHistory({
    selectedCustomerPrice,
}: CustomerPriceDrawerPriceChangeHistoryProps) {
    const { namedQueryService } = useService();
    const { isForeignCurrency, exchangeRates } = useExchangeRates();
    const columns: GridColDef<ISpGetCustomerPriceHistory>[] = [
        {
            headerName: 'id',
            field: 'id',
            flex: 1,
            hide: true,
            filterable: false,
        },
        {
            headerName: 'Recommended Price',
            field: 'recommendedPriceTimesPerQuantity',
            valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: (params: GridRenderCellParams) => {
                return <CustomerPricesForeignCurrencyRenderCell {...params} />;
            },
            flex: 1,
        },
        {
            headerName: 'Current price in SAP',
            field: 'currentPriceInSAP',
            valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: (params: GridRenderCellParams) => {
                return <CustomerPricesForeignCurrencyRenderCell {...params} />;
            },
            flex: 1,
        },
        {
            headerName: 'Revised Price',
            field: 'revisedPrice',
            valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: params =>
                params.row.isScalePrice ? (
                    <NullCell {...params} />
                ) : (
                    <CustomerPricesForeignCurrencyRenderCell {...params} />
                ),
            flex: 1,
        },
        {
            headerName: 'UOM',
            field: 'uom',
            flex: 1,
        },
        {
            headerName: 'Document Currency',
            field: 'documentCurrency',
            flex: 1,
        },
        {
            headerName: 'Status',
            field: 'status',
            flex: 1,
        },
        {
            headerName: 'Edited by',
            field: 'modifiedBy',
            flex: 1,
        },
        {
            headerName: 'Edited Date',
            field: 'editedDate',
            valueFormatter: longDateValueFormatter,
            flex: 1.5,
        },
        {
            headerName: 'Approver',
            field: 'approver',
            flex: 1,
        },
    ];

    const { getDataGridProps } = useI2pSimpleDataGrid<ISpGetCustomerPriceHistory>({
        columns: columns,
        name: QUERY_KEYS.getCustomerPriceHistory,
        additionalKeys: [
            selectedCustomerPrice?.businessLine,
            selectedCustomerPrice?.soldToId,
            selectedCustomerPrice?.shipToId,
            selectedCustomerPrice?.materialId,
        ],
        queryOptions: {
            enabled: Boolean(
                selectedCustomerPrice?.businessLine &&
                    selectedCustomerPrice?.soldToId &&
                    selectedCustomerPrice?.shipToId &&
                    selectedCustomerPrice?.materialId
            ),
        },
        getData: async () => {
            if (!selectedCustomerPrice) return [];

            const namedQueryResponse = await namedQueryService.executeNamedQuery<
                SpGetCustomerPriceHistoryParams,
                ISpGetCustomerPriceHistory
            >({
                queryName: SP_GET_CUSTOMER_PRICE_HISTORY,
                parameters: {
                    businessLine: selectedCustomerPrice.businessLine,
                    soldToId: selectedCustomerPrice.soldToId,
                    shipToId: selectedCustomerPrice.shipToId,
                    materialId: selectedCustomerPrice.materialId,
                },
                page: 0,
                pageSize: 1000,
            });

            return namedQueryResponse.data;
        },
        dataGridInitialState: {
            pagination: { pageSize: 10, page: 0 },
            sorting: {
                sortModel: [
                    {
                        field: 'editedDate',
                        sort: 'desc',
                    },
                ],
            },
        },
        rowsPerPageOptions: [10, 20, 50, 100],
    });
    const dataGridProps = getDataGridProps();

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <DataGridPremium
                {...dataGridProps}
                sx={SLIM_DATA_GRID_STYLE}
                disableSelectionOnClick
                components={{
                    LoadingOverlay: LinearProgress,
                }}
                componentsProps={{
                    panel: {
                        sx: {
                            '& .MuiDataGrid-filterFormColumnInput': {
                                width: 'auto',
                            },
                        },
                    },
                }}
                disableVirtualization={false}
                headerHeight={HEADER_HEIGHT}
            />
        </Box>
    );
}
