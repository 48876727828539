import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useProductPricesApplicationDropdown } from 'shared/queries/productPricesDropdownQueries';
import { ProductPricesAddNewAutocomplete } from './ProductPricesAddNewAutocomplete';

export function ProductPricesApplicationAutocomplete(params: GridRenderEditCellParams) {
    const { data, isLoading } = useProductPricesApplicationDropdown({
        selectedBusinessLine: params.row.businessLine,
    });
    const apiRef = useGridApiContext();

    return (
        <ProductPricesAddNewAutocomplete
            isLoading={isLoading}
            setEditCellValue={apiRef.current.setEditCellValue}
            rowId={params.row.id}
            field={params.field}
            value={params.value}
            options={data}
            error={params.error}
            placeholder='Application'
        />
    );
}
