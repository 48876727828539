import { MASS_ACTION_TYPES } from 'shared/constants';

export function useMassActionFormLogic({
    selectedType,
    selectedField,
}: {
    selectedType: string;
    selectedField: string;
}) {
    const validatePrice = (value: string): string | boolean => {
        if (value === '') return true;

        const numberValue = Number(value);
        if (selectedType === MASS_ACTION_TYPES.AMOUNT && numberValue <= 0) {
            return 'must be greater than 0';
        }

        if (
            [MASS_ACTION_TYPES.CHANGE_DOLLAR as string, MASS_ACTION_TYPES.CHANGE_PERCENT as string].includes(
                selectedType
            ) &&
            numberValue === 0
        ) {
            return 'must not be 0';
        }
        return true;
    };

    const validateScaleAndAmount = (value: string): string | boolean => {
        if (value === '') return true;

        const numberValue = Number(value);
        if (selectedType === MASS_ACTION_TYPES.AMOUNT && numberValue < 0) {
            return 'must be greater than or equal to 0';
        }

        if (
            [MASS_ACTION_TYPES.CHANGE_DOLLAR as string, MASS_ACTION_TYPES.CHANGE_PERCENT as string].includes(
                selectedType
            ) &&
            numberValue === 0
        ) {
            return 'must not be 0';
        }
        return true;
    };

    const validateValidityDate = (value: string): string | boolean => {
        if (!value) {
            return true;
        }

        if (selectedType === MASS_ACTION_TYPES.VALIDITY_DATES && selectedField === 'validTo') {
            const isPast = new Date(value) < new Date();

            if (isPast) {
                return 'must be a date after today';
            }
        }

        return true;
    };

    const determineLabel = (fieldName: string) => {
        switch (selectedType) {
            case MASS_ACTION_TYPES.AMOUNT:
                if (selectedField.includes('CMPercent')) return 'Set Percent';
                return 'Set Value';
            case MASS_ACTION_TYPES.CHANGE_DOLLAR:
                return 'Change Amount';
            case MASS_ACTION_TYPES.CHANGE_PERCENT:
                return 'Change Amount';
            case MASS_ACTION_TYPES.INCREASE_PERCENT:
                return 'Increase Percent';
            default:
                return fieldName;
        }
    };

    const determineNumberFieldType = () => {
        if (selectedField.includes('CMPercent')) return 'percent';
        switch (selectedType) {
            case MASS_ACTION_TYPES.AMOUNT:
                if (selectedField.includes('scaleQty')) return 'number';
                return 'money';
            case MASS_ACTION_TYPES.CHANGE_DOLLAR:
                return 'money';
            case MASS_ACTION_TYPES.CHANGE_PERCENT:
                return 'percent';
            default:
                return 'number';
        }
    };

    return {
        validatePrice,
        determineLabel,
        validateScaleAndAmount,
        validateValidityDate,
        determineNumberFieldType,
    };
}
