import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useCustomerPricesApplicationDropdown } from 'shared/queries/customerPricesDropdownQueries';
import { getCustomerPriceMaterialId } from 'shared/types';
import { CustomerPricesAddNewAutocomplete } from './customerPricesAddNewAutocomplete';

export function CustomerPricesApplicationAutocomplete(params: GridRenderEditCellParams) {
    const selectedMaterialId = getCustomerPriceMaterialId(params?.row?.material);
    const selectedOrgRegion = params?.row?.orgRegion;
    const selectedBusinessLine = params?.row?.businessLine;
    const { data, isLoading } = useCustomerPricesApplicationDropdown({
        selectedMaterialId,
        selectedOrgRegion,
        selectedBusinessLine,
    });
    const apiRef = useGridApiContext();

    return (
        <CustomerPricesAddNewAutocomplete
            isLoading={isLoading}
            setEditCellValue={apiRef.current.setEditCellValue}
            rowId={params.row.id}
            field={params.field}
            value={params.value}
            options={data}
            error={params.error}
            placeholder='Application'
            disabled={!selectedMaterialId || !selectedOrgRegion}
            onChange={(_, value) => {
                apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: value });
            }}
        />
    );
}
