import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { GridEditCellValueParams, MuiBaseEvent } from '@mui/x-data-grid-premium';
import { DropdownErrorToolTip } from 'pages/productPrices/productPricesAddRecord/DropdownErrorToolTip';

type CustomerPricesAddNewAutocompleteProps<T> = {
    isLoading: boolean;
    setEditCellValue: (params: GridEditCellValueParams, event?: MuiBaseEvent | undefined) => void | Promise<boolean>;
    rowId: string;
    field: string;
    value: T;
    options: T[];
    error: string | boolean;
    placeholder: string;
} & Partial<AutocompleteProps<T, false, false, false>>;

export function CustomerPricesAddNewAutocomplete<T>({
    isLoading,
    setEditCellValue,
    rowId,
    field,
    value,
    options,
    error,
    placeholder,
    ...autoCompleteProps
}: CustomerPricesAddNewAutocompleteProps<T>) {
    return (
        <DropdownErrorToolTip error={error}>
            <Autocomplete
                id={`customer-prices-${field}-dropdown`}
                options={options || []}
                value={value || null}
                fullWidth
                onChange={(_, value) => {
                    setEditCellValue({ id: rowId, field: field, value: value });
                }}
                loading={isLoading}
                renderInput={renderInputParams => <TextField {...renderInputParams} placeholder={placeholder} />}
                {...autoCompleteProps}
            />
        </DropdownErrorToolTip>
    );
}
