import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { SaveButton } from 'shared/components/forms';
import { BUSINESS_LINE } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { useCustomerProductEngineBatchMetaDatas } from 'shared/providers';
import { useCustomerProductEngineBatchMutation } from 'shared/mutations';
import { MenuOption } from 'shared/types';
import { DropdownMenu } from '../mass-actions/DropdownMenu';

type BusinessLineBatch = {
    businessLineName: string;
    businessLineKey: string;
    isAllowed: boolean;
    isInProgress: boolean;
};

export function GridToolbarCustomerProductEngineBatchProcessAction() {
    const {
        model: { isTriggerAfCpeAllowed, isTriggerIsCpeAllowed, isTriggerPcCpeAllowed, isTriggerNrCpeAllowed },
    } = useUserPermissions();
    const [selectedBusinessLineBatch, setSelectedBusinessLineBatch] = useState<BusinessLineBatch | null>(null);
    const mutation = useCustomerProductEngineBatchMutation();

    const { isInProgress, isLoading } = useCustomerProductEngineBatchMetaDatas();

    const open = (businessLineBatch: BusinessLineBatch) => setSelectedBusinessLineBatch(businessLineBatch);

    const close = () => setSelectedBusinessLineBatch(null);
    const isOpen = !!selectedBusinessLineBatch;

    const businessLineBatches: BusinessLineBatch[] = [
        {
            businessLineName: 'AG AND FOOD',
            businessLineKey: BUSINESS_LINE.agAndFood,
            isAllowed: isTriggerAfCpeAllowed,
            isInProgress: isInProgress(BUSINESS_LINE.agAndFood) || isLoading,
        },
        {
            businessLineName: 'INORGANIC SPECIALTIES',
            businessLineKey: BUSINESS_LINE.inorganicSpecialties,
            isAllowed: isTriggerIsCpeAllowed,
            isInProgress: isInProgress(BUSINESS_LINE.inorganicSpecialties) || isLoading,
        },
        {
            businessLineName: 'PAINTS AND COATINGS',
            businessLineKey: BUSINESS_LINE.paintsAndCoatings,
            isAllowed: isTriggerPcCpeAllowed,
            isInProgress: isInProgress(BUSINESS_LINE.paintsAndCoatings) || isLoading,
        },
        {
            businessLineName: 'NATURAL RESOURCES',
            businessLineKey: BUSINESS_LINE.naturalResources,
            isAllowed: isTriggerNrCpeAllowed,
            isInProgress: isInProgress(BUSINESS_LINE.naturalResources) || isLoading,
        },
    ];

    const submitHandler = () => {
        if (selectedBusinessLineBatch) {
            mutation.mutate(selectedBusinessLineBatch.businessLineKey);
            close();
        }
    };

    const allowedBusinessLineBatches = businessLineBatches.filter(businessLineBatch => businessLineBatch.isAllowed);

    if (allowedBusinessLineBatches.length === 0) {
        return null;
    }

    const batchActionMenuOptions: MenuOption[] = [
        {
            key: BUSINESS_LINE.agAndFood,
            value: 'AG AND FOOD',
            action: () =>
                open(allowedBusinessLineBatches.find(ablb => ablb.businessLineKey === BUSINESS_LINE.agAndFood)!),
            disabled: isInProgress(BUSINESS_LINE.agAndFood) || isLoading,
        },
        {
            key: BUSINESS_LINE.inorganicSpecialties,
            value: 'INORGANIC SPECIALTIES',
            action: () =>
                open(
                    allowedBusinessLineBatches.find(
                        ablb => ablb.businessLineKey === BUSINESS_LINE.inorganicSpecialties
                    )!
                ),
            disabled: isInProgress(BUSINESS_LINE.inorganicSpecialties) || isLoading,
        },
        {
            key: BUSINESS_LINE.paintsAndCoatings,
            value: 'PAINTS AND COATINGS',
            action: () =>
                open(
                    allowedBusinessLineBatches.find(ablb => ablb.businessLineKey === BUSINESS_LINE.paintsAndCoatings)!
                ),
            disabled: isInProgress(BUSINESS_LINE.paintsAndCoatings) || isLoading,
        },
        {
            key: BUSINESS_LINE.naturalResources,
            value: 'NATURAL RESOURCES',
            action: () =>
                open(allowedBusinessLineBatches.find(ablb => ablb.businessLineKey === BUSINESS_LINE.naturalResources)!),
            disabled: isInProgress(BUSINESS_LINE.naturalResources) || isLoading,
        },
    ];

    const modalButton =
        allowedBusinessLineBatches.length === 1 ? (
            <SaveButton
                text={`Initiate Customer Price Review`}
                isDisabled={allowedBusinessLineBatches[0].isInProgress}
                isLoading={allowedBusinessLineBatches[0].isInProgress}
                submitHandler={() => open(allowedBusinessLineBatches[0])}
            />
        ) : (
            <DropdownMenu
                key={'dropdown-batch-actions'}
                title={'Initiate Customer Price Review'}
                defaultDisplay={'Initiate Customer Price Review'}
                disabled={false}
                menuOptions={batchActionMenuOptions}
                buttonSx={{
                    minWidth: '8rem',
                    backgroundColor: 'secondary.main',
                    color: 'black',

                    '&:hover': {
                        backgroundColor: 'secondary.light',
                        color: 'black',
                    },
                }}
            />
        );

    return (
        <>
            {modalButton}
            <Dialog onClose={() => close()} open={isOpen}>
                <DialogTitle>
                    {selectedBusinessLineBatch?.businessLineName.toUpperCase()} Customer-Product Engine
                </DialogTitle>
                <DialogContent>
                    <DialogContentText p={2} id='alert-dialog-error'>
                        Are you sure you want to initiate the customer-product engine for business line{' '}
                        {selectedBusinessLineBatch?.businessLineName.toUpperCase()}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} size={'large'}>
                        Cancel
                    </Button>
                    <SaveButton submitHandler={submitHandler} isLoading={false} isDisabled={false} text='Initiate' />
                </DialogActions>
            </Dialog>
        </>
    );
}
