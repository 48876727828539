import { useService, useUser } from '@insight2profit/drive-app';
import { EventData } from '@price-for-profit/micro-services';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERY_KEYS } from 'shared/constants';
import { useCustomerProductEngineBatchMetaDatas, usePricesInvalidator } from 'shared/providers';

export function useCustomerProductEngineBatchMutation() {
    const { batchService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { invalidateQuery } = usePricesInvalidator();
    const { invalidateQuery: invalidateMetadataQuery } = useCustomerProductEngineBatchMetaDatas();
    const user = useUser();

    const mutation = useMutation<EventData, Error, string>({
        mutationKey: [QUERY_KEYS.customerProductEngineBatchMutation],
        mutationFn: async (businessLine: string) => {
            if (!user) throw new Error('User not found');
            return await batchService.triggerCustomerProductEngine({ businessLine, user });
        },
        onSuccess: () => {
            enqueueSnackbar('Customer-Product Engine initiated', { variant: 'success' });
            invalidateQuery();
            invalidateMetadataQuery();
        },
        onError: error => {
            enqueueSnackbar('There was an error initiating the Customer-Product Engine', { variant: 'error' });
        },
    });
    return mutation;
}
