import { QueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { QUERY_KEYS, UOM_KAI, UOM_KG } from 'shared/constants';
import {
    ITableMaterialUOMConversion,
    ITableMaterialUOMConversionRow,
    ITableNonStandardUOMConversion,
    ITableUOMConversion,
    MaterialNonStandardUOMConversion,
    MaterialNonStandardUOMConversionType,
    MaterialUOMType,
} from 'shared/types';
import { getNonStandardUomDropdownParams } from 'shared/utility/dropdown';
import { isDefined } from 'shared/utility/guards';
import { IDropDownService } from './generic/dropdownService';

export interface IUomConversionService {
    getConverterForMaterial(
        oldUom?: string,
        newUom?: string,
        materialId?: string
    ): Promise<(uomMoney?: number, inverted?: boolean) => number | undefined>;
    getConverterForStandardAndNonStandardUom(
        oldUom?: string,
        newUom?: string,
        materialId?: string,
        queryClient?: QueryClient
    ): Promise<(uomMoney?: number, inverted?: boolean) => number | undefined>;
    getUomConversions(): Promise<ITableUOMConversion[]>;
    isNonStandardUom(
        materialUom: ITableMaterialUOMConversionRow
    ): materialUom is ITableNonStandardUOMConversion & { type: MaterialUOMType };
    isStandardUom(
        materialUom: ITableMaterialUOMConversionRow
    ): materialUom is ITableUOMConversion & { type: MaterialUOMType };
    matchingNonStandardUom({
        uom,
        materialUomList,
    }: {
        uom: string;
        materialUomList: ITableMaterialUOMConversion;
    }): ITableNonStandardUOMConversion;
    matchingStandardUom({
        uom,
        materialUomList,
    }: {
        uom: string;
        materialUomList: ITableMaterialUOMConversion;
    }): ITableUOMConversion;
    getNonStandardConversionType({
        oldUom,
        newUom,
        materialUomList,
    }: {
        oldUom: string;
        newUom: string;
        materialUomList: ITableMaterialUOMConversion;
    }): MaterialNonStandardUOMConversionType;
    nonStandardConversion({
        oldUom,
        newUom,
        materialUomList,
        value,
        kaiKgConversionFactor,
    }: {
        oldUom: string;
        newUom: string;
        materialUomList: ITableMaterialUOMConversion;
        value: number;
        kaiKgConversionFactor: number;
    }): number | undefined;
    convertKaiToKg({
        kaiPrice,
        materialKaiKgConversionFactor,
    }: {
        kaiPrice: number;
        materialKaiKgConversionFactor: number;
    }): number;
    convertQuantityKaiToKg({
        kaiQuantity,
        materialKaiKgConversionFactor,
    }: {
        kaiQuantity: number;
        materialKaiKgConversionFactor: number;
    }): number;
    convertKgToUom({ kgPrice, kgConversion }: { kgPrice: number; kgConversion: number }): number;
    convertQuantityKgToUom({ kgQuantity, kgConversion }: { kgQuantity: number; kgConversion: number }): number;
    convertKgToKai({
        kgPrice,
        materialKaiKgConversionFactor,
    }: {
        kgPrice: number;
        materialKaiKgConversionFactor: number;
    }): number;
    convertQuantityKgToKai({
        kgQuantity,
        materialKaiKgConversionFactor,
    }: {
        kgQuantity: number;
        materialKaiKgConversionFactor: number;
    }): number;
    convertUomToKg({ uomPrice, kgConversion }: { uomPrice: number; kgConversion: number }): number;
    convertQuantityUomToKg({ uomQuantity, kgConversion }: { uomQuantity: number; kgConversion: number }): number;
    convertKaiToUom({
        kaiPrice,
        kgConversion,
        materialKaiKgConversionFactor,
    }: {
        kaiPrice: number;
        kgConversion: number;
        materialKaiKgConversionFactor: number;
    }): number;
    convertQuantityKaiToUom({
        kaiQuantity,
        kgConversion,
        materialKaiKgConversionFactor,
    }: {
        kaiQuantity: number;
        kgConversion: number;
        materialKaiKgConversionFactor: number;
    }): number;
    convertUomToKai({
        uomPrice,
        kgConversion,
        materialKaiKgConversionFactor,
    }: {
        uomPrice: number;
        kgConversion: number;
        materialKaiKgConversionFactor: number;
    }): number;
    convertQuantityUomToKai({
        uomQuantity,
        kgConversion,
        materialKaiKgConversionFactor,
    }: {
        uomQuantity: number;
        kgConversion: number;
        materialKaiKgConversionFactor: number;
    }): number;
    convertUomToUom({
        uomPrice,
        fromKgConversion,
        toKgConversion,
    }: {
        uomPrice: number;
        fromKgConversion: number;
        toKgConversion: number;
    }): number;
    convertQuantityUomToUom({
        uomQuantity,
        fromKgConversion,
        toKgConversion,
    }: {
        uomQuantity: number;
        fromKgConversion: number;
        toKgConversion: number;
    }): number;
    convertNonStandardUomToKg({
        nonStandardPrice,
        nonStandardConversionFactor,
    }: {
        nonStandardPrice: number;
        nonStandardConversionFactor: number;
    }): number;
    convertKgToNonStandardUom({
        kgPrice,
        nonStandardConversionFactor,
    }: {
        kgPrice: number;
        nonStandardConversionFactor: number;
    }): number;
    convertQuantityKgToNonStandardUom({
        kgQuantity,
        nonStandardConversionFactor,
    }: {
        kgQuantity: number;
        nonStandardConversionFactor: number;
    }): number;
    convertNonStandardUomToNonStandardUom({
        nonStandardPrice,
        fromNonStandardConversionFactor,
        toNonStandardConversionFactor,
    }: {
        nonStandardPrice: number;
        fromNonStandardConversionFactor: number;
        toNonStandardConversionFactor: number;
    }): number;
    convertUomToNonStandardUom({
        uomPrice,
        fromKgConversion,
        toNonStandardConversionFactor,
    }: {
        uomPrice: number;
        fromKgConversion: number;
        toNonStandardConversionFactor: number;
    }): number;
    convertNonStandardUomToUom({
        nonStandardPrice,
        fromNonStandardConversionFactor,
        toKgConversion,
    }: {
        nonStandardPrice: number;
        fromNonStandardConversionFactor: number;
        toKgConversion: number;
    }): number;
    convertNonStandardUomToKai({
        nonStandardPrice,
        fromFactor,
        kaiKgConversionFactor,
    }: {
        nonStandardPrice: number;
        fromFactor: number;
        kaiKgConversionFactor: number;
    }): number;
    convertKaiToNonStandardUom({
        kaiPrice,
        kaiKgConversionFactor,
        toNonStandardConversionFactor,
    }: {
        kaiPrice: number;
        kaiKgConversionFactor: number;
        toNonStandardConversionFactor: number;
    }): number;
    convertQuantityKaiToNonStandardUom({
        kaiQuantity,
        kaiKgConversionFactor,
        toNonStandardConversionFactor,
    }: {
        kaiQuantity: number;
        kaiKgConversionFactor: number;
        toNonStandardConversionFactor: number;
    }): number;
    convertKaiToAnyUom({
        kaiPrice,
        uom,
        materialKaiKgConversionFactor,
        materialUomList,
    }: {
        kaiPrice?: number;
        uom: string;
        materialKaiKgConversionFactor: number;
        materialUomList: ITableMaterialUOMConversion;
    }): undefined | number;
}

export class UomConversionService implements IUomConversionService {
    constructor(
        private nonStandardUomDropdownService: IDropDownService<ITableNonStandardUOMConversion>,
        private uomDropdownService: IDropDownService<ITableUOMConversion>
    ) {}

    async getConverterForMaterial(oldUom?: string, newUom?: string, materialId?: string) {
        const uomConversions = await this.getUomConversions();

        const oldUomConversionFactor = uomConversions.find(uomConversion => uomConversion.uom === oldUom)?.kgConversion;
        const newUomConversionFactor = uomConversions.find(uomConversion => uomConversion.uom === newUom)?.kgConversion;

        if (!isDefined(oldUomConversionFactor)) throw Error('Cannot find current UOM conversion factor');
        if (!isDefined(newUomConversionFactor)) throw Error('Cannot find new UOM conversion factor');

        return (uomPrice?: number) => {
            if (!isDefined(uomPrice)) return undefined;

            const kgPrice = this.convertUomToKg({ uomPrice, kgConversion: oldUomConversionFactor });
            const newUomMoney = this.convertKgToUom({ kgPrice, kgConversion: newUomConversionFactor });

            return newUomMoney;
        };
    }
    async getConverterForStandardAndNonStandardUom(
        oldUom?: string,
        newUom?: string,
        materialId?: string,
        queryClient?: QueryClient
    ) {
        if (!oldUom) throw Error('Previous UOM is required');
        if (!newUom) throw Error('New UOM is required');
        if (!materialId) throw Error('Material ID is required');

        const uomList =
            (queryClient?.getQueryData?.([QUERY_KEYS.uomDropdown]) as ITableUOMConversion[]) ??
            (await this.getUomConversions());
        const nonStandardUomList =
            (queryClient?.getQueryData?.([
                QUERY_KEYS.nonStandardUOMDropdown,
                materialId,
            ]) as ITableNonStandardUOMConversion[]) ??
            (await this.nonStandardUomDropdownService.get(getNonStandardUomDropdownParams(materialId)));

        let oldUomConversionFactor = uomList.find(uomConversion => uomConversion.uom === oldUom)?.kgConversion;
        const newUomConversionFactor = uomList.find(uomConversion => uomConversion.uom === newUom)?.kgConversion;
        const oldNonStandardUomConversionFactor = nonStandardUomList.find(
            uomConversion => uomConversion.targetUnit === oldUom
        )?.factor;
        const newNonStandardUomConversionFactor = nonStandardUomList.find(
            uomConversion => uomConversion.targetUnit === newUom
        )?.factor;

        if (isNil(oldUomConversionFactor) && isNil(oldNonStandardUomConversionFactor)) {
            // If there is no conversion rate for current UOM, default to KG and allow to change from KG to new UOM
            oldUomConversionFactor = uomList.find(uomConversion => uomConversion.uom === UOM_KG)?.kgConversion;
        }

        if (isNil(oldUomConversionFactor) && isNil(oldNonStandardUomConversionFactor))
            throw Error('Cannot find previous UOM conversion factor');
        if (isNil(newUomConversionFactor) && isNil(newNonStandardUomConversionFactor))
            throw Error('Cannot find new UOM conversion factor');

        return (uomPrice?: number) => {
            if (!isDefined(uomPrice)) return undefined;
            if (oldUom === newUom) return uomPrice;

            if (isDefined(oldUomConversionFactor) && isDefined(newUomConversionFactor)) {
                return this.convertUomToUom({
                    uomPrice,
                    fromKgConversion: oldUomConversionFactor,
                    toKgConversion: newUomConversionFactor,
                });
            }

            if (isDefined(oldNonStandardUomConversionFactor) && isDefined(newNonStandardUomConversionFactor)) {
                return this.convertNonStandardUomToNonStandardUom({
                    nonStandardPrice: uomPrice,
                    fromNonStandardConversionFactor: oldNonStandardUomConversionFactor,
                    toNonStandardConversionFactor: newNonStandardUomConversionFactor,
                });
            }

            if (isDefined(oldUomConversionFactor) && isDefined(newNonStandardUomConversionFactor)) {
                return this.convertUomToNonStandardUom({
                    uomPrice,
                    fromKgConversion: oldUomConversionFactor,
                    toNonStandardConversionFactor: newNonStandardUomConversionFactor,
                });
            }

            if (isDefined(oldNonStandardUomConversionFactor) && isDefined(newUomConversionFactor)) {
                return this.convertNonStandardUomToUom({
                    nonStandardPrice: uomPrice,
                    fromNonStandardConversionFactor: oldNonStandardUomConversionFactor,
                    toKgConversion: newUomConversionFactor,
                });
            }

            throw Error('Cannot convert UOM');
        };
    }

    async getUomConversions(): Promise<ITableUOMConversion[]> {
        const response = await this.uomDropdownService.get({
            sortBy: 'uom',
            collectionFilter: {
                logicalOperator: 'and',
                filters: [
                    {
                        property: 'isSelectable',
                        operator: 'eq',
                        value: 'true',
                    },
                ],
            },
        });

        return response;
    }
    isNonStandardUom(
        materialUom: ITableMaterialUOMConversionRow
    ): materialUom is ITableNonStandardUOMConversion & { type: MaterialUOMType } {
        return materialUom.type === 'nonstandard';
    }
    isStandardUom(
        materialUom: ITableMaterialUOMConversionRow
    ): materialUom is ITableUOMConversion & { type: MaterialUOMType } {
        return materialUom.type === 'standard';
    }

    matchingNonStandardUom({ uom, materialUomList }: { uom: string; materialUomList: ITableMaterialUOMConversion }) {
        return materialUomList.find(
            materialUom => this.isNonStandardUom(materialUom) && materialUom.targetUnit === uom
        ) as ITableNonStandardUOMConversion;
    }

    matchingStandardUom({ uom, materialUomList }: { uom: string; materialUomList: ITableMaterialUOMConversion }) {
        return materialUomList.find(
            materialUom => this.isStandardUom(materialUom) && materialUom.uom === uom
        ) as ITableUOMConversion;
    }

    getNonStandardConversionType({
        oldUom,
        newUom,
        materialUomList,
    }: {
        oldUom: string;
        newUom: string;
        materialUomList: ITableMaterialUOMConversion;
    }) {
        const matchingFromNonStandardUom = this.matchingNonStandardUom({
            uom: oldUom,
            materialUomList,
        });

        const matchingToNonStandardUom = this.matchingNonStandardUom({
            uom: newUom,
            materialUomList,
        });

        // From NonStandard > To NonStandard
        if (matchingFromNonStandardUom && matchingToNonStandardUom) {
            return MaterialNonStandardUOMConversion.NonStandardToNonStandard;
        }

        const matchingFromUom = this.matchingStandardUom({
            uom: oldUom,
            materialUomList,
        });

        // From Standard > To NonStandard
        if (matchingFromUom && matchingToNonStandardUom) {
            return MaterialNonStandardUOMConversion.StandardToNonStandard;
        }

        const matchingToUom = this.matchingStandardUom({
            uom: newUom,
            materialUomList,
        });

        // From NonStandard > To Standard
        if (matchingFromNonStandardUom && matchingToUom) {
            return MaterialNonStandardUOMConversion.NonStandardToStandard;
        }

        // From NonStandard > To KAI
        if (matchingFromNonStandardUom && newUom === UOM_KAI) {
            return MaterialNonStandardUOMConversion.NonStandardToKAI;
        }

        // From KAI > To NonStandard
        if (oldUom === UOM_KAI && matchingToNonStandardUom) {
            return MaterialNonStandardUOMConversion.KAIToNonStandard;
        }

        return MaterialNonStandardUOMConversion.None;
    }

    nonStandardConversion({
        oldUom,
        newUom,
        materialUomList,
        value,
        kaiKgConversionFactor,
    }: {
        oldUom: string;
        newUom: string;
        materialUomList: ITableMaterialUOMConversion;
        value: number;
        kaiKgConversionFactor: number;
    }) {
        const nonStandardConversionType = this.getNonStandardConversionType({
            oldUom,
            newUom,
            materialUomList,
        });

        switch (nonStandardConversionType) {
            case MaterialNonStandardUOMConversion.NonStandardToNonStandard: {
                const matchingFromNonStandardUom = this.matchingNonStandardUom({
                    uom: oldUom,
                    materialUomList,
                });
                const matchingToNonStandardUom = this.matchingNonStandardUom({
                    uom: newUom,
                    materialUomList,
                });

                return this.convertNonStandardUomToNonStandardUom({
                    nonStandardPrice: value,
                    fromNonStandardConversionFactor: matchingFromNonStandardUom.factor,
                    toNonStandardConversionFactor: matchingToNonStandardUom.factor,
                });
            }
            case MaterialNonStandardUOMConversion.StandardToNonStandard: {
                const matchingFromUom = this.matchingStandardUom({
                    uom: oldUom,
                    materialUomList,
                });
                const matchingToNonStandardUom = this.matchingNonStandardUom({
                    uom: newUom,
                    materialUomList,
                });

                return this.convertUomToNonStandardUom({
                    uomPrice: value,
                    fromKgConversion: matchingFromUom.kgConversion ?? 1,
                    toNonStandardConversionFactor: matchingToNonStandardUom.factor,
                });
            }
            case MaterialNonStandardUOMConversion.NonStandardToStandard: {
                const matchingFromNonStandardUom = this.matchingNonStandardUom({
                    uom: oldUom,
                    materialUomList,
                });
                const matchingToUom = this.matchingStandardUom({
                    uom: newUom,
                    materialUomList,
                });

                return this.convertNonStandardUomToUom({
                    nonStandardPrice: value,
                    fromNonStandardConversionFactor: matchingFromNonStandardUom.factor,
                    toKgConversion: matchingToUom.kgConversion ?? 1,
                });
            }
            case MaterialNonStandardUOMConversion.NonStandardToKAI: {
                const matchingFromNonStandardUom = this.matchingNonStandardUom({
                    uom: oldUom,
                    materialUomList,
                });

                return this.convertNonStandardUomToKai({
                    nonStandardPrice: value,
                    fromFactor: matchingFromNonStandardUom.factor,
                    kaiKgConversionFactor,
                });
            }
            case MaterialNonStandardUOMConversion.KAIToNonStandard: {
                const matchingToNonStandardUom = this.matchingNonStandardUom({
                    uom: newUom,
                    materialUomList,
                });

                return this.convertKaiToNonStandardUom({
                    kaiPrice: value,
                    kaiKgConversionFactor,
                    toNonStandardConversionFactor: matchingToNonStandardUom.factor,
                });
            }
            case MaterialNonStandardUOMConversion.None:
            default:
                return undefined;
        }
    }

    convertKaiToKg({
        kaiPrice,
        materialKaiKgConversionFactor,
    }: {
        kaiPrice: number;
        materialKaiKgConversionFactor: number;
    }) {
        const kgPrice = kaiPrice / materialKaiKgConversionFactor;
        return kgPrice;
    }
    convertQuantityKaiToKg({
        kaiQuantity,
        materialKaiKgConversionFactor,
    }: {
        kaiQuantity: number;
        materialKaiKgConversionFactor: number;
    }) {
        const kgQuantity = kaiQuantity * materialKaiKgConversionFactor;
        return kgQuantity;
    }

    convertKgToUom({ kgPrice, kgConversion }: { kgPrice: number; kgConversion: number }) {
        const uomPrice = kgPrice * kgConversion;
        return uomPrice;
    }

    convertQuantityKgToUom({ kgQuantity, kgConversion }: { kgQuantity: number; kgConversion: number }) {
        const uomQuantity = kgQuantity / kgConversion;
        return uomQuantity;
    }

    convertKgToKai({
        kgPrice,
        materialKaiKgConversionFactor,
    }: {
        kgPrice: number;
        materialKaiKgConversionFactor: number;
    }) {
        const kaiPrice = kgPrice * materialKaiKgConversionFactor;
        return kaiPrice;
    }

    convertQuantityKgToKai({
        kgQuantity,
        materialKaiKgConversionFactor,
    }: {
        kgQuantity: number;
        materialKaiKgConversionFactor: number;
    }) {
        const kaiQuantity = kgQuantity / materialKaiKgConversionFactor;
        return kaiQuantity;
    }

    convertUomToKg({ uomPrice, kgConversion }: { uomPrice: number; kgConversion: number }) {
        const kgPrice = uomPrice / kgConversion;
        return kgPrice;
    }
    convertQuantityUomToKg({ uomQuantity, kgConversion }: { uomQuantity: number; kgConversion: number }) {
        const kgQuantity = uomQuantity / kgConversion;
        return kgQuantity;
    }

    convertKaiToUom({
        kaiPrice,
        kgConversion,
        materialKaiKgConversionFactor,
    }: {
        kaiPrice: number;
        kgConversion: number;
        materialKaiKgConversionFactor: number;
    }) {
        const kgPrice = this.convertKaiToKg({ kaiPrice, materialKaiKgConversionFactor });
        const uomPrice = this.convertKgToUom({ kgPrice, kgConversion });
        return uomPrice;
    }

    convertQuantityKaiToUom({
        kaiQuantity,
        kgConversion,
        materialKaiKgConversionFactor,
    }: {
        kaiQuantity: number;
        kgConversion: number;
        materialKaiKgConversionFactor: number;
    }) {
        const kgQuantity = this.convertQuantityKaiToKg({ kaiQuantity, materialKaiKgConversionFactor });
        const uomQuantity = this.convertQuantityKgToUom({ kgQuantity, kgConversion });
        return uomQuantity;
    }

    convertUomToKai({
        uomPrice,
        kgConversion,
        materialKaiKgConversionFactor,
    }: {
        uomPrice: number;
        kgConversion: number;
        materialKaiKgConversionFactor: number;
    }) {
        const kgPrice = this.convertUomToKg({ uomPrice, kgConversion });
        const kaiPrice = this.convertKgToKai({ kgPrice, materialKaiKgConversionFactor });
        return kaiPrice;
    }

    convertQuantityUomToKai({
        uomQuantity,
        kgConversion,
        materialKaiKgConversionFactor,
    }: {
        uomQuantity: number;
        kgConversion: number;
        materialKaiKgConversionFactor: number;
    }) {
        const kgQuantity = this.convertQuantityUomToKg({ uomQuantity, kgConversion });
        const kaiQuantity = this.convertQuantityKgToKai({ kgQuantity, materialKaiKgConversionFactor });
        return kaiQuantity;
    }

    convertUomToUom({
        uomPrice,
        fromKgConversion,
        toKgConversion,
    }: {
        uomPrice: number;
        fromKgConversion: number;
        toKgConversion: number;
    }) {
        const kgPrice = this.convertUomToKg({ uomPrice, kgConversion: fromKgConversion });
        const kaiPrice = this.convertKgToUom({ kgPrice, kgConversion: toKgConversion });
        return kaiPrice;
    }

    convertQuantityUomToUom({
        uomQuantity,
        fromKgConversion,
        toKgConversion,
    }: {
        uomQuantity: number;
        fromKgConversion: number;
        toKgConversion: number;
    }) {
        const kgQuantity = this.convertQuantityUomToKg({ uomQuantity, kgConversion: fromKgConversion });
        const kaiQuantity = this.convertQuantityKgToUom({ kgQuantity, kgConversion: toKgConversion });
        return kaiQuantity;
    }

    convertNonStandardUomToKg({
        nonStandardPrice,
        nonStandardConversionFactor,
    }: {
        nonStandardPrice: number;
        nonStandardConversionFactor: number;
    }) {
        const kgValue = nonStandardPrice / nonStandardConversionFactor;

        if (isNaN(kgValue)) throw Error('Cannot convert NonStandard UOM to KG');

        return kgValue;
    }

    convertKgToNonStandardUom({
        kgPrice,
        nonStandardConversionFactor,
    }: {
        kgPrice: number;
        nonStandardConversionFactor: number;
    }) {
        const nonStandardValue = kgPrice * nonStandardConversionFactor;

        if (isNaN(nonStandardValue)) throw Error('Cannot convert KG to NonStandard UOM');

        return nonStandardValue;
    }
    convertQuantityKgToNonStandardUom({
        kgQuantity,
        nonStandardConversionFactor,
    }: {
        kgQuantity: number;
        nonStandardConversionFactor: number;
    }) {
        const nonStandardQuantity = kgQuantity / nonStandardConversionFactor;

        if (isNaN(nonStandardQuantity)) throw Error('Cannot convert KG to NonStandard UOM');

        return nonStandardQuantity;
    }

    convertNonStandardUomToNonStandardUom({
        nonStandardPrice,
        fromNonStandardConversionFactor,
        toNonStandardConversionFactor,
    }: {
        nonStandardPrice: number;
        fromNonStandardConversionFactor: number;
        toNonStandardConversionFactor: number;
    }) {
        const kgPrice = this.convertNonStandardUomToKg({
            nonStandardPrice,
            nonStandardConversionFactor: fromNonStandardConversionFactor,
        });
        const newNonStandardValue = this.convertKgToNonStandardUom({
            kgPrice,
            nonStandardConversionFactor: toNonStandardConversionFactor,
        });

        return newNonStandardValue;
    }

    convertUomToNonStandardUom({
        uomPrice,
        fromKgConversion,
        toNonStandardConversionFactor,
    }: {
        uomPrice: number;
        fromKgConversion: number;
        toNonStandardConversionFactor: number;
    }) {
        const kgPrice = this.convertUomToKg({ uomPrice, kgConversion: fromKgConversion });

        const nonStandardValue = this.convertKgToNonStandardUom({
            kgPrice: kgPrice,
            nonStandardConversionFactor: toNonStandardConversionFactor,
        });

        if (isNaN(nonStandardValue)) throw Error('Cannot convert UOM to NonStandard UOM');

        return nonStandardValue;
    }

    convertNonStandardUomToUom({
        nonStandardPrice,
        fromNonStandardConversionFactor,
        toKgConversion,
    }: {
        nonStandardPrice: number;
        fromNonStandardConversionFactor: number;
        toKgConversion: number;
    }) {
        const kgPrice = this.convertNonStandardUomToKg({
            nonStandardPrice: nonStandardPrice,
            nonStandardConversionFactor: fromNonStandardConversionFactor,
        });
        const uomValue = this.convertKgToUom({ kgPrice: kgPrice, kgConversion: toKgConversion });

        if (isNaN(uomValue)) throw Error('Cannot convert NonStandard UOM to UOM');

        return uomValue;
    }

    convertNonStandardUomToKai({
        nonStandardPrice,
        fromFactor,
        kaiKgConversionFactor,
    }: {
        nonStandardPrice: number;
        fromFactor: number;
        kaiKgConversionFactor: number;
    }) {
        const kgPrice = this.convertNonStandardUomToKg({
            nonStandardPrice,
            nonStandardConversionFactor: fromFactor,
        });
        const kaiPrice = this.convertKgToKai({
            kgPrice,
            materialKaiKgConversionFactor: kaiKgConversionFactor,
        });

        if (isNaN(kaiPrice)) throw Error('Cannot convert NonStandard UOM to KAI');

        return kaiPrice;
    }

    convertKaiToNonStandardUom({
        kaiPrice,
        kaiKgConversionFactor,
        toNonStandardConversionFactor,
    }: {
        kaiPrice: number;
        kaiKgConversionFactor: number;
        toNonStandardConversionFactor: number;
    }) {
        const kgPrice = this.convertKaiToKg({
            kaiPrice,
            materialKaiKgConversionFactor: kaiKgConversionFactor,
        });
        const nonStandardPrice = this.convertKgToNonStandardUom({
            kgPrice,
            nonStandardConversionFactor: toNonStandardConversionFactor,
        });

        if (isNaN(nonStandardPrice)) throw Error('Cannot convert KAI to NonStandard UOM');

        return nonStandardPrice;
    }
    convertQuantityKaiToNonStandardUom({
        kaiQuantity,
        kaiKgConversionFactor,
        toNonStandardConversionFactor,
    }: {
        kaiQuantity: number;
        kaiKgConversionFactor: number;
        toNonStandardConversionFactor: number;
    }) {
        const kgQuantity = this.convertQuantityKaiToKg({
            kaiQuantity,
            materialKaiKgConversionFactor: kaiKgConversionFactor,
        });
        const nonStandardQuantity = this.convertQuantityKgToNonStandardUom({
            kgQuantity,
            nonStandardConversionFactor: toNonStandardConversionFactor,
        });

        if (isNaN(nonStandardQuantity)) throw Error('Cannot convert KAI to NonStandard UOM');

        return nonStandardQuantity;
    }

    convertKaiToAnyUom({
        kaiPrice,
        uom,
        materialKaiKgConversionFactor,
        materialUomList,
    }: {
        kaiPrice?: number;
        uom: string;
        materialKaiKgConversionFactor: number;
        materialUomList: ITableMaterialUOMConversion;
    }) {
        const isConvertingToKai = uom === UOM_KAI;

        if (isConvertingToKai || !kaiPrice) {
            return kaiPrice;
        }

        const uomConversionRow = materialUomList.find(materialUom => {
            if (this.isNonStandardUom(materialUom)) {
                return materialUom.targetUnit === uom;
            }
            if (this.isStandardUom(materialUom)) {
                return materialUom.uom === uom;
            }
            return false;
        });

        if (!uomConversionRow) {
            throw Error('Cannot find UOM conversion row');
        }

        if (this.isNonStandardUom(uomConversionRow)) {
            return this.convertKaiToNonStandardUom({
                kaiPrice,
                kaiKgConversionFactor: materialKaiKgConversionFactor,
                toNonStandardConversionFactor: uomConversionRow.factor,
            });
        }

        return this.convertKaiToUom({
            kaiPrice,
            materialKaiKgConversionFactor,
            kgConversion: uomConversionRow.kgConversion ?? 1,
        });
    }
}
