import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSelectedCustomerPrice } from 'shared/providers/provideSelectedCustomerPrice';
import { CustomerPricesExceptionsResolver } from './CustomerPricesExceptionsResolver';

export function CustomerPricesExceptionsModal() {
    const { close, isExceptionsModalOpen, status } = useSelectedCustomerPrice();

    return (
        <Dialog onClose={close} open={isExceptionsModalOpen} maxWidth={'lg'} fullWidth>
            <DialogTitle>
                <Box>Exception Prices:</Box>
                <Box pl={2}>
                    <Typography variant='body1'>
                        {status?.selectedCustomerPrice?.parent} | {status?.selectedCustomerPrice?.soldTo} |{' '}
                        {status?.selectedCustomerPrice?.shipTo}
                    </Typography>
                </Box>
                <Box pl={2}>
                    <Typography variant='body1'>
                        {status?.selectedCustomerPrice?.material} | {status?.selectedCustomerPrice?.materialId} |{' '}
                        {status?.selectedCustomerPrice?.application} | {status?.selectedCustomerPrice?.orgRegion}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ p: 2 }}>
                    <CustomerPricesExceptionsResolver customerPricesRow={status?.selectedCustomerPrice} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
