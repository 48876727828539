import { IDataAccessService } from '@price-for-profit/micro-services';
import { VIEW_MARKET_PRICE_HISTORY } from 'shared/constants';
import { IViewMarketPriceHistory } from 'shared/types';
import { GridService, IGridService } from './generic/gridService';

export interface IProductPricesMarketPriceHistoryService extends IGridService<IViewMarketPriceHistory> {}

export class ProductPricesMarketPriceHistoryService
    extends GridService<IViewMarketPriceHistory>
    implements IProductPricesMarketPriceHistoryService {
    constructor(dasService: IDataAccessService) {
        super(dasService, VIEW_MARKET_PRICE_HISTORY);
    }
}
