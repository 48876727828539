import { CLIENT_ID } from '@insight2profit/drive-app';
import { DataAccessPaginatedResponse, IDataAccessService } from '@price-for-profit/micro-services';
import { DATABASE_LABEL, VIEW_EXCHANGE_RATES } from 'shared/constants';
import { IViewExchangeRates } from 'shared/types';

export interface IExchangeRatesService {
    getExchangeRates(): Promise<DataAccessPaginatedResponse<IViewExchangeRates>>;
}

export class ExchangeRatesService implements IExchangeRatesService {
    constructor(private dasService: IDataAccessService) {}

    async getExchangeRates(): Promise<DataAccessPaginatedResponse<IViewExchangeRates>> {
        const response = await this.dasService.getCollection<IViewExchangeRates, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: VIEW_EXCHANGE_RATES,
            page: 0,
            pageSize: 100,
        });
        const newData = response.data.some(row => {
            return row.fromCurrencyCode === 'USD';
        })
            ? response.data
            : [...response.data, usdExchangeRate];
        return { ...response, data: newData };
    }
}
const now = new Date();
const usdExchangeRate: IViewExchangeRates = {
    fromCurrencyCode: 'USD',
    monthNumber: now.getMonth(),
    year: now.getFullYear(),
    exchangeRate: 1,
    fxId: -1,
    modifiedBy: 'default',
    effectiveStart: now.toISOString(),
    deleted: false,
};
