import { useDebounce } from '@insight2profit/drive-app';
import { TextField } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useCustomerPricesMaterialDropdown } from 'shared/queries/customerPricesDropdownQueries';
import { CustomerPricesMaterialDropDownObject } from 'shared/types';
import { CustomerPricesAddNewAutocomplete } from './customerPricesAddNewAutocomplete';

export function CustomerPricesMaterialAutocomplete(params: GridRenderEditCellParams) {
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 500);
    const { data, isLoading, isFetching } = useCustomerPricesMaterialDropdown({
        searchValue: debouncedSearchValue,
        selectedBusinessLine: params?.row?.businessLine,
    });
    const apiRef = useGridApiContext();
    return (
        <CustomerPricesAddNewAutocomplete
            isLoading={isLoading}
            setEditCellValue={apiRef.current.setEditCellValue}
            rowId={params.row.id}
            field={params.field}
            value={params.value}
            options={debouncedSearchValue && data ? data : []}
            error={params.error}
            getOptionLabel={(option: CustomerPricesMaterialDropDownObject) => {
                if (typeof option === 'object' && typeof option.search === 'string') return option.search;
                if (typeof option === 'string') return option;
                return '';
            }}
            isOptionEqualToValue={(
                option: CustomerPricesMaterialDropDownObject,
                value: CustomerPricesMaterialDropDownObject | string
            ) => {
                if (typeof value === 'string') {
                    return option.materialId === value;
                }
                return option.materialId === value.materialId;
            }}
            placeholder={'Material'}
            onChange={(_, value) => {
                setSearchValue(value?.search ?? '');
                apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: value });

                if (value !== params.row.material) {
                    apiRef.current.setEditCellValue({ id: params.id, field: 'orgRegion', value: null });
                    apiRef.current.setEditCellValue({ id: params.id, field: 'application', value: null });
                    apiRef.current.setEditCellValue({ id: params.id, field: 'marketSegment', value: null });
                }
            }}
            onBlur={event => setSearchValue('')}
            loading={(isLoading || isFetching) && !!debouncedSearchValue}
            onInputChange={(event, value, reason) => {
                if (value?.length >= 3) setSearchValue(value);
            }}
            renderInput={params => <TextField {...params} placeholder='Material' />}
            disabled={!params?.row?.businessLine}
        />
    );
}
