import { useDebounce } from '@insight2profit/drive-app';
import { Box } from '@mui/material';
import { Embed } from 'powerbi-client';
import { EmbedProps, PowerBIEmbed } from 'powerbi-client-react';
import { useLayoutEffect, useRef } from 'react';
import useWindowSize from 'shared/hooks/use-window-resize';

interface I2pAnalyticsEmbedProps {
    analyticsEmbedProps: EmbedProps;
    height?: string | number;
    width?: string | number;
}

export const ANALYTICS_REPORT_CLASS = 'i2p-individual-analytics-report';
export function EmbedIndividualAnalytic({
    analyticsEmbedProps,
    height = '100%',
    width = '100%',
}: I2pAnalyticsEmbedProps) {
    const windowSize = useWindowSize();
    const windowSizeDebounced = useDebounce(windowSize, 500);

    useLayoutEffect(() => {
        embeddedComponentRef.current?.reload();
    }, [windowSizeDebounced]);

    const embeddedComponentRef = useRef<Embed | null>(null);

    return (
        <Box
            sx={{
                height,
                width,
                [`& .${ANALYTICS_REPORT_CLASS}`]: {
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                },
            }}
        >
            <PowerBIEmbed
                getEmbeddedComponent={embeddedComponent => {
                    embeddedComponentRef.current = embeddedComponent;
                }}
                {...analyticsEmbedProps}
                cssClassName={ANALYTICS_REPORT_CLASS}
            />
        </Box>
    );
}
