import { createContext, useCallback, useContext } from 'react';
import { useProductPricesLocksQuery } from 'shared/queries';
import { IViewProductPricesLocks, NoPropsJustChildren } from 'shared/types';

export const productPricesLocksContext = createContext<ReturnType<typeof useProductPricesLocksQuery>>({
    data: [],
    isLoading: false,
    isFetching: false,
    invalidateQuery: async () => {},
});

export function ProvideProductPricesLocks({ children }: NoPropsJustChildren) {
    const productPricesLocks = useProductPricesLocksQuery();

    return (
        <productPricesLocksContext.Provider value={productPricesLocks}>{children}</productPricesLocksContext.Provider>
    );
}

type IsBusinessLineLockedReturn =
    | { isLocked: true; matchingLock: undefined }
    | { isLocked: boolean; matchingLock: IViewProductPricesLocks };

export function useProductPricesLocks() {
    const context = useContext(productPricesLocksContext);

    const isBusinessLineLocked = useCallback(
        (businessLine: string): IsBusinessLineLockedReturn => {
            const matchingLock = context.data?.find(lock => {
                return lock.businessLine.toUpperCase() === businessLine.toUpperCase();
            });

            if (!matchingLock) return { isLocked: true, matchingLock };
            return { isLocked: matchingLock.isLocked, matchingLock };
        },
        [context.data]
    );

    return {
        ...context,
        isBusinessLineLocked,
    };
}
