import { CircularProgress } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useCustomerPricesOrgRegionDropdown } from 'shared/queries/customerPricesDropdownQueries';
import { getCustomerPriceMaterialId } from 'shared/types';
import { CustomerPricesAddNewAutocomplete } from './customerPricesAddNewAutocomplete';

export function CustomerPricesOrgRegionAutocomplete(params: GridRenderEditCellParams) {
    const selectedBusinessLine = params?.row?.businessLine;
    const selectedMaterialId = getCustomerPriceMaterialId(params?.row?.material);
    const { data, isLoading } = useCustomerPricesOrgRegionDropdown({
        selectedBusinessLine,
        selectedMaterialId,
    });
    const apiRef = useGridApiContext();

    if (!selectedBusinessLine) return null;

    if (isLoading) return <CircularProgress size={20} />;

    return (
        <CustomerPricesAddNewAutocomplete
            isLoading={isLoading}
            setEditCellValue={apiRef.current.setEditCellValue}
            rowId={params.row.id}
            field={params.field}
            value={params.value}
            options={data}
            error={params.error}
            placeholder='Org Region'
            disabled={!selectedBusinessLine || !selectedMaterialId}
            onChange={(_, value) => {
                apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: value });

                if (value !== params.row?.orgRegion) {
                    apiRef.current.setEditCellValue({ id: params.id, field: 'application', value: null });
                    apiRef.current.setEditCellValue({ id: params.id, field: 'marketSegment', value: null });
                }
            }}
        />
    );
}
