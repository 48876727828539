import { CLIENT_ID, DefaultAppConfig, LoginPermissionKey } from '@insight2profit/drive-app';
import { ITableUOMConversion } from './customerPrices';
// type alias so the node backend service can read the type

export interface AppConfig extends DefaultAppConfig {
    authorizationAppId: string;
    calculationAppId: string;
    dataAccessAppId: string;
    exportAppId: string;
    powerBiAppId: string;
    eventAppId: string;
    i2pAuthorityUrl: string;
}

type PagePermissionType = `product-prices` | `customer-prices`;

type AddRecordPermissionType = 'productprices' | 'customerprices';

type DataPermissionType =
    | `global-revised-minimum`
    | `global-revised-target`
    | `regional-revised-minimum`
    | `regional-revised-target`
    | `revised-customer-price`
    | `customer-exceptions`;

type PreferencePermissionType = `show-cm-percent` | `show-price`;

type RegionPermissionType =
    | `pf-emea`
    | `pf-europe`
    | `pf-mea`
    | `pf-india`
    | `pf-ne-asia`
    | `pf-north-america`
    | `pf-se-asia`
    | `pf-south-america`
    | `ts-asia-pacific`
    | `ts-emea`
    | `ts-north-america`
    | `ts-south-america`;

type MarketRegionTypePermissionType = `inclusive` | `exclusive`;

type MarketSegmentPermissionType =
    | `not-assigned`
    | `ts-catalyst`
    | `ts-coatings`
    | `ts-construction`
    | `ts-electronics`
    | `ts-foundry`
    | `ts-hpmo`
    | `ts-oil-well-service`
    | `ts-other`
    | `ts-packaging`
    | `ts-pharma`
    | `ts-porous-ceramics`;

type ApproverTierPermissionType = `1` | `2` | `3` | `4`;

type BusinessLinePermissionType = `a-and-f` | `i-s` | `p-and-c` | `n-r`;
export type CustomerPricesLevelPermissionType = `region` | `row`;

type ModelPermissionType = `af-cpe` | `is-cpe` | `pc-cpe` | `nr-cpe`;

type FeatureFlagPermissionType = `cp-exceptions` | `cp-scale-pricing` | `cp-quote`;

type PagePermission = `${PagePermissionType}.page`;
type AddRecordPermission = `${AddRecordPermissionType}.add`;
type DataReadPermission = `${DataPermissionType}.read`;
type DataWritePermission = `${DataPermissionType}.write`;
type DataPermission = DataReadPermission | DataWritePermission;
type PreferencePermission = `${PreferencePermissionType}.preference`;
type RegionPermission = `${RegionPermissionType}.region`;
type ApproverTierPermission = `${ApproverTierPermissionType}.tier`;
type BusinessLinePermission = `${BusinessLinePermissionType}.business-line`;
type CustomerPricesLevelPermission = `${CustomerPricesLevelPermissionType}.customer-prices-level`;
type ModelPermission = `${ModelPermissionType}.model`;
type FeatureFlagPermission = `${FeatureFlagPermissionType}.view`;
type MarketRegionTypePermission = `${MarketRegionTypePermissionType}.market-region-type`;
type MarketSegmentPermission = `${MarketSegmentPermissionType}.market-segment`;

export type AppPermission = `${typeof CLIENT_ID}.${
    | LoginPermissionKey
    | PagePermission
    | AddRecordPermission
    | DataPermission
    | PreferencePermission
    | RegionPermission
    | ApproverTierPermission
    | BusinessLinePermission
    | CustomerPricesLevelPermission
    | ModelPermission
    | FeatureFlagPermission
    | MarketRegionTypePermission
    | MarketSegmentPermission}`;

export interface ITableNonStandardUOMConversion {
    materialNonStandardUomConversionId: number;
    materialId: string;
    baseUnit: string;
    targetUnit: string;
    factor: number;
    displayUnit: string;
}

export type MaterialUOMType = 'standard' | 'nonstandard';

export type ITableMaterialUOMConversionRow = (ITableUOMConversion | ITableNonStandardUOMConversion) & {
    type: MaterialUOMType;
};

export type ITableMaterialUOMConversion = Array<ITableMaterialUOMConversionRow>;

export const MaterialNonStandardUOMConversion = {
    NonStandardToNonStandard: 'NonStandardToNonStandard',
    StandardToNonStandard: 'StandardToNonStandard',
    NonStandardToStandard: 'NonStandardToStandard',
    NonStandardToKAI: 'NonStandardToKAI',
    KAIToNonStandard: 'KAIToNonStandard',
    None: 'None',
} as const;

export type MaterialNonStandardUOMConversionType = typeof MaterialNonStandardUOMConversion[keyof typeof MaterialNonStandardUOMConversion];
