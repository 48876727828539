import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import { IViewProductPrices } from 'shared/types';

interface ProductPriceDrawerHeaderProps {
    material: string;
    productLine: string;
    orgRegion: string;
    setSelectedProduct: React.Dispatch<React.SetStateAction<IViewProductPrices | null>>;
}

export function ProductPriceDrawerHeader({
    material,
    productLine,
    orgRegion,
    setSelectedProduct,
}: ProductPriceDrawerHeaderProps) {
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                <Box
                    width='50px'
                    sx={{ background: 'lightgray', borderRadius: '3px', height: '10px', cursor: 'pointer' }}
                    onClick={() => setSelectedProduct(null)}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    background:
                        'linear-gradient(0deg, rgba(0, 68, 137, 0.04), rgba(0, 68, 137, 0.04)), linear-gradient(0deg, rgba(255, 255, 255, 0.002), rgba(255, 255, 255, 0.002))',
                    height: '50px',
                    alignItems: 'center',
                }}
            >
                <strong> Material: &nbsp;</strong>
                <span>
                    {material} | {productLine} | {orgRegion}
                </span>
            </Box>
            <Divider variant='middle' />
        </>
    );
}
