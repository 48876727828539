import { useService, useUser, CLIENT_ID } from '@insight2profit/drive-app';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useUserPermissions } from 'shared/hooks';
import { useCustomerPricesSummary } from 'shared/providers';
import { ICustomerPricesApprovalMassActionParameters, ICustomerPricesMassActionForm } from 'shared/types';

export type CustomerPricesApprovalMassActionMutationParams = {
    state: ServerSideState;
    parameters: ICustomerPricesApprovalMassActionParameters;
};

export type UseCustomerPricesApprovalMassActionMutationProps = {
    state: ServerSideState;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

export function useCustomerPricesApprovalMassActionMutation({
    state,
    onSuccess,
    onError,
}: UseCustomerPricesApprovalMassActionMutationProps) {
    const { customerPricesService, activityTrackingService, appConfig } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { invalidateQuery: invalidateSummaryQuery } = useCustomerPricesSummary();
    const { customerPricesPermittedRowLevels, userApproverTier } = useUserPermissions();
    const user = useUser();

    const mutation = useMutation<void, Error, CustomerPricesApprovalMassActionMutationParams>({
        mutationKey: ['customerPricesApprovalMassAction'],
        mutationFn: async ({ state, parameters }) => {
            return await customerPricesService.approvalMassAction({
                state,
                customerPricesPermittedRowLevels,
                parameters,
                userApproverTier,
            });
        },
        onSuccess: () => {
            enqueueSnackbar('Mass action successfully completed', { variant: 'success' });
            onSuccess?.();
            invalidateSummaryQuery();
            activityTrackingService.trackStandardUserActivityEvent({
                action: 'ApproveMassAction',
                user: {
                    displayName: user.displayName,
                    email: user.email,
                    id: user.id,
                },
                payload: {
                    pageName: 'CustomerPrices',
                },
                correlationId: '',
                eventType: 'ButtonClick',
                applicationId: appConfig.appId,
                clientId: CLIENT_ID,
            });
        },
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            onError?.(error);
        },
    });

    const onSubmit = (form: ICustomerPricesMassActionForm) => {
        mutation.mutate({
            state,
            parameters: customerPricesFormToApprovalParameterMapper(form),
        });
    };

    return { onSubmit, isLoading: mutation.isLoading };
}

const customerPricesFormToApprovalParameterMapper = (
    form: ICustomerPricesMassActionForm
): ICustomerPricesApprovalMassActionParameters =>
    ({
        Type: form.type as ICustomerPricesApprovalMassActionParameters['Type'],
        Comment: form.comment,
        ModifiedBy: form.modifiedBy,
    } as ICustomerPricesApprovalMassActionParameters);
