import { useService } from '@insight2profit/drive-app';
import { ServerSideState } from '@price-for-profit/data-grid';
import { DataAccessPaginatedResponse } from '@price-for-profit/micro-services';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import axios from 'axios';

import { useUserPermissions } from 'shared/hooks';
import { ICustomerPricesMassActionForm, ICustomerPricesMassActionValidation } from 'shared/types';
import {
    customerPricesFormToParameterMapper,
    CustomerPricesMassActionMutationParams,
} from './customerPricesMassActionMutation';
import { useExchangeRates } from 'shared/providers';

interface UseCustomerPricesMassActionValidationMutationProps {
    customerPricesState: ServerSideState;
}

export function useCustomerPricesMassActionValidationMutation({
    customerPricesState,
}: UseCustomerPricesMassActionValidationMutationProps) {
    const { customerPricesService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { customerPricesPermittedRowLevels } = useUserPermissions();

    const [validationRows, setValidationRows] = useState<ICustomerPricesMassActionValidation[]>([]);
    const [isValidated, setIsValidated] = useState(false);

    const { isForeignCurrency } = useExchangeRates();

    const mutation = useMutation<
        DataAccessPaginatedResponse<ICustomerPricesMassActionValidation>,
        Error,
        CustomerPricesMassActionMutationParams
    >({
        mutationKey: ['customerPricesMassActionValidationMutation'],
        mutationFn: async ({ state, parameters }) => {
            return await customerPricesService.validateMassAction({
                state,
                customerPricesPermittedRowLevels,
                parameters: { ...parameters, IsForeignCurrency: isForeignCurrency },
            });
        },
        onSuccess: () => {
            setIsValidated(true);
        },
    });

    const onSubmitValidate = async (data: ICustomerPricesMassActionForm) => {
        try {
            const validationNeededRows = await mutation.mutateAsync({
                state: customerPricesState,
                parameters: customerPricesFormToParameterMapper(data),
            });
            setValidationRows(validationNeededRows.data);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                enqueueSnackbar(error?.response?.data.error, { variant: 'error' });
            } else {
                enqueueSnackbar((error as Error).message, { variant: 'error' });
            }
            setIsValidated(false);
        }
    };

    return { mutation, onSubmitValidate, validationRows, setValidationRows, isValidated, setIsValidated };
}
