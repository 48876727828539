export function cmPrice({ price = 0, cost = 0 }: { price?: number; cost?: number }): number {
    return price - cost;
}

export function cmPercent({ price = 0, cost = 0 }: { price?: number; cost?: number }): number {
    if (price === 0) return 0;
    return (price - cost) / price;
}

export function priceFromCMPrice({ cmPrice = 0, cost = 0 }: { cmPrice?: number; cost?: number }): number {
    return cost + cmPrice;
}

export function priceFromCMPercent({
    cmPercent = 0,
    updatedCost = 0,
}: {
    cmPercent?: number;
    updatedCost?: number;
}): number {
    if (cmPercent === 1) return 0;
    const price = updatedCost / (1 - cmPercent);
    return price;
}

export function minimumPrice(price?: number) {
    return price === undefined || price <= 0 ? 0.01 : price;
}
