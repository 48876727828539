import { Stack } from '@mui/system';
import { PageLayout } from 'shared/components/layouts';
import { CustomerPriceAddProspective } from './customerPriceAddProspective';

export function CustomerPricesAddProspectiveResolver() {
    return (
        <PageLayout pageName='Add Prospective Customer'>
            <Stack gap={2} height='calc(100vh - 300px)' width={'100%'}>
                <CustomerPriceAddProspective />
            </Stack>
        </PageLayout>
    );
}
