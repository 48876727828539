import { CLIENT_ID } from '@insight2profit/drive-app';
import { DataAccessPaginatedResponse, IDataAccessService } from '@price-for-profit/micro-services';
import { DATABASE_LABEL, QUERY_NAME_CUSTOMER_PRICES_SUMMARY } from 'shared/constants';
import { ICustomerPricesSummaryResponse } from 'shared/types/customerPricesSummary';

export interface IGetCustomerPricesSummaryProps {
    userDisplayName: any;
    isRegionLevel: boolean;
    regions: string;
    businessLines: string;
    marketSegments: string;
    marketRegionType: string;
}

export interface ICustomerPricesSummaryService {
    getCustomerPricesSummary(
        parameters: IGetCustomerPricesSummaryProps
    ): Promise<DataAccessPaginatedResponse<ICustomerPricesSummaryResponse>>;
}

export class CustomerPricesSummaryService implements ICustomerPricesSummaryService {
    constructor(private dasService: IDataAccessService) {}

    async getCustomerPricesSummary(
        parameters: IGetCustomerPricesSummaryProps
    ): Promise<DataAccessPaginatedResponse<ICustomerPricesSummaryResponse>> {
        return await this.dasService.executeNamedQuery<{}, ICustomerPricesSummaryResponse>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            queryName: QUERY_NAME_CUSTOMER_PRICES_SUMMARY,
            parameters,
        });
    }
}
