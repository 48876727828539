import { Control, FieldError, FieldValues, Path, RegisterOptions, useController } from 'react-hook-form';
import { SelectOption } from 'shared/types';
import { FormlessSelect } from './FormlessSelect';

type FormSelectProps<T extends FieldValues> = {
    label: string;
    name: Path<T>;
    control: Control<T>;
    componentProps?: {
        type?: string;
        startAdornment?: JSX.Element;
        endAdornment?: JSX.Element;
    };
    rules?: Omit<RegisterOptions<T>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    required?: boolean;
    fieldError?: FieldError;
    options: SelectOption[];
    isLoading?: boolean;
    fullWidth?: boolean;
    width?: string;
    isDisabled?: boolean;
};

export function FormSelect<T extends FieldValues>({
    label,
    name,
    control,
    rules,
    options,
    isLoading = false,
    fullWidth = false,
    width = 'auto',
    isDisabled = false,
}: FormSelectProps<T>) {
    const { field } = useController({
        name,
        control,
        rules,
    });

    return <FormlessSelect {...{ label, options, isLoading, fullWidth, width, field, isDisabled }} />;
}
