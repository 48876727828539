import {
    Email,
    EventData,
    HtmlEmail,
    HtmlNotificationEvent,
    IEventService,
    NotificationEvent,
    SimpleNotificationEvent,
} from '@price-for-profit/micro-services';

export interface IEmailService {
    sendSimpleEmail(
        { to, cc, bcc, subject, body }: SimpleNotificationEvent,
        correlationId?: string
    ): Promise<EventData>;
    sendHtmlEmail<T extends Record<string, unknown>>(
        { to, cc, bcc, subject, templateId, data }: HtmlNotificationEvent<T>,
        correlationId?: string
    ): Promise<EventData>;
}

export class EmailService implements IEmailService {
    constructor(
        private eventService: IEventService,
        private isConsoleLogOnly: boolean,
        private clientId: string,
        private appType: string
    ) {}

    private get actor() {
        return `${this.clientId}|${this.appType}`;
    }

    async sendSimpleEmail(
        { to, cc, bcc, subject, body }: SimpleNotificationEvent,
        correlationId?: string
    ): Promise<EventData> {
        const notificationEventWithFixedEmails = this.validateNotificationEvent({ to, cc, bcc, subject });

        const email: Email = {
            clientId: this.clientId,
            actor: this.actor,
            correlationId,
            body,
            ...notificationEventWithFixedEmails,
        };

        if (this.isConsoleLogOnly) {
            console.log('Email Event', email);
            return this.consoleLogOnlyResponse(correlationId);
        }
        const eventData = await this.eventService.sendSimpleEmail(email);

        return eventData;
    }

    async sendHtmlEmail<T extends Record<string, unknown>>(
        { to, cc, bcc, subject, templateId, data }: HtmlNotificationEvent<T>,
        correlationId?: string
    ): Promise<EventData> {
        const notificationEventWithFixedEmails = this.validateNotificationEvent({ to, cc, bcc, subject });

        const htmlEmail: HtmlEmail<T> = {
            clientId: this.clientId,
            actor: this.actor,
            correlationId,
            templateId,
            data,
            ...notificationEventWithFixedEmails,
        };

        if (this.isConsoleLogOnly) {
            console.log('Html Email Event', htmlEmail);
            return this.consoleLogOnlyResponse(correlationId);
        }

        const eventData = await this.eventService.sendHtmlEmail(htmlEmail);

        return eventData;
    }

    private validateNotificationEvent(notificationEvent: NotificationEvent) {
        if (!notificationEvent.subject) {
            throw new Error('Email subject is required');
        }

        const to = this.fixEmailList(notificationEvent.to);
        if (!to.length) {
            throw new Error('To email is required');
        }

        const ccWithoutTo = notificationEvent.cc && notificationEvent.cc.filter(cc => !to.includes(cc));
        const fixedCc = ccWithoutTo?.length ? this.fixEmailList(ccWithoutTo) : undefined;
        const cc = fixedCc?.length ? fixedCc : undefined;

        const bccWithoutToAndCc =
            notificationEvent.bcc && notificationEvent.bcc.filter(bcc => !to.includes(bcc) && !cc?.includes(bcc));
        const fixedBcc = bccWithoutToAndCc?.length ? this.fixEmailList(bccWithoutToAndCc) : undefined;
        const bcc = fixedBcc?.length ? fixedBcc : undefined;

        const validatedNotificationEvent = {
            ...notificationEvent,
            to,
            cc,
            bcc,
        };

        return validatedNotificationEvent;
    }

    private fixEmailList(emailList: string[]) {
        const emailSet = new Set(emailList);
        return Array.from(emailSet).filter(Boolean);
    }

    private consoleLogOnlyResponse(correlationId?: string): EventData {
        return { correlationId: correlationId || 'console-log-only' };
    }
}
