import { GridEditSingleSelectCell, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { DropdownErrorToolTip } from 'pages/productPrices/productPricesAddRecord/DropdownErrorToolTip';

export function CustomerPricesEditBusinessLineCell({ error, value, ...params }: GridRenderEditCellParams) {
    const apiRef = useGridApiContext();

    return (
        <DropdownErrorToolTip error={error}>
            <GridEditSingleSelectCell
                {...params}
                value={value || ''}
                onValueChange={(event, newValue) => {
                    if (newValue !== params.row.businessLine) {
                        apiRef.current.setEditCellValue({ id: params.id, field: 'material', value: null });
                        apiRef.current.setEditCellValue({ id: params.id, field: 'orgRegion', value: null });
                        apiRef.current.setEditCellValue({ id: params.id, field: 'application', value: null });
                        apiRef.current.setEditCellValue({ id: params.id, field: 'marketSegment', value: null });
                    }
                }}
            />
        </DropdownErrorToolTip>
    );
}
