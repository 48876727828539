import { useService } from '@insight2profit/drive-app';
import { Box } from '@mui/material';
import { isNil } from 'lodash';
import { IUomConversionService } from 'shared/services';
import { IViewProductPrices, KeyOfType } from 'shared/types';
import { formatMoney, formatPercent, formatPmi } from 'shared/utility';

interface ProductPriceDrawerModelDetailsProps {
    selectedProduct: IViewProductPrices | null;
}

export function ProductPriceDrawerModelDetails({ selectedProduct }: ProductPriceDrawerModelDetailsProps) {
    const { uomConversionService } = useService();
    const tableSx = {
        minWidth: '30vw',
    };

    const commonRowSx = {
        borderBottom: '2px solid',
        borderColor: 'grey.400',
        py: 0.5,
        px: 2,
    };

    const headerSx = {
        backgroundColor: 'grey.300',
        fontWeight: 'bold',
        ...commonRowSx,
    };

    const rowSx = {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 4,
        ...commonRowSx,
    };

    return (
        <Box display='flex' justifyContent={'center'} gap={6} pt={4}>
            <Box sx={tableSx}>
                <Box sx={headerSx}>Product & Market Trends</Box>
                <Box sx={rowSx}>
                    <Box>Last Month Variable Cost</Box>
                    <Box>{formatUom(uomConversionService, selectedProduct, 'currentCost')}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Variable cost</Box>
                    <Box>{formatUom(uomConversionService, selectedProduct, 'updatedCost')}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>VC Forecast Month 1 - 3</Box>
                    <Box>{formatUom(uomConversionService, selectedProduct, 'forecastedCost3Month')}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>VC Forecast Month 4 - 6</Box>
                    <Box>{formatUom(uomConversionService, selectedProduct, 'forecastedCost6Month')}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>VC Forecast Month 7 - 9</Box>
                    <Box>{formatUom(uomConversionService, selectedProduct, 'forecastedCost9Month')}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>90-Day CM %</Box>
                    <Box>{displayValue(selectedProduct?.cmPct90Day, formatPercent)}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Cost Change %</Box>
                    <Box>{displayValue(selectedProduct?.costChangePct, formatPercent)}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Price Change %</Box>
                    <Box>{displayValue(selectedProduct?.priceChangePct, formatPercent)}</Box>
                </Box>
            </Box>
            <Box sx={tableSx}>
                <Box sx={headerSx}>Other Infomation</Box>
                <Box sx={rowSx}>
                    <Box>Product Specialization</Box>
                    <Box>{selectedProduct?.productReachCategory}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Elasticity Category</Box>
                    <Box>{selectedProduct?.elasticityCategory}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Product Tier</Box>
                    <Box>{selectedProduct?.productTier}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>Ag & Food Composite Index</Box>
                    <Box>{displayValue(selectedProduct?.agFoodCompositeIndex, formatPercent)}</Box>
                </Box>
                <Box sx={rowSx}>
                    <Box>PMI – Purchasing Managers Index</Box>
                    <Box>{displayValue(selectedProduct?.pmi, formatPmi)}</Box>
                </Box>
            </Box>
        </Box>
    );
}

const displayValue = (
    value: number | undefined,
    formatter: (value: number) => string,
    emptyResult: string = 'Insufficient Data'
) => {
    if (value === null || value === undefined) return emptyResult;
    return formatter(value);
};

const formatUom = (
    uomConversionService: IUomConversionService,
    viewRow: IViewProductPrices | null,
    priceColumn: KeyOfType<IViewProductPrices, number | undefined>
) => {
    if (!viewRow || viewRow.uom === 'KAI') {
        return displayValue(viewRow?.[priceColumn], formatMoney);
    }
    const kaiPrice = viewRow[priceColumn];
    if (isNil(kaiPrice)) {
        return displayValue(kaiPrice, formatMoney);
    }

    const currentCostUom = !!viewRow.nonStandardUomConversionFactor
        ? uomConversionService.convertKaiToNonStandardUom({
              kaiPrice,
              kaiKgConversionFactor: viewRow.materialKaiKgConversionFactor,
              toNonStandardConversionFactor: viewRow.nonStandardUomConversionFactor,
          })
        : uomConversionService.convertKaiToUom({
              kaiPrice,
              materialKaiKgConversionFactor: viewRow.materialKaiKgConversionFactor,
              kgConversion: viewRow.kgConversion,
          });

    return displayValue(currentCostUom, formatMoney);
};
