import { KeyboardArrowDown } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Button, Chip, CircularProgress, FormControlLabel, Menu, Switch as MuiSwitch } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { gridToolbarItemStyle } from 'shared/constants/dataGrid';
import { useUserPermissions } from 'shared/hooks';
import { useProductPricesLocks } from 'shared/providers';
import { useProductPriceLocksMutation } from 'shared/queries/productPricesLocksQuery';
import { ProductPricesLockConfirmationModal } from './massActionModal/ProductPricesLockConfirmationModal';

const shortenedBusinessLineNames: { [key: string]: string } = {
    'AGRICULTURE AND FOOD': 'Ag and Food',
};

const defaultConfirmationInfo = { businessLineName: '', isLocked: false };

export function ProductPricesLocksToolbarButtons() {
    const [openConfirmation, setOpenConfirmation] = useState(defaultConfirmationInfo);
    const handleOpen = (confirmationInfo: typeof defaultConfirmationInfo) => setOpenConfirmation(confirmationInfo);
    const handleClose = () => setOpenConfirmation(defaultConfirmationInfo);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { data, isLoading, isBusinessLineLocked } = useProductPricesLocks();
    const {
        permittedRowLevels: {
            permitted: { businessLines: permittedBusinessLines },
        },
        data: { isGlobalRevisedTargetWriteAllowed, isGlobalRevisedMinimumWriteAllowed },
    } = useUserPermissions();
    const productPriceLocksMutation = useProductPriceLocksMutation({ onSuccess: handleClose });
    const isGlobalEditor = isGlobalRevisedTargetWriteAllowed || isGlobalRevisedMinimumWriteAllowed;

    const open = Boolean(anchorEl);
    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {!isGlobalEditor && <Box flexGrow={1} />}

            {permittedBusinessLines.length > 2 ? (
                <Box>
                    <Button
                        id='toggle-lock-buttons'
                        aria-controls={open ? 'toggle-lock-buttons' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        variant='contained'
                        disableElevation
                        onClick={handleClickMenu}
                        endIcon={<KeyboardArrowDown />}
                        sx={{ marginLeft: 3 }}
                    >
                        Toggle Lock Buttons
                    </Button>
                    <Menu
                        sx={{
                            '& .MuiMenu-paper': {
                                marginTop: '2px',
                                padding: '0 8px 5px 8px',
                                '& .MuiFormControlLabel-root': {
                                    display: 'block',
                                    margin: '5px 2px 2px',
                                },
                            },
                        }}
                        id='toggle-lock-buttons'
                        MenuListProps={{
                            'aria-labelledby': 'toggle-lock-buttons',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseMenu}
                    >
                        {permittedBusinessLines.map(businessLineName => {
                            const { isLocked, matchingLock } = isBusinessLineLocked(businessLineName);
                            const businessLineNameToDisplay =
                                shortenedBusinessLineNames[businessLineName] || businessLineName;
                            return (
                                <Box key={businessLineName}>
                                    <FormControlLabel
                                        sx={{
                                            ...gridToolbarItemStyle,
                                            mr: productPriceLocksMutation.isLoading ? 0 : 1.25,
                                            display: 'block',
                                        }}
                                        key={businessLineName}
                                        control={
                                            <MuiSwitch
                                                size='small'
                                                onChange={(event, checked) => {
                                                    handleOpen({ businessLineName, isLocked: checked });
                                                }}
                                                checked={isLocked}
                                            />
                                        }
                                        label={`LOCK ${businessLineNameToDisplay}`}
                                    />

                                    <ProductPricesLockConfirmationModal
                                        businessLineName={businessLineName}
                                        openConfirmation={openConfirmation}
                                        handleClose={handleClose}
                                        matchingLock={matchingLock}
                                        productPriceLocksMutation={productPriceLocksMutation}
                                    />
                                </Box>
                            );
                        })}
                    </Menu>
                </Box>
            ) : (
                permittedBusinessLines.map(businessLineName => {
                    const { isLocked, matchingLock } = isBusinessLineLocked(businessLineName);
                    const businessLineNameToDisplay = shortenedBusinessLineNames[businessLineName] || businessLineName;

                    if (!isGlobalEditor) {
                        const determineIcon = () => {
                            if (isLoading) return <CircularProgress size={15} />;
                            if (data?.every(lock => lock.isLocked)) return <LockIcon fontSize='small' />;
                            return <LockOpenIcon fontSize='small' />;
                        };

                        return (
                            <Box key={businessLineName} sx={{ ...gridToolbarItemStyle, marginY: 'auto' }}>
                                <Chip
                                    sx={{ p: 0.5 }}
                                    size='small'
                                    icon={determineIcon()}
                                    label={businessLineNameToDisplay}
                                    color='primary'
                                    variant={isLocked ? 'filled' : 'outlined'}
                                />
                            </Box>
                        );
                    }

                    return (
                        <Box key={businessLineName} sx={{ marginY: 'auto' }}>
                            <FormControlLabel
                                sx={{ ...gridToolbarItemStyle, mr: productPriceLocksMutation.isLoading ? 0 : 1.25 }}
                                control={
                                    <MuiSwitch
                                        size='small'
                                        onChange={(event, checked) => {
                                            handleOpen({ businessLineName, isLocked: checked });
                                        }}
                                        checked={isLocked}
                                    />
                                }
                                label={`LOCK ${businessLineNameToDisplay}`}
                            />
                            {productPriceLocksMutation.isLoading && <CircularProgress size={10} />}

                            <ProductPricesLockConfirmationModal
                                businessLineName={businessLineName}
                                openConfirmation={openConfirmation}
                                handleClose={handleClose}
                                matchingLock={matchingLock}
                                productPriceLocksMutation={productPriceLocksMutation}
                            />
                        </Box>
                    );
                })
            )}
        </>
    );
}
