import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { createContext, PropsWithChildren, ReactElement, useContext, useEffect, useState } from 'react';
import { usePrevious } from 'shared/hooks';
import { useExchangeRatesQuery } from 'shared/queries';
import { IViewExchangeRates } from 'shared/types';

export type NumberRenderCell = (params: GridRenderCellParams<number>) => ReactElement;

export type GenerateForeignCurrencyRenderCell = (isForeignCurrency: boolean) => NumberRenderCell;

export interface IExchangeRates {
    data?: IViewExchangeRates[];
    isLoading: boolean;
    isFetching: boolean;
    invalidateQuery: () => Promise<void>;
    error: string | null;
}

export interface ExchangeRatesContext {
    isForeignCurrency: boolean;
    setForeignCurrency: () => void;
    setUSD: () => void;
    exchangeRates: IExchangeRates;
}

export const exchangeRatesContext = createContext<ExchangeRatesContext>({
    isForeignCurrency: false,
    setForeignCurrency: () => {},
    setUSD: () => {},
    exchangeRates: {
        isLoading: false,
        isFetching: false,
        invalidateQuery: async () => {},
        error: null,
    },
});

export function ProvideExchangeRates({
    children,
    isForeignCurrencyDefault,
}: PropsWithChildren<{ isForeignCurrencyDefault: boolean }>) {
    const [isForeignCurrency, setIsForeignCurrency] = useState(isForeignCurrencyDefault);
    const previousIsForeignCurrencyDefault = usePrevious(isForeignCurrencyDefault);

    useEffect(() => {
        if (previousIsForeignCurrencyDefault !== isForeignCurrencyDefault)
            setIsForeignCurrency(isForeignCurrencyDefault);
    }, [isForeignCurrencyDefault, previousIsForeignCurrencyDefault]);

    const setUSD = () => setIsForeignCurrency(false);
    const setForeignCurrency = () => setIsForeignCurrency(true);

    const exchangeRates = useExchangeRatesQuery();

    return (
        <exchangeRatesContext.Provider value={{ exchangeRates, setUSD, setForeignCurrency, isForeignCurrency }}>
            {children}
        </exchangeRatesContext.Provider>
    );
}

export function useExchangeRates() {
    return useContext(exchangeRatesContext);
}

export const currencyCodeMapper = (orgRegion: string) => {
    switch (orgRegion?.toUpperCase()) {
        case 'PF EMEA':
        case 'PF EUROPE':
            return 'EUR';
        case 'PF MEA':
            return 'EUR';
        case 'PF INDIA':
            return 'INR';
        case 'PF NE ASIA':
            return 'CNY';
        case 'PF NORTH AMERICA':
            return 'USD';
        case 'PF SE ASIA':
            return 'USD';
        case 'PF SOUTH AMERICA':
            return 'BRL';
        case 'TS ASIA PACIFIC':
            return 'CNY';
        case 'TS EMEA':
            return 'EUR';
        case 'TS NORTH AMERICA':
            return 'USD';
        case 'TS SOUTH AMERICA':
            return 'BRL';
        default:
            return 'USD';
    }
};
