import { useService } from '@insight2profit/drive-app';
import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { DataGridPremium, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useI2pServerDataGrid } from '@price-for-profit/data-grid';
import { ProductPricesForeignCurrencyRenderCell } from 'shared/components/app/productPrices';
import { QUERY_KEYS, productPriceStatuses } from 'shared/constants';
import { HEADER_HEIGHT, SLIM_DATA_GRID_STYLE } from 'shared/constants/dataGrid';
import { useExchangeRates } from 'shared/providers';
import { IViewMarketPriceHistory, IViewProductPrices } from 'shared/types';
import { longDateValueFormatter, productPricesForeignCurrencyValueGetterCurried } from 'shared/utility';

type ProductPriceDrawerPriceChangeHistoryProps = {
    selectedProduct: IViewProductPrices | null;
};

export function ProductPriceDrawerPriceChangeHistory({ selectedProduct }: ProductPriceDrawerPriceChangeHistoryProps) {
    const { productPricesMarketPriceHistoryService } = useService();
    const { isForeignCurrency, exchangeRates } = useExchangeRates();
    const columns = [
        {
            headerName: 'Recommended Minimum Price',
            field: 'recommendedMinimum',
            valueGetter: productPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: (params: GridRenderCellParams) => {
                return <ProductPricesForeignCurrencyRenderCell {...params} />;
            },
            flex: 1,
        },
        {
            headerName: 'Recommended Target Price',
            field: 'recommendedTarget',
            valueGetter: productPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: (params: GridRenderCellParams) => {
                return <ProductPricesForeignCurrencyRenderCell {...params} />;
            },
            flex: 1,
        },
        {
            headerName: 'Current Regional Minimum Price',
            field: 'currentMinimum',
            valueGetter: productPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: (params: GridRenderCellParams) => {
                return <ProductPricesForeignCurrencyRenderCell {...params} />;
            },
            flex: 1,
        },
        {
            headerName: 'Current Regional Target Price',
            field: 'currentTarget',
            valueGetter: productPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: (params: GridRenderCellParams) => {
                return <ProductPricesForeignCurrencyRenderCell {...params} />;
            },
            flex: 1,
        },
        {
            headerName: 'Global Revised Minimum Price',
            field: 'globalRevisedMinimum',
            valueGetter: productPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: (params: GridRenderCellParams) => {
                return <ProductPricesForeignCurrencyRenderCell {...params} />;
            },
            flex: 1,
        },
        {
            headerName: 'Global Revised Target Price',
            field: 'globalRevisedTarget',
            valueGetter: productPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: (params: GridRenderCellParams) => {
                return <ProductPricesForeignCurrencyRenderCell {...params} />;
            },
            flex: 1,
        },
        {
            headerName: 'Regional Revised Minimum Price',
            field: 'regionalRevisedMinimum',
            valueGetter: productPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: (params: GridRenderCellParams) => {
                return <ProductPricesForeignCurrencyRenderCell {...params} />;
            },
            flex: 1,
        },
        {
            headerName: 'Regional Revised Target Price',
            field: 'regionalRevisedTarget',
            valueGetter: productPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
            renderCell: (params: GridRenderCellParams) => {
                return <ProductPricesForeignCurrencyRenderCell {...params} />;
            },
            flex: 1,
        },
        {
            headerName: 'Status',
            field: 'status',
            flex: 1,
        },
        {
            headerName: 'Edited by',
            field: 'modifiedBy',
            flex: 1,
        },
        {
            headerName: 'Edited Date',
            field: 'editedDate',
            valueFormatter: longDateValueFormatter,
            flex: 1.5,
        },
        {
            headerName: 'Approver',
            field: 'approver',
            flex: 1,
        },
    ];
    const { getDataGridProps } = useI2pServerDataGrid<IViewMarketPriceHistory>({
        columns: columns,
        name: `${QUERY_KEYS.productPricesMarketPriceHistory}.${selectedProduct?.materialId}.${selectedProduct?.orgRegion}.${selectedProduct?.application}`,
        queryOptions: {
            enabled: Boolean(selectedProduct?.materialId && selectedProduct?.orgRegion && selectedProduct?.application),
        },
        getData: async state => {
            return productPricesMarketPriceHistoryService.get({
                state,
                baseFilter: {
                    logicalOperator: 'and',
                    filters: [
                        {
                            property: 'materialId',
                            operator: 'eq',
                            value: selectedProduct?.materialId,
                        },
                        {
                            property: 'orgRegion',
                            operator: 'eq',
                            value: selectedProduct?.orgRegion,
                        },
                        {
                            property: 'application',
                            operator: 'eq',
                            value: selectedProduct?.application,
                        },
                        {
                            property: 'status',
                            operator: 'ne',
                            value: productPriceStatuses.NEEDS_REVIEW,
                        },
                    ],
                },
            });
        },

        initialState: {
            pageSize: 10,
            sortModel: [{ field: 'editedDate', sort: 'desc' }],
        },
        rowsPerPageOptions: [10, 20, 50, 100],
    });
    const dataGridProps = getDataGridProps();

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <DataGridPremium
                {...dataGridProps}
                sx={SLIM_DATA_GRID_STYLE}
                disableSelectionOnClick
                components={{
                    LoadingOverlay: LinearProgress,
                }}
                componentsProps={{
                    panel: {
                        sx: {
                            '& .MuiDataGrid-filterFormColumnInput': {
                                width: 'auto',
                            },
                        },
                    },
                }}
                disableVirtualization={false}
                headerHeight={HEADER_HEIGHT}
            />
        </Box>
    );
}
