import { useMemo } from 'react';
import { BUSINESS_LINE, PERMISSIONS } from 'shared/constants';
import { AppPermission } from 'shared/types';

export function useBusinessLines({ hasPermission }: { hasPermission: (appPermission: AppPermission) => boolean }) {
    const isAgAndFoodAllowed = useMemo(() => hasPermission(PERMISSIONS.businessLine.agAndFood), [hasPermission]);
    const isInorganicSpecialtiesAllowed = useMemo(() => hasPermission(PERMISSIONS.businessLine.inorganicSpecialties), [
        hasPermission,
    ]);
    const isPaintsAndCoatingsAllowed = useMemo(() => hasPermission(PERMISSIONS.businessLine.paintsAndCoatings), [
        hasPermission,
    ]);
    const isNaturalResourcesAllowed = useMemo(() => hasPermission(PERMISSIONS.businessLine.naturalResources), [
        hasPermission,
    ]);

    const permittedBusinessLines = useMemo(
        () =>
            [
                { name: BUSINESS_LINE.agAndFood, isAllowed: isAgAndFoodAllowed },
                { name: BUSINESS_LINE.inorganicSpecialties, isAllowed: isInorganicSpecialtiesAllowed },
                { name: BUSINESS_LINE.paintsAndCoatings, isAllowed: isPaintsAndCoatingsAllowed },
                { name: BUSINESS_LINE.naturalResources, isAllowed: isNaturalResourcesAllowed },
            ]
                .filter(businessLine => businessLine.isAllowed)
                .map(businessLine => businessLine.name),
        [isAgAndFoodAllowed, isInorganicSpecialtiesAllowed, isPaintsAndCoatingsAllowed, isNaturalResourcesAllowed]
    );

    return {
        permittedBusinessLines,
        isAgAndFoodAllowed,
        isInorganicSpecialtiesAllowed,
        isPaintsAndCoatingsAllowed,
        isNaturalResourcesAllowed,
    };
}
