import { DeleteForever } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import {
    GridActionsCellItem,
    GridEditDateCell,
    GridEditInputCell,
    GridRenderEditCellParams,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridValueSetterParams,
    getGridSingleSelectOperators,
} from '@mui/x-data-grid-premium';
import { CustomerPriceEditUOM } from 'pages/customerPrices/CustomerPriceEditUOM';
import { RemoveableGridColDef } from 'pages/productPrices/productPricesResolver';
import { useMemo } from 'react';
import { useUserPermissions } from 'shared/hooks';
import { useExchangeRates } from 'shared/providers';
import { useSelectedCustomerPrice } from 'shared/providers/provideSelectedCustomerPrice';
import { useUomDropdown } from 'shared/queries/uomDropdownQuery';
import { IViewCustomerPrices, IViewCustomerPricesExceptions } from 'shared/types';
import {
    PositiveNonZero,
    ValidFromPreProcessorWithMessage,
    ValidToPreProcessorWithMessage,
    customerPricesForeignCurrencyValueGetterCurried,
    customerPricesForeignCurrencyValueSetterCurried,
    modifiedByFormatter,
} from 'shared/utility';
import {
    DynamicEditableRenderCell,
    LONG_DATE_COLUMN,
    SHORT_DATE_COLUMN,
    ValidatedRenderEditCellWrapper,
} from '../../DataGridCells';
import { UnitOfMeasureCell } from '../../DataGridCells/UnitOfMeasureCell';
import { CustomerPricesForeignCurrencyRenderCell } from '../CustomerPricesForeignCurrencyRenderCell';
import { CustomerPricesExceptions } from './CustomerPricesExceptions';
import { CustomerPricesExceptionsApprovalActions } from './CustomerPricesExceptionsApprovalActions';
import { CustomerPricesExceptionsSubmitAction } from './CustomerPricesExceptionsSubmitAction';

type CustomerPricesExceptionsResolverProps = {
    customerPricesRow?: IViewCustomerPrices;
};

export function CustomerPricesExceptionsResolver({ customerPricesRow }: CustomerPricesExceptionsResolverProps) {
    const { close } = useSelectedCustomerPrice();
    if (!customerPricesRow) {
        close();
        return null;
    }
    return <CustomerPricesExceptions customerPricesRow={customerPricesRow} />;
}

export function useCustomerPricesExceptionsColumns(
    rowModesModel: GridRowModesModel,
    onCancelClick: (id: GridRowId) => () => void,
    customerPricesRow?: IViewCustomerPrices
) {
    const permissions = useUserPermissions();
    const { exchangeRates, isForeignCurrency } = useExchangeRates();
    const { uomValueOptions, isLoading: isLoadingUomValueOptions } = useUomDropdown();

    const columns: RemoveableGridColDef<IViewCustomerPricesExceptions>[] = useMemo(() => {
        const documentCurrencyOptions =
            exchangeRates?.data?.map(aCurrencyCode => {
                return aCurrencyCode.fromCurrencyCode;
            }) || [];

        return [
            {
                headerName: 'Exception Type',
                field: 'exceptionType',
                width: 200,
                editable: permissions.data.isCustomerExceptionsWriteAllowed,
                valueGetter: params => params.value || '',
                type: 'singleSelect',
                filterOperators: getGridSingleSelectOperators().filter(operator =>
                    ['is', 'not'].includes(operator.value)
                ),
                valueOptions: ['Sales Order'],
                renderCell: params => <DynamicEditableRenderCell {...params} />,
            },
            {
                headerName: 'Sales Order',
                field: 'salesOrder',
                type: 'number',
                width: 200,
                editable: permissions.data.isCustomerExceptionsWriteAllowed,
                valueSetter: (params: GridValueSetterParams<IViewCustomerPricesExceptions, any>) => {
                    return { ...params.row, salesOrder: params.value?.toString() };
                },
                renderEditCell: (params: GridRenderEditCellParams<number>) => (
                    <ValidatedRenderEditCellWrapper error={params.error} zIndexOverride={10000}>
                        <GridEditInputCell {...params} disabled={!params.row.exceptionType} />
                    </ValidatedRenderEditCellWrapper>
                ),
                preProcessEditCellProps: params => {
                    if (!params.props?.value) {
                        return { ...params.props, error: 'Sales Order is required' };
                    }
                    if (!/^\d+$/.test(params.props.value)) {
                        return { ...params.props, error: 'Sales Order must contain only numbers' };
                    }
                    return { ...params.props, error: false };
                },
                renderCell: params => <DynamicEditableRenderCell {...params} />,
            },
            {
                headerName: 'Current Price',
                field: 'currentPrice',
                width: 200,
                type: 'number',
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params => <CustomerPricesForeignCurrencyRenderCell {...params} />,
                headerClassName: 'color-header-full-height green-header',
            },
            {
                headerName: 'Recommended Price',
                field: 'recommendedPriceTimesPerQuantity',
                width: 200,
                type: 'number',
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                renderCell: params => <CustomerPricesForeignCurrencyRenderCell {...params} />,
                headerClassName: 'color-header-full-height light-blue-header',
            },
            {
                headerName: 'Revised Price',
                field: 'revisedPrice',
                width: 200,
                type: 'number',
                preProcessEditCellProps: PositiveNonZero,
                valueGetter: customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates }),
                valueSetter: customerPricesForeignCurrencyValueSetterCurried({
                    isForeignCurrency,
                    exchangeRates,
                    priceColumnName: 'revisedPrice',
                }),
                renderCell: params => <CustomerPricesForeignCurrencyRenderCell {...params} />,
                renderEditCell: (params: GridRenderEditCellParams<number>) => (
                    <ValidatedRenderEditCellWrapper error={params.error} zIndexOverride={10000}>
                        <GridEditInputCell {...params} />
                    </ValidatedRenderEditCellWrapper>
                ),
                headerClassName: 'color-header-full-height light-blue-header',
                editable: permissions.data.isCustomerExceptionsWriteAllowed,
            },
            {
                headerName: 'Document Currency',
                field: 'documentCurrency',
                type: 'singleSelect',
                filterOperators: getGridSingleSelectOperators().filter(operator =>
                    ['is', 'not'].includes(operator.value)
                ),
                hide: false,
                width: 200,
                editable: permissions.data.isCustomerExceptionsWriteAllowed,
                headerClassName: 'color-header-full-height light-blue-header',
                valueOptions: documentCurrencyOptions,
                valueGetter: ({ value }) => value || 'USD',
                renderCell: params => <DynamicEditableRenderCell {...params} />,
            },
            {
                headerName: 'Per Quantity',
                field: 'perQuantity',
                headerAlign: 'center',
                type: 'number',
                headerClassName: 'color-header-full-height light-blue-header',
                editable: false,
            },
            {
                headerName: 'UOM',
                field: 'uom',
                type: 'singleSelect',
                filterOperators: getGridSingleSelectOperators().filter(operator =>
                    ['is', 'not'].includes(operator.value)
                ),
                width: 200,
                editable: permissions.data.isCustomerExceptionsWriteAllowed,
                headerClassName: 'color-header-full-height light-blue-header',
                valueOptions: uomValueOptions,
                renderEditCell: (params: GridRenderEditCellParams<string>) => {
                    if (isForeignCurrency) return <UnitOfMeasureCell {...params} />;
                    if (isLoadingUomValueOptions)
                        return (
                            <Box display='flex' justifyContent={'space-between'} alignItems='center' width='100%' p={2}>
                                <Box>{params.value}</Box>
                                <CircularProgress size={20} />
                            </Box>
                        );
                    return <CustomerPriceEditUOM {...params} />;
                },
                renderCell: params => <DynamicEditableRenderCell {...params} />,
            },
            {
                headerName: 'Valid From',
                field: 'validFrom',
                width: 200,
                ...SHORT_DATE_COLUMN,
                headerClassName: 'color-header-full-height blue-header',
                editable: permissions.data.isCustomerExceptionsWriteAllowed,
                cellClassName: 'editable-cell',
                preProcessEditCellProps: ValidFromPreProcessorWithMessage,
                renderEditCell: (params: GridRenderEditCellParams) => (
                    <ValidatedRenderEditCellWrapper error={params.error} zIndexOverride={10000}>
                        <GridEditDateCell {...params} />
                    </ValidatedRenderEditCellWrapper>
                ),
            },
            {
                headerName: 'Valid To',
                field: 'validTo',
                width: 200,
                ...SHORT_DATE_COLUMN,
                headerClassName: 'color-header-full-height blue-header',
                editable: permissions.data.isCustomerExceptionsWriteAllowed,
                cellClassName: 'editable-cell',
                preProcessEditCellProps: ValidToPreProcessorWithMessage,
                renderEditCell: (params: GridRenderEditCellParams) => (
                    <ValidatedRenderEditCellWrapper error={params.error} zIndexOverride={10000}>
                        <GridEditDateCell {...params} disablePast />,
                    </ValidatedRenderEditCellWrapper>
                ),
            },
            {
                headerName: 'Submit Price',
                field: 'action-submit',
                type: 'actions',
                hide: false,
                filterable: false,
                pinnable: false,
                width: 125,
                getActions: (params: GridRowParams<IViewCustomerPricesExceptions>): JSX.Element[] => {
                    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

                    return !isInEditMode
                        ? [
                              <CustomerPricesExceptionsSubmitAction
                                  key={`submit-button-${params.row.id}`}
                                  params={params}
                                  customerPricesRow={customerPricesRow}
                              />,
                          ]
                        : [];
                },
            },
            {
                headerName: 'Status',
                field: 'status',
                width: 200,
                headerClassName: 'color-header-full-height blue-header',
            },
            {
                headerName: 'Approver',
                field: 'approver',
                width: 200,
                headerClassName: 'color-header-full-height blue-header',
            },
            {
                headerName: 'Approve',
                field: 'approval-actions',
                type: 'actions',
                hide: false,
                filterable: false,
                pinnable: false,
                width: 125,
                getActions: (params: GridRowParams<IViewCustomerPricesExceptions>): JSX.Element[] => {
                    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

                    return CustomerPricesExceptionsApprovalActions({ params, isInEditMode, customerPricesRow });
                },
                customFieldIsReadAllowed: permissions.userApproverTier >= 1,
            },
            {
                headerName: 'Last Edited By',
                field: 'modifiedBy',
                width: 200,
                valueFormatter: modifiedByFormatter,
                headerClassName: 'color-header-full-height blue-header',
            },
            {
                headerName: 'Last Edited Date',
                field: 'effectiveStart',
                width: 200,
                ...LONG_DATE_COLUMN,
                headerClassName: 'color-header-full-height blue-header',
            },
            {
                field: 'add-row-actions',
                type: 'actions',
                headerName: 'Actions',
                width: 200,
                getActions: (params: GridRowParams<IViewCustomerPricesExceptions>): JSX.Element[] => {
                    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

                    return isInEditMode
                        ? [
                              <GridActionsCellItem
                                  key={'delete'}
                                  icon={<DeleteForever />}
                                  label='Cancel'
                                  className='textPrimary'
                                  onClick={onCancelClick(params.id)}
                                  color='inherit'
                              />,
                          ]
                        : [];
                },
            },
        ];
    }, [
        permissions,
        exchangeRates,
        rowModesModel,
        onCancelClick,
        isForeignCurrency,
        uomValueOptions,
        isLoadingUomValueOptions,
        customerPricesRow,
    ]);

    const filteredColumns = columns.filter(column => column.customFieldIsReadAllowed !== false);

    return filteredColumns;
}
