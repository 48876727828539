import { useQuery } from '@tanstack/react-query';
import { useAnalyticsPrerequisites } from '../providers';
import { AnalyticsReport } from '../types/analytics';

const TWENTY_NINE_MINUTES = 1000 * 60 * 29;

export function useAnalyticsReportsQuery() {
    const { options, analyticsReportService } = useAnalyticsPrerequisites();

    return useQuery<AnalyticsReport[], Error>({
        queryKey: ['Insight Analytics Reports'],
        queryFn: async () => {
            const authorizedReports = await analyticsReportService.getAuthorizedReportsForUser();
            const reportPromises = authorizedReports.map(async report =>
                analyticsReportService.getReportToken(report.reportId)
            );
            const reports = await Promise.all(reportPromises);

            return reports
                .map(report => ({
                    id: report.embedReports[0].id,
                    embedUrl: report.embedReports[0].embedUrl,
                    accessToken: report.embedToken.token,
                    reportName: report.embedReports[0].reportName,
                }))
                .sort((a, b) => a.reportName.localeCompare(b.reportName));
        },
        onError: error => {
            options?.snackbar?.enqueueSnackbar?.(error.message, { variant: 'error' });
        },
        keepPreviousData: true,
        staleTime: TWENTY_NINE_MINUTES,
    });
}
