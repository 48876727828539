export const HEADER_HEIGHT = 80;

export const DATA_GRID_STYLE = {
    border: 'none',
    '.MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        textOverflow: 'clip',
        whiteSpace: 'break-spaces',
        lineHeight: 1.67,
    },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell--editing': {
        border: '2px solid #0d0d0d',
    },
    '& .editable-cell': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#d5edf7',
        width: '100%',
        height: '95%',
        padding: '0.5rem',
    },
    '& .green-header': {
        backgroundColor: '#e2efda',
    },
    '& .light-blue-header': {
        backgroundColor: '#d9e1f2',
    },
    '& .blue-header': {
        backgroundColor: '#b4c6e7',
    },
    '& .gray-header': {
        backgroundColor: '#ddd',
    },
    '& .color-header-full-height': {
        height: `${HEADER_HEIGHT}px`,
    },
    '& .Mui-error': {
        border: '3px solid #ca3939',
        height: '100%',
    },
};

export const SLIM_DATA_GRID_STYLE = {
    flexGrow: 1,
    border: 'none',
    '.MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        textOverflow: 'clip',
        whiteSpace: 'break-spaces',
        lineHeight: 1,
    },
    '& .MuiDataGrid-columnHeaders': {
        height: '50px',
        minHeight: '50px',
    },
};

export const gridToolbarItemStyle = {
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    color: 'primary.main',
    marginRight: 0,
    marginLeft: 0,
    paddingRight: 0,
    paddingLeft: 0,
    px: 0.5,

    '& .MuiFormControlLabel-label': {
        fontSize: '13px',
    },
};
