import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { SelectOption } from 'shared/types';

type MuiSelectProps = {
    options: SelectOption[];
    width?: string;
    label: string;
    value: string;
    onChange: ((event: SelectChangeEvent<string>, child: React.ReactNode) => void) | undefined;
    size?: 'small' | 'medium' | undefined;
    isDisabled?: boolean;
};

export function MuiSelect({ options, width, label, value, onChange, size, isDisabled = false }: MuiSelectProps) {
    return (
        <FormControl sx={{ width: width }} size={size}>
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                value={value}
                onChange={onChange}
                disabled={options.length <= 1 || isDisabled}
                IconComponent={options.length === 1 ? () => null : undefined}
            >
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.displayText}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
