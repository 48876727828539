import { CircularProgress, FormControl, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';
import { SelectOption } from 'shared/types';

type FormlessSelectProps<T extends FieldValues> = {
    label: string;
    options: SelectOption[];
    isLoading?: boolean;
    fullWidth?: boolean;
    width?: string;
    field?: ControllerRenderProps<T, Path<T>>;
    isDisabled?: boolean;
};

export function FormlessSelect<T extends FieldValues>({
    label,
    options,
    isLoading = false,
    fullWidth = false,
    width = 'auto',
    field,
    isDisabled = false,
}: FormlessSelectProps<T>) {
    return (
        <FormControl sx={{ width: fullWidth ? null : width }} fullWidth={fullWidth}>
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                {...field}
                endAdornment={
                    isLoading && (
                        <InputAdornment position='end' sx={{ marginRight: '1.5rem' }}>
                            <CircularProgress size={20} />
                        </InputAdornment>
                    )
                }
                disabled={isLoading || isDisabled}
            >
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.displayText}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
