import LaunchIcon from '@mui/icons-material/Launch';
import { Box } from '@mui/system';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { useSelectedCustomerPrice } from 'shared/providers/provideSelectedCustomerPrice';
import { IViewCustomerPrices } from 'shared/types';
import { TooltipIconButton } from '../../productPrices';

type CustomerProductExceptionsActionsProps = {
    params: GridRowParams<IViewCustomerPrices>;
};

export function CustomerPricesExceptionsActions({ params }: CustomerProductExceptionsActionsProps) {
    const { openExceptionsModal } = useSelectedCustomerPrice();

    return [
        <Box key={`exceptions-dialog-${params.row.id}`}>
            <TooltipIconButton
                tooltip='Exceptions'
                onClick={() => openExceptionsModal(params.row)}
                Icon={LaunchIcon}
                color='primary.main'
                isDisabled={false}
            />
        </Box>,
    ];
}
