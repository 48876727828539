import { CLIENT_ID } from '@insight2profit/drive-app';
import { DataAccessPaginatedResponse, DataAccessResponse, IDataAccessService } from '@price-for-profit/micro-services';
import { DATABASE_LABEL, TABLE_BATCH_META_DATA, VIEW_CUSTOMER_PRODUCT_ENGINE_BATCH_META_DATA } from 'shared/constants';
import { ITableBatchMetaData, IViewCustomerProductEngineBatchMetaData } from 'shared/types';

export interface IBatchMetaDataService {
    getCustomerProductEngineBatchMetaData(): Promise<
        DataAccessPaginatedResponse<IViewCustomerProductEngineBatchMetaData>
    >;
    addStartStatus({
        businessLine,
        userDisplayName,
        batchType,
    }: {
        businessLine: string;
        userDisplayName: string;
        batchType: string;
    }): Promise<DataAccessResponse<ITableBatchMetaData>>;
    updateCorrelationId({
        batchMetaDataId,
        correlationId,
    }: {
        batchMetaDataId: number;
        correlationId: string;
    }): Promise<DataAccessResponse<ITableBatchMetaData>>;
}

export class BatchMetaDataService implements IBatchMetaDataService {
    constructor(private dasService: IDataAccessService) {}

    private STATUSES = {
        started: 1,
        completed: 2,
        error: 3,
    };

    async getCustomerProductEngineBatchMetaData(): Promise<
        DataAccessPaginatedResponse<IViewCustomerProductEngineBatchMetaData>
    > {
        return await this.dasService.getCollection<IViewCustomerProductEngineBatchMetaData, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: VIEW_CUSTOMER_PRODUCT_ENGINE_BATCH_META_DATA,
            page: 0,
            pageSize: 100,
        });
    }

    async addStartStatus({
        businessLine,
        userDisplayName,
        batchType,
    }: {
        businessLine: string;
        userDisplayName: string;
        batchType: string;
    }) {
        if (!businessLine) throw new Error('Business Line is required');
        if (!userDisplayName) throw new Error(`User's Display Name is required`);

        const payload: ITableBatchMetaData = {
            batchMetaDataId: 0,
            batchType,
            businessLine,
            eventCorrelationId: undefined,
            batchCorrelationId: undefined,
            batchMetaDataStatusId: this.STATUSES.started,
            modifiedBy: userDisplayName,
            effectiveStart: new Date().toISOString(),
            effectiveEnd: undefined,
            deleted: false,
        };

        return await this.dasService.addRow<ITableBatchMetaData, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_BATCH_META_DATA,
            payload,
        });
    }

    async updateCorrelationId({ batchMetaDataId, correlationId }: { batchMetaDataId: number; correlationId: string }) {
        if (!batchMetaDataId) throw new Error('Batch Meta Data Id is required');
        if (!correlationId) throw new Error('Correlation Id is required');

        return await this.dasService.patchRow<ITableBatchMetaData, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_BATCH_META_DATA,
            key: batchMetaDataId.toString(),
            operations: [
                {
                    op: 'replace',
                    path: '/eventCorrelationId',
                    value: correlationId,
                },
            ],
        });
    }
}
