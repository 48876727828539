import { useService } from '@insight2profit/drive-app';
import { DataAccessResponse } from '@price-for-profit/micro-services';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { ITableProductPricesComments } from 'shared/types';

interface useProductPriceCommentsMutationProps {
    onSuccess: () => void;
}

export function useProductPriceCommentsMutation({ onSuccess }: useProductPriceCommentsMutationProps) {
    const { productPricesCommentsService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const mutation = useMutation<DataAccessResponse<ITableProductPricesComments>, Error, ITableProductPricesComments>({
        mutationKey: ['addProductPriceComment'],
        mutationFn: async newRow => {
            return await productPricesCommentsService.addComment(newRow);
        },
        onSuccess: () => {
            enqueueSnackbar('Comment submitted', { variant: 'success' });
            onSuccess();
        },
        onError: error => {
            enqueueSnackbar('There was an error submitting the comment', { variant: 'error' });
        },
    });
    return mutation;
}
