import { RegisterAppUpdate, useIsDemoMode, useTitle } from '@insight2profit/drive-app';
import { CssBaseline } from '@mui/material';
import { AppRoutes } from 'routing';
import { useTrackLogin } from 'shared/hooks/use-track-login';

export default function App() {
    const isDemoMode = useIsDemoMode();
    useTitle(isDemoMode ? 'PMT' : 'Nouryon PMT');
    useTrackLogin();
    return (
        <>
            <CssBaseline />
            <RegisterAppUpdate />
            <AppRoutes />
        </>
    );
}
