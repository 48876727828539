import { CLIENT_ID } from '@insight2profit/drive-app';
import { IDataAccessService, IFilter, getData } from '@price-for-profit/micro-services';
import { DATABASE_LABEL } from 'shared/constants';
import { VwUsers } from 'shared/types';

export const getUserEmails = async ({
    dasService,
    collectionFilter,
}: {
    dasService: IDataAccessService;
    collectionFilter?: IFilter<VwUsers> | undefined;
}): Promise<Pick<VwUsers, 'displayName' | 'email' | 'isI2pUser'>[]> => {
    return (await getData((page: number) =>
        dasService.getCollection<VwUsers, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: 'vwUsers',
            // das supports projections, but micro-services does not
            // @ts-ignore
            projections: ['displayName', 'email', 'isI2pUser'],
            collectionFilter,
            page,
        })
    )) as Pick<VwUsers, 'displayName' | 'email' | 'isI2pUser'>[];
};

export const getRoleFilter = (approver: string): IFilter<VwUsers> => {
    return approver === 'Product Line Management'
        ? {
              logicalOperator: 'or',
              filters: [
                  {
                      property: 'isRoleProductLineManager',
                      operator: 'eq',
                      value: `1`,
                  },
                  {
                      property: 'isTier4Approver',
                      operator: 'eq',
                      value: `1`,
                  },
              ],
          }
        : {
              property: 'approverRoles',
              operator: 'contains',
              value: `|${approver}|`,
          };
};

export const filterEmailsForEnvironment = (
    env: string,
    users: Pick<VwUsers, 'displayName' | 'email' | 'isI2pUser'>[]
): string[] => {
    const remainingCCUsers = env === 'prod' ? users.filter(ccUser => !ccUser.isI2pUser) : users;
    const remainingCCEmails = remainingCCUsers.map(ccUser => ccUser.email).filter(Boolean) as string[];

    return remainingCCEmails;
};

export const getBccByEnvironment = (env: string): string[] | undefined => {
    return env === 'prod' ? ['jrothschild@insight2profit.com', 'ppandit@insight2profit.com'] : undefined;
};
