import { useService, useUser } from '@insight2profit/drive-app';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useUserPermissions } from 'shared/hooks';
import { useProductPricesSummary } from 'shared/providers';

export type ProductPricesSubmitMassActionMutationParams = {
    state: ServerSideState;
};

export type UseProductPricesSubmitMassActionMutationProps = {
    state: ServerSideState;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

export function useProductPricesSubmitMassActionMutation({
    state,
    onSuccess,
    onError,
}: UseProductPricesSubmitMassActionMutationProps) {
    const { productPricesService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { invalidateQuery: invalidateSummaryQuery } = useProductPricesSummary();
    const { permittedRowLevels, userApproverTier } = useUserPermissions();
    const user = useUser();

    const mutation = useMutation<void, Error, ProductPricesSubmitMassActionMutationParams>({
        mutationKey: ['productPricesSubmitMassAction'],
        mutationFn: async ({ state }) => {
            return await productPricesService.submitMassAction({
                state,
                permittedRowLevels,
                userApproverTier,
                modifiedBy: user?.displayName,
            });
        },
        onSuccess: () => {
            enqueueSnackbar('Mass action successfully completed', { variant: 'success' });
            onSuccess?.();
            invalidateSummaryQuery();
        },
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            onError?.(error);
        },
    });

    const onSubmit = () => {
        mutation.mutate({
            state,
        });
    };

    return { onSubmit, isLoading: mutation.isLoading };
}
