import { useService } from '@insight2profit/drive-app';
import { QUERY_KEYS } from 'shared/constants';
import { useI2PQuery } from 'shared/hooks/use-i2p-query';
import { IViewExchangeRates } from 'shared/types';

const errorMessage = 'Failed to load exchange rates';

export function useExchangeRatesQuery() {
    const { exchangeRatesService } = useService();

    const { data, isLoading, isFetching, invalidateQuery } = useI2PQuery<IViewExchangeRates>({
        queryName: QUERY_KEYS.exchangeRates,
        queryFn: async () => exchangeRatesService.getExchangeRates(),
        errorMessage,
    });

    const isValid = data && data.length >= 1;

    return {
        data,
        isLoading,
        isFetching,
        invalidateQuery,
        error: isValid ? null : errorMessage,
    };
}
