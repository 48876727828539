import { Divider } from '@mui/material';
import { Box } from '@mui/system';

interface CustomerPriceDrawerHeaderProps {
    shipToCustomer: string;
    material: string;
    application: string;
    orgRegion: string;
    close: () => void;
}

export function CustomerPriceDrawerHeader({
    shipToCustomer,
    material,
    application,
    orgRegion,
    close,
}: CustomerPriceDrawerHeaderProps) {
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                <Box
                    width='50px'
                    sx={{ background: 'lightgray', borderRadius: '3px', height: '10px', cursor: 'pointer' }}
                    onClick={() => close()}
                />
            </Box>
            <CustomerPriceDrawerTitle
                shipToCustomer={shipToCustomer}
                material={material}
                application={application}
                orgRegion={orgRegion}
            />
            <Divider variant='middle' />
        </>
    );
}

export function CustomerPriceDrawerTitle({
    shipToCustomer,
    material,
    application,
    orgRegion,
}: {
    shipToCustomer: string;
    material: string;
    application: string;
    orgRegion: string;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                background:
                    'linear-gradient(0deg, rgba(0, 68, 137, 0.04), rgba(0, 68, 137, 0.04)), linear-gradient(0deg, rgba(255, 255, 255, 0.002), rgba(255, 255, 255, 0.002))',
                height: '50px',
                alignItems: 'center',
            }}
        >
            <strong> Material: &nbsp;</strong>
            <span>
                {shipToCustomer} | {material} | {application} | {orgRegion}
            </span>
        </Box>
    );
}
