import { Breadcrumbs, Paper, Stack, Typography } from '@mui/material';
import { pageName as CUSTOMER_PRICES_PAGE_NAME } from 'pages/customerPrices/customerPricesResolver';
import { pageName as PRODUCT_PRICES_PAGE_NAME } from 'pages/productPrices/productPricesResolver';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'routing';
import { useUserPermissions } from 'shared/hooks';

export function PageLayout({ children, pageName }: PropsWithChildren<{ pageName: string }>) {
    const {
        page: { isViewProductPricesPageAllowed, isViewCustomerPricesPageAllowed },
    } = useUserPermissions();

    if (
        (!isViewProductPricesPageAllowed && pageName === PRODUCT_PRICES_PAGE_NAME) ||
        (!isViewCustomerPricesPageAllowed && pageName === CUSTOMER_PRICES_PAGE_NAME)
    ) {
        return <Navigate to={ROUTES.moduleRedirect} replace />;
    }

    return (
        <Stack gap={2} p={4}>
            <Breadcrumbs aria-label='breadcrumb'>
                <Typography color='text.primary' variant='h6'>
                    {pageName}
                </Typography>
            </Breadcrumbs>
            <Paper sx={{ padding: '0.5rem' }}>{children}</Paper>
        </Stack>
    );
}
