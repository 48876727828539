import { CLIENT_ID } from '@insight2profit/drive-app';
import { EventData, IEventService } from '@price-for-profit/micro-services';
import { IBatchMetaDataService } from './batchMetaDataService';

export interface IBatchService {
    triggerCustomerProductEngine({
        businessLine,
        user,
    }: {
        businessLine: string;
        user: app.UserInfo;
    }): Promise<EventData>;
}

export class BatchService implements IBatchService {
    constructor(private batchMetaDataService: IBatchMetaDataService, private eventService: IEventService) {}

    BATCH_TYPES = {
        customerProductEngine: 'CustomerProductEngine',
    };

    async triggerCustomerProductEngine({
        businessLine,
        user,
    }: {
        businessLine: string;
        user: app.UserInfo;
    }): Promise<EventData> {
        const inProgressCPEBatches = await this.batchMetaDataService.getCustomerProductEngineBatchMetaData();
        const businessLineBatchInProgress = inProgressCPEBatches?.data?.find(
            batch =>
                batch.businessLine === businessLine &&
                batch.status === 'Started' &&
                batch.batchType === this.BATCH_TYPES.customerProductEngine
        );

        if (businessLineBatchInProgress) throw new Error('A batch is already in progress. Please try again later.');

        const metaDataResponse = await this.batchMetaDataService.addStartStatus({
            businessLine,
            userDisplayName: user?.displayName,
            batchType: this.BATCH_TYPES.customerProductEngine,
        });

        const adfPipelineResponse = await this.eventService.triggerADFPipeline({
            clientId: CLIENT_ID,
            subject: 'Trigger Batch - CustomerProductEngine',
            actor: user?.id,
            eventType: 'application.event.triggered',
            eventBody: {
                businessLine,
                batchMetaDataId: metaDataResponse.data.batchMetaDataId,
                userId: user?.id,
                userDisplayName: user?.displayName,
                userEmail: user?.email,
            },
        });

        await this.batchMetaDataService.updateCorrelationId({
            batchMetaDataId: metaDataResponse.data.batchMetaDataId,
            correlationId: adfPipelineResponse.correlationId,
        });

        return adfPipelineResponse;
    }
}
