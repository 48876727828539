import { SxProps, TextField, Theme } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { useController } from 'react-hook-form';

type FormDateFieldProps = {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
    label: string;
    prefix?: string;
    suffix?: string;
    rules?: Object;
    readOnly?: boolean;
    defaultValue?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    placeholder?: string;
    sx?: SxProps<Theme>;
};

export function FormDateField({
    name,
    label,
    rules,
    fullWidth = true,
    readOnly = false,
    defaultValue = '',
    disabled,
    placeholder,
    sx,
    ...rest
}: FormDateFieldProps & Partial<DatePickerProps<any, Date>>) {
    const {
        field: { ref, ...inputProps },
        fieldState: { error },
    } = useController({
        name,
        rules,
        defaultValue,
    });

    return (
        <DatePicker
            {...inputProps}
            inputRef={ref}
            autoFocus
            disabled={disabled}
            renderInput={params => (
                <TextField
                    {...params}
                    variant='outlined'
                    fullWidth={fullWidth}
                    label={`${label}${rules && Object.keys(rules).includes('required') ? ' *' : ''}`}
                    error={Boolean(error)}
                    helperText={error?.message}
                    disabled={disabled}
                    placeholder={placeholder}
                    sx={sx}
                />
            )}
            {...rest}
        />
    );
}
