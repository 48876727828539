import { CLIENT_ID, createHash } from '@insight2profit/drive-app';
import { GridValidRowModel } from '@mui/x-data-grid-premium';
import { IDataAccessService } from '@price-for-profit/micro-services';
import { DATABASE_LABEL, QUERY_NAME_PRODUCT_PRICES_UPDATE_NEW, customerPriceStatuses } from 'shared/constants';
import { IAddNewProductPrices, ITableProductPrices, ITableProductPricesPersistedAttributes } from 'shared/types';
import { ITableCrudService } from './generic/tableCrudService';

export interface IPricesAddNewService<T extends { id: string }, R extends GridValidRowModel> {
    addNewRecords(rowsToBeAdded: T[], userDisplayName: string): Promise<PromiseSettledResult<R>[]>;
}
export class ProductPricesAddNewService implements IPricesAddNewService<IAddNewProductPrices, ITableProductPrices> {
    constructor(
        private dasService: IDataAccessService,
        private productPricesTableCrudService: ITableCrudService<ITableProductPrices>,
        private productPricesPersistedAttributesTableCrudService: ITableCrudService<ITableProductPricesPersistedAttributes>
    ) {}
    async addNewRecords(rowsToBeAdded: IAddNewProductPrices[], userDisplayName: string) {
        const date = new Date();
        const effectiveStartString = date.toISOString();

        const response = rowsToBeAdded.map(async aNewRow => {
            const materialString =
                typeof aNewRow.material === 'string' ? aNewRow.material.split('|')[1] : aNewRow.material?.material;
            const materialIdString =
                typeof aNewRow.material === 'string' ? aNewRow.material.split('|')[0] : aNewRow.material?.materialId;

            if (
                !materialString ||
                !materialIdString ||
                !aNewRow.businessLine ||
                !aNewRow.orgRegion ||
                !aNewRow.application
            )
                return Promise.reject();

            const newProductPriceTableRow: ITableProductPrices = {
                massActionId: 0,
                newRecordId: createHash(aNewRow.id),
                businessLine: aNewRow.businessLine,
                material: materialString,
                materialId: materialIdString,
                orgRegion: aNewRow.orgRegion,
                application: aNewRow.application,
                marketSegment: aNewRow.marketSegment ?? undefined,
                status: customerPriceStatuses.NEEDS_REVIEW,
                globalRevisedMinimum: 0,
                globalRevisedTarget: 0,
                regionalRevisedMinimum: 0,
                regionalRevisedTarget: 0,
                modifiedBy: userDisplayName,
                effectiveStart: effectiveStartString,
                deleted: false,
                dataSource: 'User added SAP material',
            };
            const newProductPriceRowWithId = await this.productPricesTableCrudService.addRow(
                userDisplayName,
                newProductPriceTableRow
            );

            await this.productPricesPersistedAttributesTableCrudService.addRow(userDisplayName, {
                businessLine: newProductPriceRowWithId.data.businessLine,
                materialId: newProductPriceRowWithId.data.materialId,
                orgRegion: newProductPriceRowWithId.data.orgRegion,
                application: newProductPriceRowWithId.data.application,
                uom: 'KG',
            });

            await this.dasService.executeNamedQuery({
                clientId: CLIENT_ID,
                databaseLabel: DATABASE_LABEL,
                parameters: { newRecordId: newProductPriceRowWithId.data.newRecordId },
                queryName: QUERY_NAME_PRODUCT_PRICES_UPDATE_NEW,
            });

            return newProductPriceRowWithId.data;
        });
        const data = Promise.allSettled(response);
        return data;
    }
}
