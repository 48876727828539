import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useSelectedCustomerPrice } from 'shared/providers/provideSelectedCustomerPrice';
import { useCustomerPricesQuoteQuery, useCompanyNamesDropdown, useCompanyAddressesDropdown } from 'shared/queries';
import { IViewCustomerPrices } from 'shared/types';
import { CustomerPricesQuoteForm } from './CustomerPricesQuoteForm';
type CustomerPricesQuoteModalProps = {
    state: ServerSideState;
    rows: IViewCustomerPrices[];
};

export function CustomerPricesQuoteModal({ state }: CustomerPricesQuoteModalProps) {
    const { close, isQuoteModalOpen } = useSelectedCustomerPrice();
    const { data: allRows, isLoading } = useCustomerPricesQuoteQuery({
        state,
        enabled: isQuoteModalOpen,
    });

    const { data: companyNames, isFetching: isFetchingCompanyNames } = useCompanyNamesDropdown();

    const { data: companyAddresses, isFetching: isFetchingCompanyAddresses } = useCompanyAddressesDropdown();

    return (
        <Dialog onClose={close} open={isQuoteModalOpen} maxWidth={'lg'} fullWidth>
            <DialogTitle>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <span>Quote:</span>
                </Box>
            </DialogTitle>
            <DialogContent>
                <CustomerPricesQuoteForm
                    key={JSON.stringify(state)}
                    isFetchingCompanyAddresses={isFetchingCompanyAddresses}
                    companyAddresses={companyAddresses}
                    isFetchingCompanyNames={isFetchingCompanyNames}
                    companyNames={companyNames}
                    allRows={allRows}
                    isLoading={isLoading}
                />
            </DialogContent>
            <DialogActions>
                <Box display='flex' justifyContent='end' gap={2} pt={4}>
                    <Button onClick={close}>Close</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
