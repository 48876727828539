import { GridRenderCellParams } from '@mui/x-data-grid-premium';

export const CustomerPricesCurrentSAPPriceStaticRenderCell = (params: GridRenderCellParams) => {
    const currencyCode = params.row?.SAPDocumentCurrency ?? 'USD';
    const valueToFormat = params?.value ?? 0;

    const formattedValue = new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency: currencyCode,
    }).format(valueToFormat);
    const editableCell = params.colDef.editable && params.isEditable ? 'editable-cell' : '';

    return <div className={editableCell}>{formattedValue}</div>;
};
