import { useService } from '@insight2profit/drive-app';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

export function useCustomerBankDetails() {
    const { enqueueSnackbar } = useSnackbar();
    const { customerPricesQuoteService } = useService();

    const { data, isLoading, isFetching } = useQuery({
        queryKey: ['customerBankDetails'],
        queryFn: async () => customerPricesQuoteService.getBankDetails(),
        onError: () => {
            enqueueSnackbar('Failed to load quote products');
        },
    });

    return {
        data,
        isLoading,
        isFetching,
    } as const;
}
