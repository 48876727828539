import { MASS_ACTION_TYPES } from 'shared/constants';
import { useUserPermissions } from '../use-user-permissions';

const priceChangeMassActions = [
    MASS_ACTION_TYPES.AMOUNT,
    MASS_ACTION_TYPES.CHANGE_DOLLAR,
    MASS_ACTION_TYPES.CHANGE_PERCENT,
] as string[];

const amtChangeMassActions = [
    MASS_ACTION_TYPES.AMOUNT,
    MASS_ACTION_TYPES.CHANGE_DOLLAR,
    MASS_ACTION_TYPES.CHANGE_PERCENT,
] as string[];

const scaleChangeMassActions = [MASS_ACTION_TYPES.AMOUNT] as string[];

const validityDatesChangeMassActions = [MASS_ACTION_TYPES.VALIDITY_DATES] as string[];
const uomChangeMassActions = [MASS_ACTION_TYPES.UOM] as string[];

export const isPriceChangeMassActionCheck = (selectedType: string) => priceChangeMassActions.includes(selectedType);
export const isScaleChangeMassActionCheck = (selectedType: string) => scaleChangeMassActions.includes(selectedType);
export const isAmtChangeMassActionCheck = (selectedType: string) => amtChangeMassActions.includes(selectedType);
export const isValidityDatesChangeMassActionCheck = (selectedType: string) =>
    validityDatesChangeMassActions.includes(selectedType);
export const isUomChangeMassActionCheck = (selectedType: string) => uomChangeMassActions.includes(selectedType);

export function useCustomerPricesMassActionField(selectedType: string) {
    const permissions = useUserPermissions();

    const isPriceChangeMassAction = isPriceChangeMassActionCheck(selectedType);
    const isScaleChangeMassAction = isScaleChangeMassActionCheck(selectedType);
    const isAmtChangeMassAction = isAmtChangeMassActionCheck(selectedType);
    const isValidityDatesChangeMassAction = isValidityDatesChangeMassActionCheck(selectedType);

    const fieldOptions = [
        {
            value: 'revisedPrice',
            displayText: 'Revised Price',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isPriceChangeMassAction,
        },
        {
            value: 'scaleQty01',
            displayText: 'Scale 1',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isScaleChangeMassAction,
        },
        {
            value: 'amount01',
            displayText: 'Amount 1',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isAmtChangeMassAction,
        },
        {
            value: 'scaleQty02',
            displayText: 'Scale 2',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isScaleChangeMassAction,
        },
        {
            value: 'amount02',
            displayText: 'Amount 2',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isAmtChangeMassAction,
        },
        {
            value: 'scaleQty03',
            displayText: 'Scale 3',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isScaleChangeMassAction,
        },
        {
            value: 'amount03',
            displayText: 'Amount 3',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isAmtChangeMassAction,
        },
        {
            value: 'scaleQty04',
            displayText: 'Scale 4',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isScaleChangeMassAction,
        },
        {
            value: 'amount04',
            displayText: 'Amount 4',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isAmtChangeMassAction,
        },
        {
            value: 'scaleQty05',
            displayText: 'Scale 5',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isScaleChangeMassAction,
        },
        {
            value: 'amount05',
            displayText: 'Amount 5',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isAmtChangeMassAction,
        },
        {
            value: 'scaleQty06',
            displayText: 'Scale 6',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isScaleChangeMassAction,
        },
        {
            value: 'amount06',
            displayText: 'Amount 6',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isAmtChangeMassAction,
        },
        {
            value: 'validFrom',
            displayText: 'Valid From',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isValidityDatesChangeMassAction,
        },
        {
            value: 'validTo',
            displayText: 'Valid To',
            isAllowed: permissions.data.isRevisedCustomerPriceWriteAllowed && isValidityDatesChangeMassAction,
        },
    ];

    const allowedFieldOptions = fieldOptions.filter(action => action.isAllowed);
    const defaultSelectedField = allowedFieldOptions?.[0]?.value || '';

    return {
        allowedFieldOptions,
        defaultSelectedField,
    };
}
