import { useService, useUser } from '@insight2profit/drive-app';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { MASS_ACTION_TYPES } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { useExchangeRates } from 'shared/providers';
import { ICustomerPricesMassActionForm, ICustomerPricesMassActionParameters } from 'shared/types';

export type CustomerPricesMassActionMutationParams = {
    state: ServerSideState;
    parameters: ICustomerPricesMassActionParameters;
};

export type UseCustomerPricesMassActionMutationProps = {
    state: ServerSideState;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

export function useCustomerPricesMassActionMutation({
    state,
    onSuccess,
    onError,
}: UseCustomerPricesMassActionMutationProps) {
    const { customerPricesService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    // const { invalidateQuery: invalidateSummaryQuery } = useCustomerPricesSummary();
    const { customerPricesPermittedRowLevels, userApproverTier } = useUserPermissions();
    const user = useUser();

    const { isForeignCurrency } = useExchangeRates();

    const mutation = useMutation<void, Error, CustomerPricesMassActionMutationParams>({
        mutationKey: ['customerPricesMassAction'],
        mutationFn: async ({ state, parameters }) => {
            return await customerPricesService.massAction({
                state,
                customerPricesPermittedRowLevels,
                parameters: { ...parameters, IsForeignCurrency: isForeignCurrency },
                userApproverTier,
            });
        },
        onSuccess: () => {
            enqueueSnackbar('Mass action successfully completed', { variant: 'success' });
            onSuccess?.();
            // invalidateSummaryQuery();
        },
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            onError?.(error);
        },
    });

    const onSubmit = (data: ICustomerPricesMassActionForm) => {
        mutation.mutate({
            state,
            parameters: { ...customerPricesFormToParameterMapper(data), ModifiedByEmail: user?.email || '' },
        });
    };

    return { onSubmit, isLoading: mutation.isLoading };
}

export const customerPricesFormToParameterMapper = (
    form: ICustomerPricesMassActionForm
): ICustomerPricesMassActionParameters => {
    const dateNoTimeUnits =
        form.type === MASS_ACTION_TYPES.VALIDITY_DATES ? new Date(form.value).toLocaleDateString('en-US') : undefined;

    const uom = form.type === MASS_ACTION_TYPES.UOM ? form.value : undefined;

    const value =
        !form.value || ([MASS_ACTION_TYPES.VALIDITY_DATES, MASS_ACTION_TYPES.UOM] as string[]).includes(form.type)
            ? undefined
            : Number(form.value);

    return {
        Type: form.type as ICustomerPricesMassActionParameters['Type'],
        Field: form.field,
        Value: value,
        Date: dateNoTimeUnits,
        Uom: uom,
        ModifiedBy: form.modifiedBy,
    } as ICustomerPricesMassActionParameters;
};
