import { CircularProgress } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useProductPricesOrgRegionDropdown } from 'shared/queries/productPricesDropdownQueries';
import { ProductPricesAddNewAutocomplete } from './ProductPricesAddNewAutocomplete';

export function ProductPricesEditOrgRegionAutocomplete(params: GridRenderEditCellParams) {
    const selectedBusinessLine = params?.row?.businessLine;
    const { data, isLoading } = useProductPricesOrgRegionDropdown({
        selectedBusinessLine,
    });
    const apiRef = useGridApiContext();

    if (!selectedBusinessLine) return <div></div>;

    if (isLoading) return <CircularProgress size={20} />;

    return (
        <ProductPricesAddNewAutocomplete
            isLoading={isLoading}
            setEditCellValue={apiRef.current.setEditCellValue}
            rowId={params.row.id}
            field={params.field}
            value={params.value}
            options={data}
            error={params.error}
            placeholder='Org Region'
        />
    );
}
