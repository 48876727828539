import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { GridToolbarLayoutsButton, ServerSideState } from '@price-for-profit/data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserPermissions } from 'shared/hooks';
import { useProductPricesLocks } from 'shared/providers';
import { GridToolbarCustomerProductEngineBatchProcessAction } from './GridToolbarCustomerProductEngineBatchProcessAction';
import { ProductPricesLocksToolbarButtons } from './ProductPricesLocksToolbarToggles';
import { ProductPricesToggleCurrency } from './ProductPricesToggleCurrency';
import { ProductPricesMassActionModal } from './massActionModal/ProductPricesMassActionModal';

type ProductPricesCustomToolbarProps = {
    state: ServerSideState;
    invalidate: () => void;
    rowCount: number;
    regionalCurrencyLocked?: boolean;
};

export function ProductPricesCustomToolbar({
    state,
    invalidate,
    rowCount,
    regionalCurrencyLocked,
}: ProductPricesCustomToolbarProps) {
    const {
        permittedRowLevels: {
            permitted: { businessLines: permittedBusinessLines },
        },
        data: {
            isGlobalRevisedMinimumWriteAllowed,
            isGlobalRevisedTargetWriteAllowed,
            isRegionalRevisedMinimumWriteAllowed,
            isRegionalRevisedTargetWriteAllowed,
            isAddProductPriceAllowed,
        },
    } = useUserPermissions();
    const { isBusinessLineLocked } = useProductPricesLocks();

    const determineAddRecordDisabled = () => {
        if (permittedBusinessLines.length === 1) {
            const { isLocked } = isBusinessLineLocked(permittedBusinessLines[0]);
            return isLocked;
        }
        if (permittedBusinessLines.length > 1) {
            const unlockedBusinessLines = permittedBusinessLines.filter(aBusinessLine => {
                const data = isBusinessLineLocked(aBusinessLine);
                return !data.isLocked;
            });
            if (unlockedBusinessLines.length >= 1) return false;
            else return true;
        }
        return true;
    };

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const isMassActionAllowed =
        isGlobalRevisedMinimumWriteAllowed ||
        isGlobalRevisedTargetWriteAllowed ||
        isRegionalRevisedMinimumWriteAllowed ||
        isRegionalRevisedTargetWriteAllowed;

    const handleClick = () => {
        navigate(pathname.concat('/add-record'));
    };

    return (
        <Box display='flex' flexDirection='column' justifyContent='center'>
            <Box display='flex'>
                <ProductPricesToggleCurrency label={'Regional Currency'} isLocked={regionalCurrencyLocked} />
                <ProductPricesLocksToolbarButtons />
                <GridToolbarCustomerProductEngineBatchProcessAction />
            </Box>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarLayoutsButton localStorageName='nouryon-pmt-product' />
                {isMassActionAllowed && (
                    <ProductPricesMassActionModal state={state} invalidate={invalidate} rowCount={rowCount} />
                )}
                {isAddProductPriceAllowed && (
                    <Button
                        color='primary'
                        startIcon={<AddIcon />}
                        size='small'
                        onClick={handleClick}
                        disabled={determineAddRecordDisabled()}
                    >
                        Add record
                    </Button>
                )}
            </GridToolbarContainer>
        </Box>
    );
}
