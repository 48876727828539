import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { useProductPricesLocks } from 'shared/providers';
import { longDateValueFormatter, percentValueFormatter, shortDateValueFormatter } from 'shared/utility';

export const PERCENT_COLUMN = {
    type: 'number',
    valueFormatter: percentValueFormatter,
};

export const SHORT_DATE_COLUMN = {
    type: 'date',
    valueFormatter: shortDateValueFormatter,
};

export const LONG_DATE_COLUMN = {
    type: 'date',
    valueFormatter: longDateValueFormatter,
};

export function DynamicEditableRenderCell<T extends GridValidRowModel>(params: GridRenderCellParams<any, T, any>) {
    const { isBusinessLineLocked } = useProductPricesLocks();
    const globalFields = [
        'globalRevisedMinimum',
        'globalRevisedTarget',
        'globalRevisedMinimumCMPercent',
        'globalRevisedTargetCMPercent',
    ];
    if (isBusinessLineLocked(params.row.businessLine).isLocked && globalFields.includes(params.field)) {
        return (
            <div>
                {params.colDef.valueFormatter
                    ? params.colDef.valueFormatter({ ...params, value: params.value })
                    : params.value}
            </div>
        );
    }
    return (
        <div className={params.colDef.editable && params.isEditable ? 'editable-cell' : undefined}>
            {params.colDef.valueFormatter
                ? params.colDef.valueFormatter({ ...params, value: params.value })
                : params.value}
        </div>
    );
}
