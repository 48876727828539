import { FormControlLabel } from '@mui/material';
import MuiSwitch from '@mui/material/Switch';
import { gridToolbarItemStyle } from 'shared/constants/dataGrid';
import { useExchangeRates } from 'shared/providers';

export function ProductPricesToggleCurrency({
    label,
    mutationLoading,
    isLocked,
}: {
    label: string;
    mutationLoading?: boolean;
    isLocked?: boolean;
}) {
    const { setForeignCurrency, setUSD, isForeignCurrency } = useExchangeRates();

    return (
        <FormControlLabel
            sx={gridToolbarItemStyle}
            control={
                <MuiSwitch
                    id='documentCurrency'
                    size='small'
                    onChange={(event, checked) => (checked ? setForeignCurrency() : setUSD())}
                    checked={isForeignCurrency}
                    disabled={mutationLoading || isLocked}
                />
            }
            label={label}
        />
    );
}
