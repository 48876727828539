import { useService, useUser } from '@insight2profit/drive-app';
import { DataAccessResponse } from '@price-for-profit/micro-services';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERY_KEYS } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { useI2PQuery } from 'shared/hooks/use-i2p-query';
import { useProductPricesLocks } from 'shared/providers';
import { ProductPricesLocksSoftEditParams } from 'shared/services';
import { ITableProductPricesLocks, IViewProductPricesLocks } from 'shared/types';

const errorMessage = 'Failed to load product prices Locks';

export function useProductPricesLocksQuery() {
    const { productPricesLocksService } = useService();
    const {
        permittedRowLevels: {
            permitted: { businessLines },
        },
    } = useUserPermissions();

    const { data, isLoading, isFetching, invalidateQuery } = useI2PQuery<IViewProductPricesLocks>({
        queryName: QUERY_KEYS.productPricesLocks,
        queryFn: async () => productPricesLocksService.get(businessLines),
        errorMessage,
    });

    return {
        data,
        isLoading,
        isFetching,
        invalidateQuery,
    };
}

export function useProductPriceLocksMutation({ onSuccess }: { onSuccess?: () => void }) {
    const { productPricesLocksService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const user = useUser();
    const { invalidateQuery } = useProductPricesLocks();

    const mutation = useMutation<
        {
            editResponse: DataAccessResponse<ITableProductPricesLocks>;
            addResponse: DataAccessResponse<ITableProductPricesLocks>;
        },
        Error,
        Omit<ProductPricesLocksSoftEditParams, 'userDisplayName'>
    >({
        mutationKey: [QUERY_KEYS.productPricesLocksEdit],
        mutationFn: async ({ isLocked, oldViewRow }) => {
            return await productPricesLocksService.softEdit({
                isLocked,
                userDisplayName: user?.displayName || '',
                oldViewRow,
            });
        },
        onSuccess: data => {
            enqueueSnackbar('Lock status changed', { variant: 'success' });
            invalidateQuery();
            onSuccess?.();
        },
        onError: error => {
            enqueueSnackbar('Changing lock status failed', { variant: 'error' });
        },
    });
    return mutation;
}
