import { CLIENT_ID } from '@insight2profit/drive-app';
import { GridValidRowModel } from '@mui/x-data-grid-premium';
import {
    DataAccessQueryResponse,
    DataAccessResponse,
    IDataAccessService,
    Operation,
    ValueOf,
} from '@price-for-profit/micro-services';
import { DATABASE_LABEL } from 'shared/constants';

const ARCHIVE_ACTIONS = {
    edited: 'EDITED',
    deleted: 'DELETED',
} as const;

type ArchiveAction = ValueOf<typeof ARCHIVE_ACTIONS>;

type ArchiveMetaFields = {
    archivedDate: string;
    archivedAction?: ArchiveAction;
};

type Archive<Table> = Table &
    ArchiveMetaFields & {
        archiveId: number;
        archivedBy: string;
        archivedAction: ArchiveAction;
    };

export interface ITableCrudService<Table extends GridValidRowModel> {
    getById(primaryKey: number | string, isArchive?: boolean): Promise<DataAccessQueryResponse<Table>>;
    addRow(
        userDisplayName: string,
        tableRow: Omit<Table, 'modifiedBy' | 'effectiveStart' | 'deleted'> & {
            modifiedBy?: string;
            effectiveStart?: string;
            deleted?: boolean;
        }
    ): Promise<DataAccessResponse<Table>>;
    softDelete(
        userDisplayName: string,
        primaryKey: number | string
    ): Promise<{
        archiveResult: DataAccessResponse<Table>;
        deleteOriginalRowResult: DataAccessResponse<Table>;
    }>;
    softEdit(
        userDisplayName: string,
        newRow: Partial<Table>,
        primaryKey: number | string
    ): Promise<{
        archiveResult: DataAccessResponse<Table>;
        editOriginalRowResult: DataAccessResponse<Table>;
    }>;
    softEditViaTermOriginal(
        userDisplayName: string,
        newRow: Partial<Table>,
        primaryKey: number
    ): Promise<{
        editOriginalRowResult: DataAccessResponse<Table>;
        addRowResult: DataAccessResponse<Table>;
    }>;
}

export class TableCrudService<Table extends GridValidRowModel> implements ITableCrudService<Table> {
    constructor(protected dasService: IDataAccessService, private tableId: string) {}
    private userDisplayName: string = '';

    async getById(primaryKey: number | string, isArchive: boolean = false) {
        return this.dasService.getSingle<Table, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: isArchive ? `${this.tableId}Archives` : this.tableId,
            key: primaryKey.toString(),
        });
    }

    async addRow(
        userDisplayName: string,
        tableRow: Table & { modifiedBy?: string; effectiveStart?: string; deleted?: boolean }
    ) {
        this.setUserDisplayName(userDisplayName);
        const now = new Date().toISOString();
        const modifiedTableRow = {
            ...tableRow,
            modifiedBy: this.userDisplayName,
            effectiveStart: now,
            deleted: false,
        };
        return this.add(modifiedTableRow);
    }

    async softDelete(userDisplayName: string, primaryKey: number) {
        this.setUserDisplayName(userDisplayName);
        const getOriginalRowResult = await this.getById(primaryKey);
        const now = new Date().toISOString();
        const archiveResult = await this.addDeleteArchive(getOriginalRowResult.data, { archivedDate: now });
        const deleteOriginalRowResult = await this.delete(primaryKey);
        return {
            archiveResult,
            deleteOriginalRowResult,
        };
    }

    async softEdit(userDisplayName: string, newRow: Partial<Table>, primaryKey: number) {
        this.setUserDisplayName(userDisplayName);
        const { data: originalRow } = await this.getById(primaryKey);
        const now = new Date().toISOString();
        const archiveResult = await this.addEditArchive(originalRow, { archivedDate: now });
        const editedRow = { ...originalRow, ...newRow };
        const editOriginalRowResult = await this.updateAfterArchive(editedRow, now);

        return {
            archiveResult,
            editOriginalRowResult,
        };
    }

    async softEditViaTermOriginal(userDisplayName: string, newRow: Partial<Table>, primaryKey: number) {
        this.setUserDisplayName(userDisplayName);
        const { data: originalRow } = await this.getById(primaryKey);
        const now = new Date().toISOString();
        const editOriginalRowResult = await this.patch(primaryKey, [
            { op: 'replace', path: '/effectiveEnd', value: now },
        ]);
        const editedRow = { ...originalRow, ...newRow };
        const addRowResult = await this.updateAfterArchive(editedRow, now);

        return {
            editOriginalRowResult,
            addRowResult,
        };
    }

    private setUserDisplayName(userDisplayName: string) {
        if (this.userDisplayName !== userDisplayName) this.userDisplayName = userDisplayName;
    }

    private async updateAfterArchive(newRow: Table, effectiveStart: string) {
        const modifiedNewRow = {
            ...newRow,
            effectiveStart,
            modifiedBy: this.userDisplayName,
            effectiveEnd: undefined,
            deleted: false,
        };
        return await this.update(modifiedNewRow);
    }

    private async addDeleteArchive(newRow: Table, archiveMetaFields: ArchiveMetaFields) {
        const archivedAction = archiveMetaFields.archivedAction ?? ARCHIVE_ACTIONS.deleted;
        return await this.addArchive(newRow, archiveMetaFields.archivedDate, archivedAction);
    }

    private async addEditArchive(newRow: Table, archiveMetaFields: ArchiveMetaFields) {
        const archivedAction = archiveMetaFields.archivedAction ?? ARCHIVE_ACTIONS.edited;
        return await this.addArchive(newRow, archiveMetaFields.archivedDate, archivedAction);
    }

    private async addArchive(newRow: Table, archivedDate: string, archivedAction: ArchiveAction) {
        const newArchiveRow: Archive<Table> = {
            ...newRow,
            archiveId: 0,
            archivedBy: this.userDisplayName,
            archivedDate,
            archivedAction,
        };
        return await this.add(newArchiveRow, true);
    }

    private validateUserDisplayName() {
        if (!this.userDisplayName) throw Error('User Display Name is missing.');
    }

    private async add(tableRow: Table, isArchive: boolean = false) {
        this.validateUserDisplayName();

        return this.dasService.addRow<Table, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: isArchive ? `${this.tableId}Archives` : this.tableId,
            payload: tableRow,
        });
    }

    private async update(existingTableRow: Table, isArchive: boolean = false) {
        this.validateUserDisplayName();

        return this.dasService.updateRow<Table, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: isArchive ? `${this.tableId}Archives` : this.tableId,
            payload: existingTableRow,
        });
    }

    private async delete(primaryKey: number | string, isArchive: boolean = false) {
        this.validateUserDisplayName();

        return this.dasService.deleteRow<Table, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: isArchive ? `${this.tableId}Archives` : this.tableId,
            key: primaryKey.toString(),
        });
    }

    private async patch(primaryKey: number | string, operations: Operation[]) {
        this.validateUserDisplayName();

        return this.dasService.patchRow<Table, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: this.tableId,
            key: primaryKey.toString(),
            operations: operations,
        });
    }
}
