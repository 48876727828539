import { GetDropdownParams } from 'shared/services/generic/dropdownService';
import { ITableNonStandardUOMConversion } from 'shared/types';

export function getNonStandardUomDropdownParams(materialId: string): GetDropdownParams<ITableNonStandardUOMConversion> {
    return {
        sortBy: 'targetUnit',
        collectionFilter: {
            logicalOperator: 'and',
            filters: [
                {
                    property: 'materialId',
                    operator: 'eq',
                    value: materialId,
                },
            ],
        },
    };
}
