import '@fontsource/roboto';
import {
    AuthorityUrlObj,
    getAppConfig,
    getAuthorityUrlInfo,
    getIsDemoMode,
    MUI_LICENSE_KEY,
    ProvideAuth,
    ProvideErrorBoundary,
    ProvideQueryClient,
    ProvideService,
    ProvideSnackbar,
    ProvideTheme,
} from '@insight2profit/drive-app';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import { getBrowserAuthClients } from '@price-for-profit/micro-services';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AppConfig } from 'shared/types';
import { createServiceContext } from 'startup';
import App from './App';
import './index.css';

const run = async () => {
    const baseUrl = process.env.PUBLIC_URL;
    const appConfig = await getAppConfig<AppConfig>(baseUrl);

    const authorityUrlObjs: AuthorityUrlObj[] = [
        {
            authorityUrl: appConfig.authorityUrl,
            name: getIsDemoMode({ serviceBaseUrl: appConfig.serviceBaseUrl }) ? 'Client' : 'Nouryon',
        },
        { authorityUrl: appConfig.i2pAuthorityUrl, name: 'Insight2Profit' },
    ];
    const { authorityUrl, name } = getAuthorityUrlInfo(authorityUrlObjs);

    const clientScopes = {
        auth: appConfig.authorizationAppId,
        ces: appConfig.calculationAppId,
        event: appConfig.eventAppId,
        export: appConfig.exportAppId,
        das: appConfig.dataAccessAppId,
        pbi: appConfig.powerBiAppId,
    } as const;

    LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

    const { httpClient, clientApp } = await getBrowserAuthClients({
        clientId: appConfig.appId,
        authDomainHost: appConfig.authDomainHost,
        authorityUrl,
        cacheLocation: 'sessionStorage',
        serviceBaseUrl: appConfig.serviceBaseUrl,
        redirectUrl: baseUrl,
        clientScopes,
    });
    const serviceContext = createServiceContext(appConfig, httpClient, name);

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(
        <React.StrictMode>
            <BrowserRouter basename={baseUrl}>
                <ProvideTheme>
                    <ProvideSnackbar>
                        <ProvideErrorBoundary>
                            <ProvideAuth clientApp={clientApp}>
                                <ProvideService context={serviceContext}>
                                    <ProvideQueryClient>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <App />
                                        </LocalizationProvider>
                                    </ProvideQueryClient>
                                </ProvideService>
                            </ProvideAuth>
                        </ProvideErrorBoundary>
                    </ProvideSnackbar>
                </ProvideTheme>
            </BrowserRouter>
        </React.StrictMode>
    );
};

run().catch(console.error);
