import { TextField } from '@mui/material';
import { Control, FieldError, FieldValues, Path, RegisterOptions, useController } from 'react-hook-form';

export type FormTextFieldProps<T extends FieldValues> = {
    label: string;
    name: Path<T>;
    control: Control<T>;
    componentProps?: {
        type?: string;
        startAdornment?: JSX.Element;
        endAdornment?: JSX.Element;
    };
    rules?: Omit<RegisterOptions<T>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    required?: boolean;
    fieldError?: FieldError;
    isDisabled?: boolean;
};

export function FormTextField<T extends FieldValues>({
    label,
    name,
    control,
    componentProps = {},
    rules = {},
    fieldError,
    isDisabled = false,
}: FormTextFieldProps<T>) {
    const { field } = useController({
        name,
        control,
        rules,
    });

    return (
        <TextField
            sx={{ width: 1 }}
            {...field}
            type={componentProps?.type}
            error={!!fieldError}
            label={label + (!!rules.required ? ' *' : '')}
            helperText={fieldError?.message ? (label || 'This') + ' ' + fieldError?.message : null}
            InputProps={{
                startAdornment: componentProps?.startAdornment,
                endAdornment: componentProps?.endAdornment,
            }}
            disabled={isDisabled}
        />
    );
}
