import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { COMMENTS_TAB, CUSTOMER_PRICE_HISTORY, MODEL_DETAILS_TAB, PRICING_ANALYTICS_TAB } from 'shared/constants';

export function CustomerPriceDrawerTabs({
    selectedTab,
    onTabChange,
}: {
    selectedTab: string;
    onTabChange: (event: React.SyntheticEvent<Element, Event>, tab: string) => void;
}) {
    return (
        <Box>
            <Tabs
                value={selectedTab}
                onChange={onTabChange}
                textColor='secondary'
                indicatorColor='secondary'
                aria-label='customer details'
                variant='fullWidth'
            >
                <Tab sx={{ flexGrow: 1 }} value={MODEL_DETAILS_TAB} label='Model Details' />
                <Tab sx={{ flexGrow: 1 }} value={PRICING_ANALYTICS_TAB} label='Pricing Analytics' />
                <Tab sx={{ flexGrow: 1 }} value={COMMENTS_TAB} label='Comments' />
                <Tab sx={{ flexGrow: 1 }} value={CUSTOMER_PRICE_HISTORY} label='Price Change History' />
            </Tabs>
        </Box>
    );
}
