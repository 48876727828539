import { DEMO_ENV } from '../constants';
import { useService } from '../providers';
import { getInsightEnvironment } from './use-insight-environment';

interface GetIsDemoModeParams {
    serviceBaseUrl: string;
}

export function getIsDemoMode({ serviceBaseUrl }: GetIsDemoModeParams): boolean {
    const env = getInsightEnvironment({ serviceBaseUrl });

    if (!DEMO_ENV) return false;

    return env === DEMO_ENV;
}

export function useIsDemoMode() {
    const {
        appConfig: { serviceBaseUrl },
    } = useService();
    const env = getInsightEnvironment({ serviceBaseUrl });

    if (!DEMO_ENV) return false;

    return env === DEMO_ENV;
}
