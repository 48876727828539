import { useService } from '@insight2profit/drive-app';
import { Delete } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import {
    DataGridPremium,
    GridActionsCellItem,
    GridEnrichedColDef,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    getGridSingleSelectOperators,
} from '@mui/x-data-grid-premium';
import { useI2pDataGridEdit } from '@price-for-profit/data-grid';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { ROUTES } from 'routing';
import { AddRecordCopyButton } from 'shared/components/app/AddRecordCopyButton';
import { AddRecordToolbar } from 'shared/components/app/AddRecordToolbar';
import { DATA_GRID_STYLE, HEADER_HEIGHT } from 'shared/constants/dataGrid';
import { useUserPermissions } from 'shared/hooks';
import { useBusinessLineDropdown } from 'shared/queries/productPricesDropdownQueries';
import { IAddNewProductPrices, getProductPriceMaterialId } from 'shared/types';
import { distinctObjectArray } from 'shared/utility';
import { ProductPricesApplicationAutocomplete } from './ProductPricesApplicationAutocomplete2';
import { ProductPricesEditOrgRegionAutocomplete } from './ProductPricesEditOrgRegionAutocomplete';
import { ProductPricesMarketSegmentAutocomplete } from './ProductPricesMarketSegmentAutocomplete';
import { ProductPricesEditBusinessLineCell } from './productPricesEditBusinessLineCell';
import { ProductPricesMaterialAutocomplete } from './productPricesMaterialAutocomplete';
import { useNewProductPricesReducer } from './useNewProductPricesReducer';

export function ProductPricesAddRecord() {
    const [rows, dispatchRows] = useNewProductPricesReducer();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [isAddNewRow, setIsAddNewRow] = useState<string | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { data: businessLineData, isLoading } = useBusinessLineDropdown();
    const { productPricesAddNewService } = useService();
    const {
        marketRegionType: { isMarketRegionTypeInclusive },
    } = useUserPermissions();

    const distinctRows = distinctObjectArray(
        rows.map(aRow => {
            return {
                ...aRow,
                id: '',
                material: getProductPriceMaterialId(aRow.material ?? ''),
            };
        })
    );
    const containsDuplicateRows = distinctRows.length !== rows.length;

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleAddRecord = () => {
        const newProductPriceId = `new-${getNewId()}`;
        setIsAddNewRow(newProductPriceId);
        setRowModesModel(oldModel => ({
            [newProductPriceId]: { mode: GridRowModes.Edit, fieldToFocus: 'businessLine' },
        }));
    };

    const handleCopyRecord = (copiedRowId: string) => {
        const copiedProductNewRowId = `new-${getNewId()}`;
        dispatchRows({ type: 'copy', newRowId: copiedProductNewRowId, copiedRowId });
        setIsAddNewRow(null);
        setRowModesModel(oldModel => ({
            [copiedProductNewRowId]: { mode: GridRowModes.Edit, fieldToFocus: 'businessLine' },
        }));
    };

    const columns: GridEnrichedColDef<IAddNewProductPrices>[] = [
        {
            headerName: 'ID',
            field: 'dataGridId',
            hide: true,
            headerAlign: 'center',
            align: 'center',
            description: 'Unique ID identifier for each specific row in the application',
        },
        {
            headerName: 'Business Line',
            field: 'businessLine',
            flex: 1,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            type: 'singleSelect',
            filterOperators: getGridSingleSelectOperators().filter(operator => ['is', 'not'].includes(operator.value)),
            valueOptions: businessLineData,
            preProcessEditCellProps: params => {
                return {
                    ...params.props,
                    error: !params.props.value ? 'Business Line is required' : false,
                };
            },
            renderEditCell: params => {
                return <ProductPricesEditBusinessLineCell {...params} />;
            },
            renderCell: params => params.row.businessLine?.toUpperCase(),
        },
        {
            headerName: 'Material',
            field: 'material',
            flex: 1,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            valueGetter: params => {
                if (typeof params.value === 'string') return params.value;
                return params.value?.search;
            },
            preProcessEditCellProps: params => ({
                ...params.props,
                error: !params.props.value ? 'Material is required' : false,
            }),
            renderEditCell: params => <ProductPricesMaterialAutocomplete {...params} />,
        },
        {
            headerName: 'Org Region',
            field: 'orgRegion',
            flex: 1,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            type: 'singleSelect',
            filterOperators: getGridSingleSelectOperators().filter(operator => ['is', 'not'].includes(operator.value)),
            valueOptions: [],
            preProcessEditCellProps: params => ({
                ...params.props,
                error: !params.props.value ? 'Org Region is required' : false,
            }),
            renderEditCell: params => {
                return <ProductPricesEditOrgRegionAutocomplete {...params} />;
            },
        },
        {
            headerName: 'Application',
            field: 'application',
            flex: 1,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            preProcessEditCellProps: params => ({
                ...params.props,
                error: !params.props.value ? 'Application is required' : false,
            }),
            renderEditCell: params => {
                return <ProductPricesApplicationAutocomplete {...params} />;
            },
        },
        {
            headerName: 'Market Segment',
            field: 'marketSegment',
            flex: 1,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            preProcessEditCellProps: params => ({
                ...params.props,
                error: isMarketRegionTypeInclusive && !params.props.value ? 'Market Segment is required' : false,
            }),
            renderEditCell: params => {
                return <ProductPricesMarketSegmentAutocomplete {...params} />;
            },
        },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params: GridRowParams<IAddNewProductPrices>) => [
                <GridActionsCellItem
                    icon={<Delete />}
                    label='Delete'
                    onClick={() => {
                        if (params.row.id === isAddNewRow) {
                            setIsAddNewRow(null);
                        }
                        dispatchRows({ type: 'delete', id: params.row.id });
                    }}
                />,
                <AddRecordCopyButton copyHandler={handleCopyRecord} rowId={params.row.id} />,
            ],
        },
    ];

    const rowsPlusNew = [...rows, { ...newProductPrice, id: isAddNewRow }];

    const updatedRows = isAddNewRow ? rowsPlusNew : rows;

    const { getDataGridEditProps } = useI2pDataGridEdit<IAddNewProductPrices>({
        onEdit: async (newViewRow, oldRow) => {
            if (newViewRow.id.startsWith('new') && isAddNewRow) {
                if (JSON.stringify(newProductPrice) === JSON.stringify(newViewRow)) {
                    setIsAddNewRow(null);
                    enqueueSnackbar('Add record cancelled', { variant: 'warning' });
                    return newViewRow;
                }
                if (!isValid(newViewRow, isAddNewRow)) {
                    enqueueSnackbar('Record invalid', { variant: 'warning' });
                    return newViewRow;
                }
                dispatchRows({ type: 'add', newViewRow });
                setIsAddNewRow(null);
                return newViewRow;
            }
            dispatchRows({ type: 'edit', newViewRow });
            return newViewRow;
        },
    });
    const dataGridEditProps = getDataGridEditProps();

    return (
        <DataGridPremium
            {...dataGridEditProps}
            rows={updatedRows}
            sx={DATA_GRID_STYLE}
            columns={columns}
            disableSelectionOnClick
            components={{
                LoadingOverlay: LinearProgress,
                Toolbar: AddRecordToolbar,
            }}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            componentsProps={{
                panel: {
                    sx: {
                        '& .MuiDataGrid-filterFormColumnInput': {
                            width: 'auto',
                        },
                    },
                },
                toolbar: {
                    isAddNewRow,
                    setIsAddNewRow,
                    handleAddRecord,
                    rows,
                    isLoading,
                    addNewRecordsService: productPricesAddNewService,
                    successRoute: ROUTES.productPrices,
                    name: 'product-prices',
                    containsDuplicateRows,
                },
            }}
            disableVirtualization={false}
            headerHeight={HEADER_HEIGHT}
            getRowId={row => row.id}
            experimentalFeatures={{ newEditingApi: true }}
            editMode='row'
        />
    );
}
const getNewId = () => {
    let now = new Date();
    let stringDateId = now.toISOString();
    return stringDateId;
};

const newProductPrice: IAddNewProductPrices = {
    id: 'new',
    newRecordId: null,
    application: null,
    businessLine: null,
    marketSegment: null,
    material: null,
    orgRegion: null,
};

function isValid(newViewRow: IAddNewProductPrices, newProductPriceId: string) {
    if (newViewRow.id !== newProductPriceId) return false;
    if (!newViewRow.application) return false;
    if (!newViewRow.businessLine) return false;
    if (!newViewRow.material) return false;
    if (!newViewRow.orgRegion) return false;
    return true;
}
