import { ImmutableObject } from '@hookstate/core';
import { CLIENT_ID } from '@insight2profit/drive-app';
import { ServerSideState } from '@price-for-profit/data-grid';
import { DataAccessPaginatedResponse, DataAccessResponse, NoInfer } from '@price-for-profit/micro-services';
import {
    DATABASE_LABEL,
    MASS_ACTION_TYPES,
    TABLE_PRODUCT_PRICES,
    VIEW_PRODUCT_PRICES,
    productPriceStatuses,
} from 'shared/constants';
import { currencyCodeMapper } from 'shared/providers';
import {
    IProductPricesApprovalMassActionParameters,
    IProductPricesPriceChangeMassActionParameters,
    IProductPricesPriceChangeMassActionValidation,
    IProductPricesSubmitMassActionParameters,
    ITableProductPrices,
    IViewProductPrices,
} from 'shared/types';
import { IExchangeRatesService } from './exchangeRatesService';
import { IProductPriceApprovalService } from './productPriceApprovalService';
import { IProductPricesLocksService } from './productPricesLocksService';
import { IRowLevelSecurityDasService, PermittedRowLevels } from './rowLevelSecurityDasService';

type GetGridDataParams = {
    state: ServerSideState;
    permittedRowLevels: PermittedRowLevels;
};

type SoftEditGridRowDataParams = {
    newViewRow: IViewProductPrices;
    oldViewRow: IViewProductPrices;
    userApproverTier: number;
    userDisplayName?: string;
    isForeignCurrency?: boolean;
};

type PriceChangeMassActionParams = {
    state: ServerSideState;
    permittedRowLevels: PermittedRowLevels;
    parameters: IProductPricesPriceChangeMassActionParameters;
};

type ApprovalMassActionParams = {
    state: ServerSideState;
    permittedRowLevels: PermittedRowLevels;
    parameters: IProductPricesApprovalMassActionParameters;
    userApproverTier: number;
};

type SubmitMassActionParams = {
    state: ServerSideState;
    permittedRowLevels: PermittedRowLevels;
    userApproverTier: number;
    modifiedBy?: string;
};

type ValidatePriceChangeMassActionParams = {
    state: ServerSideState;
    permittedRowLevels: PermittedRowLevels;
    parameters: IProductPricesPriceChangeMassActionParameters;
};

type StatusModifierParams =
    | {
          action: 'approve' | 'reject';
          payload: {
              userDisplayName: string;
              now: string;
              currentStatus: string;
          };
      }
    | {
          action: 'edit';
          payload: {
              userDisplayName: string;
              now: string;
              currentStatus: string;
              isGlobalEdit: boolean;
          };
      }
    | {
          action: 'submit';
          payload: {
              userDisplayName: string;
              userEmail: string;
              now: string;
              currentStatus: string;
              oldTableRow: ITableProductPrices;
              userApproverTier: number;
              isLocked: boolean;
          };
      };

type StatusModifierResponse = Partial<ITableProductPrices>;

export interface IProductPricesService {
    getGridData({
        state,
        permittedRowLevels,
    }: GetGridDataParams): Promise<DataAccessPaginatedResponse<IViewProductPrices>>;
    softEditGridRowData({
        newViewRow,
        oldViewRow,
        userApproverTier,
        userDisplayName,
        isForeignCurrency,
    }: SoftEditGridRowDataParams): Promise<{
        editResponse: DataAccessResponse<ITableProductPrices>;
        addResponse: DataAccessResponse<ITableProductPrices>;
        newViewRow: IViewProductPrices;
    }>;
    priceChangeMassAction({ state, permittedRowLevels, parameters }: PriceChangeMassActionParams): Promise<void>;
    validatePriceChangeMassAction({
        state,
        permittedRowLevels,
        parameters,
    }: ValidatePriceChangeMassActionParams): Promise<
        DataAccessPaginatedResponse<IProductPricesPriceChangeMassActionValidation>
    >;
    approvalMassAction({
        state,
        permittedRowLevels,
        parameters,
        userApproverTier,
    }: ApprovalMassActionParams): Promise<void>;
    submitMassAction({
        state,
        permittedRowLevels,
        userApproverTier,
        modifiedBy,
    }: SubmitMassActionParams): Promise<void>;
    submitRow(
        oldViewRow: IViewProductPrices,
        userApproverTier: number,
        user: ImmutableObject<app.UserInfo>,
        isLocked: boolean
    ): Promise<{
        editResponse: DataAccessResponse<ITableProductPrices>;
        addResponse: DataAccessResponse<ITableProductPrices>;
        newViewRow: IViewProductPrices;
    }>;
    approveRow(
        oldViewRow: IViewProductPrices,
        userDisplayName: string
    ): Promise<{
        editResponse: DataAccessResponse<ITableProductPrices>;
        addResponse: DataAccessResponse<ITableProductPrices>;
        newViewRow: IViewProductPrices;
    }>;
    rejectRow(
        oldViewRow: IViewProductPrices,
        userDisplayName: string
    ): Promise<{
        editResponse: DataAccessResponse<ITableProductPrices>;
        addResponse: DataAccessResponse<ITableProductPrices>;
        newViewRow: IViewProductPrices;
    }>;
}

export class ProductPricesService implements IProductPricesService {
    constructor(
        private rlsDasService: IRowLevelSecurityDasService,
        private productPriceApprovalService: IProductPriceApprovalService,
        private productPricesLocksService: IProductPricesLocksService,
        private exchangeRatesService: IExchangeRatesService
    ) {}

    cmPercentEditedColumns = {
        globalRevisedMinimumCMPercent: 'globalRevisedMinimumCMPercent',
        globalRevisedTargetCMPercent: 'globalRevisedTargetCMPercent',
        regionalRevisedMinimumCMPercent: 'regionalRevisedMinimumCMPercent',
        regionalRevisedTargetCMPercent: 'regionalRevisedTargetCMPercent',
    };

    async getGridData({
        state,
        permittedRowLevels,
    }: GetGridDataParams): Promise<DataAccessPaginatedResponse<IViewProductPrices>> {
        return await this.rlsDasService.getCollectionWithRls<IViewProductPrices, typeof DATABASE_LABEL>(
            state,
            permittedRowLevels,
            {
                clientId: CLIENT_ID,
                databaseLabel: DATABASE_LABEL,
                tableId: VIEW_PRODUCT_PRICES,
                page: state.pageNumber,
                pageSize: state.pageSize,
                sortBy: state.sortModel[0]?.field as NoInfer<keyof IViewProductPrices>,
                sortDescending: state.sortModel[0]?.sort === 'desc',
            }
        );
    }

    async softEditGridRowData({
        newViewRow,
        oldViewRow,
        userDisplayName,
        isForeignCurrency,
    }: SoftEditGridRowDataParams): Promise<{
        editResponse: DataAccessResponse<ITableProductPrices>;
        addResponse: DataAccessResponse<ITableProductPrices>;
        newViewRow: IViewProductPrices;
    }> {
        const now = new Date().toISOString();

        const oldTableRow = await this.getOldTableRow(oldViewRow.massActionId);
        const isGlobalEdit = this.isGlobalEdit({ newViewRow, oldViewRow });

        const modifiedNewTableRow = await this.createNewRow({
            isForeignCurrency,
            newViewRow,
            oldTableRow,
            statusModifierParams: {
                action: 'edit',
                payload: {
                    userDisplayName: userDisplayName || '',
                    now,
                    currentStatus: oldTableRow.status || '',
                    isGlobalEdit,
                },
            },
        });

        await this.softEditValidation({ newTableRow: modifiedNewTableRow, oldTableRow });

        const editResponse = await this.termOldTableRow(oldTableRow, now);

        const addResponse = await this.addNewRow(modifiedNewTableRow);

        return {
            editResponse,
            addResponse,
            newViewRow,
        };
    }

    async priceChangeMassAction({ state, permittedRowLevels, parameters }: PriceChangeMassActionParams) {
        await this.rlsDasService.executeMassActionWithRls<
            IViewProductPrices,
            IProductPricesPriceChangeMassActionParameters
        >(state, permittedRowLevels, {
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: VIEW_PRODUCT_PRICES,
            queryName: 'spMassActionProductPrices',
            parameters: parameters,
            page: state.pageNumber,
            pageSize: state.pageSize,
        });
    }

    async validatePriceChangeMassAction({
        state,
        permittedRowLevels,
        parameters,
    }: ValidatePriceChangeMassActionParams): Promise<
        DataAccessPaginatedResponse<IProductPricesPriceChangeMassActionValidation>
    > {
        return await this.rlsDasService.executeMassActionWithRls<
            IViewProductPrices,
            IProductPricesPriceChangeMassActionParameters,
            IProductPricesPriceChangeMassActionValidation
        >(state, permittedRowLevels, {
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: VIEW_PRODUCT_PRICES,
            queryName: 'spMassActionProductPricesValidation',
            parameters,
            page: 0,
            pageSize: 1000,
        });
    }

    async approvalMassAction({ state, permittedRowLevels, parameters, userApproverTier }: ApprovalMassActionParams) {
        await this.rlsDasService.executeMassActionWithRls<
            IViewProductPrices,
            IProductPricesApprovalMassActionParameters
        >(state, permittedRowLevels, {
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: VIEW_PRODUCT_PRICES,
            queryName: 'spMassActionProductPricesApproval',
            parameters: { ...parameters, UserApproverTier: userApproverTier },
            page: state.pageNumber,
            pageSize: state.pageSize,
        });
    }

    async submitMassAction({ state, permittedRowLevels, userApproverTier, modifiedBy }: SubmitMassActionParams) {
        if (!modifiedBy) throw new Error('User not found.');

        await this.rlsDasService.executeMassActionWithRls<IViewProductPrices, IProductPricesSubmitMassActionParameters>(
            state,
            permittedRowLevels,
            {
                clientId: CLIENT_ID,
                databaseLabel: DATABASE_LABEL,
                tableId: VIEW_PRODUCT_PRICES,
                queryName: 'spMassActionProductPricesSubmit',
                parameters: {
                    Type: MASS_ACTION_TYPES.SUBMIT,
                    UserApproverTier: userApproverTier,
                    ModifiedBy: modifiedBy,
                },
                page: state.pageNumber,
                pageSize: state.pageSize,
            }
        );
    }

    async submitRow(
        oldViewRow: IViewProductPrices,
        userApproverTier: number,
        user: ImmutableObject<app.UserInfo>,
        isLocked: boolean
    ): Promise<{
        editResponse: DataAccessResponse<ITableProductPrices>;
        addResponse: DataAccessResponse<ITableProductPrices>;
        newViewRow: IViewProductPrices;
    }> {
        const now = new Date().toISOString();

        const oldTableRow: ITableProductPrices = await this.getOldTableRow(oldViewRow.massActionId);

        const modifiedNewTableRow: ITableProductPrices = await this.createNewRow({
            newViewRow: oldViewRow,
            oldTableRow,
            statusModifierParams: {
                action: 'submit',
                payload: {
                    userDisplayName: user.displayName || '',
                    userEmail: user.email || '',
                    now,
                    currentStatus: oldTableRow.status || '',
                    isLocked,
                    userApproverTier,
                    oldTableRow,
                },
            },
        });

        const editResponse = await this.termOldTableRow(oldTableRow, now);

        const addResponse = await this.addNewRow(modifiedNewTableRow);

        const newViewRow = {
            ...oldViewRow,
            ...modifiedNewTableRow,
        };

        return { editResponse, addResponse, newViewRow };
    }

    async approveRow(
        oldViewRow: IViewProductPrices,
        userDisplayName: string
    ): Promise<{
        editResponse: DataAccessResponse<ITableProductPrices>;
        addResponse: DataAccessResponse<ITableProductPrices>;
        newViewRow: IViewProductPrices;
    }> {
        const now = new Date().toISOString();

        const oldTableRow: ITableProductPrices = await this.getOldTableRow(oldViewRow.massActionId);

        const modifiedNewTableRow: ITableProductPrices = await this.createNewRow({
            newViewRow: oldViewRow,
            oldTableRow,
            statusModifierParams: {
                action: 'approve',
                payload: {
                    userDisplayName: userDisplayName || '',
                    now,
                    currentStatus: oldTableRow.status || '',
                },
            },
        });

        const editResponse = await this.termOldTableRow(oldTableRow, now);

        const addResponse = await this.addNewRow(modifiedNewTableRow);

        const newViewRow = {
            ...oldViewRow,
            ...modifiedNewTableRow,
        };

        return { editResponse, addResponse, newViewRow };
    }

    async rejectRow(
        oldViewRow: IViewProductPrices,
        userDisplayName: string
    ): Promise<{
        editResponse: DataAccessResponse<ITableProductPrices>;
        addResponse: DataAccessResponse<ITableProductPrices>;
        newViewRow: IViewProductPrices;
    }> {
        const now = new Date().toISOString();

        const oldTableRow: ITableProductPrices = await this.getOldTableRow(oldViewRow.massActionId);

        const modifiedNewTableRow: ITableProductPrices = await this.createNewRow({
            newViewRow: oldViewRow,
            oldTableRow,
            statusModifierParams: {
                action: 'reject',
                payload: {
                    userDisplayName: userDisplayName || '',
                    now,
                    currentStatus: oldTableRow.status || '',
                },
            },
        });

        const editResponse = await this.termOldTableRow(oldTableRow, now);

        const addResponse = await this.addNewRow(modifiedNewTableRow);

        const newViewRow = {
            ...oldViewRow,
            ...modifiedNewTableRow,
        };

        return { editResponse, addResponse, newViewRow };
    }

    private isGlobalEdit({
        newViewRow,
        oldViewRow,
    }: {
        newViewRow: IViewProductPrices;
        oldViewRow: IViewProductPrices;
    }) {
        return (
            newViewRow.globalRevisedMinimumCMPercent?.toFixed(10) !==
                oldViewRow.globalRevisedMinimumCMPercent?.toFixed(10) ||
            newViewRow.globalRevisedTargetCMPercent?.toFixed(10) !==
                oldViewRow.globalRevisedTargetCMPercent?.toFixed(10) ||
            newViewRow.globalRevisedMinimum?.toFixed(10) !== oldViewRow.globalRevisedMinimum?.toFixed(10) ||
            newViewRow.globalRevisedTarget?.toFixed(10) !== oldViewRow.globalRevisedTarget?.toFixed(10)
        );
    }

    private async termOldTableRow(
        oldTableRow: ITableProductPrices,
        now: string
    ): Promise<DataAccessResponse<ITableProductPrices>> {
        return await this.rlsDasService.updateRow<ITableProductPrices, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_PRODUCT_PRICES,
            payload: {
                ...oldTableRow,
                effectiveEnd: now,
            },
        });
    }

    private async addNewRow(newTableRow: ITableProductPrices) {
        return await this.rlsDasService.addRow<ITableProductPrices, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_PRODUCT_PRICES,
            payload: newTableRow,
        });
    }

    private async getOldTableRow(massActionId: number): Promise<ITableProductPrices> {
        const { data: oldTableRow } = await this.rlsDasService.getSingle<ITableProductPrices, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_PRODUCT_PRICES,
            key: massActionId.toString(),
        });

        if (!oldTableRow || !!oldTableRow.effectiveEnd || !!oldTableRow.deleted) {
            throw new Error('Data not valid. Please refresh the page and try again.');
        }

        return oldTableRow;
    }

    private cmPercentEditedColumn({
        newViewRow,
        oldViewRow,
    }: {
        newViewRow: IViewProductPrices;
        oldViewRow: IViewProductPrices;
    }) {
        if (newViewRow.globalRevisedMinimumCMPercent !== oldViewRow.globalRevisedMinimumCMPercent)
            return this.cmPercentEditedColumns.globalRevisedMinimumCMPercent;
        if (newViewRow.globalRevisedTargetCMPercent !== oldViewRow.globalRevisedTargetCMPercent)
            return this.cmPercentEditedColumns.globalRevisedTargetCMPercent;
        if (newViewRow.regionalRevisedMinimumCMPercent !== oldViewRow.regionalRevisedMinimumCMPercent)
            return this.cmPercentEditedColumns.regionalRevisedMinimumCMPercent;
        if (newViewRow.regionalRevisedTargetCMPercent !== oldViewRow.regionalRevisedTargetCMPercent)
            return this.cmPercentEditedColumns.regionalRevisedTargetCMPercent;

        return undefined;
    }

    private async createNewRow({
        newViewRow,
        oldTableRow,
        statusModifierParams,
        isForeignCurrency,
    }: {
        newViewRow: IViewProductPrices;
        oldTableRow: ITableProductPrices;
        statusModifierParams: StatusModifierParams;
        isForeignCurrency?: boolean;
    }) {
        const newTableRow: ITableProductPrices = {
            ...oldTableRow,
            globalRevisedMinimum: newViewRow.globalRevisedMinimum,
            globalRevisedTarget: newViewRow.globalRevisedTarget,
            regionalRevisedMinimum: newViewRow.regionalRevisedMinimum,
            regionalRevisedTarget: newViewRow.regionalRevisedTarget,
        };

        const statusModifier = this.statusModifier(statusModifierParams);

        if (isForeignCurrency) {
            const currentCurrencyCode = currencyCodeMapper(newViewRow.orgRegion);
            const exchangeRatesResponse = await this.exchangeRatesService.getExchangeRates();
            const matchingExchangeRate = exchangeRatesResponse?.data?.find(anExchangeRate => {
                return anExchangeRate.fromCurrencyCode === currentCurrencyCode;
            });
            if (!matchingExchangeRate) {
                throw new Error(`A matching exchange rate was not found for ${currentCurrencyCode}`);
            }

            if (
                newTableRow.regionalRevisedMinimum &&
                newTableRow.regionalRevisedTarget &&
                newTableRow.globalRevisedMinimum &&
                newTableRow.globalRevisedTarget
            ) {
                const modifiedNewTableRow: ITableProductPrices = {
                    ...newTableRow,
                    massActionId: 0,
                    modifiedBy: statusModifierParams.payload.userDisplayName,
                    effectiveStart: statusModifierParams.payload.now,
                    effectiveEnd: undefined,
                    deleted: false,
                    ...statusModifier,
                };
                return modifiedNewTableRow;
            }
        }

        const modifiedNewTableRow: ITableProductPrices = {
            ...newTableRow,
            massActionId: 0,
            regionalRevisedMinimum: newTableRow.regionalRevisedMinimum,
            regionalRevisedTarget: newTableRow.regionalRevisedTarget,
            modifiedBy: statusModifierParams.payload.userDisplayName,
            effectiveStart: statusModifierParams.payload.now,
            effectiveEnd: undefined,
            deleted: false,
            ...statusModifier,
        };
        return modifiedNewTableRow;
    }

    private statusModifier({ action, payload }: StatusModifierParams): StatusModifierResponse {
        switch (action) {
            case 'edit':
                if (payload.isGlobalEdit && payload.currentStatus !== productPriceStatuses.NEEDS_REVIEW) {
                    return {
                        status: productPriceStatuses.NEEDS_REVIEW,
                        globalApprovedBy: undefined,
                        globalApprovedDate: undefined,
                        regionalApprovedBy: undefined,
                        regionalApprovedDate: undefined,
                        editedBy: payload.userDisplayName,
                        editedDate: payload.now,
                        approver: undefined,
                        submittedBy: undefined,
                        submittedByEmail: undefined,
                        submittedByDate: undefined,
                    };
                }
                if (
                    !payload.isGlobalEdit &&
                    [
                        productPriceStatuses.REGIONAL_REJECTED,
                        productPriceStatuses.REGIONAL_APPROVED,
                        productPriceStatuses.REGIONAL_APPROVAL_REQUIRED,
                    ].includes(payload.currentStatus)
                ) {
                    return {
                        status: productPriceStatuses.GLOBAL_APPROVED,
                        regionalApprovedBy: undefined,
                        regionalApprovedDate: undefined,
                        editedBy: payload.userDisplayName,
                        editedDate: payload.now,
                        submittedBy: undefined,
                        submittedByEmail: undefined,
                        submittedByDate: undefined,
                    };
                }
                return {
                    editedBy: payload.userDisplayName,
                    editedDate: payload.now,
                    submittedBy: undefined,
                    submittedByEmail: undefined,
                    submittedByDate: undefined,
                };
            case 'submit':
                if (payload.currentStatus === productPriceStatuses.NEEDS_REVIEW && !payload.isLocked) {
                    const { isAutoApproved, approver, status } = this.productPriceApprovalService.getSubmitStatus({
                        oldTableRow: payload.oldTableRow,
                        userApproverTier: payload.userApproverTier,
                        isLocked: payload.isLocked,
                    });
                    return {
                        status,
                        approver,
                        globalApprovedBy: isAutoApproved ? payload.userDisplayName : undefined,
                        globalApprovedDate: isAutoApproved ? payload.now : undefined,
                        regionalApprovedBy: undefined,
                        regionalApprovedDate: undefined,
                        submittedBy: undefined,
                        submittedByEmail: undefined,
                        submittedByDate: undefined,
                    };
                }
                if (payload.currentStatus === productPriceStatuses.GLOBAL_APPROVED && payload.isLocked) {
                    const { isAutoApproved, approver, status } = this.productPriceApprovalService.getSubmitStatus({
                        oldTableRow: payload.oldTableRow,
                        userApproverTier: payload.userApproverTier,
                        isLocked: payload.isLocked,
                    });
                    return {
                        status,
                        approver,
                        regionalApprovedBy: isAutoApproved ? payload.userDisplayName : undefined,
                        regionalApprovedDate: isAutoApproved ? payload.now : undefined,
                        editedBy: payload.userDisplayName,
                        editedDate: payload.now,
                        submittedBy: payload.userDisplayName,
                        submittedByEmail: payload.userEmail,
                        submittedByDate: payload.now,
                    };
                }
                throw Error('Invalid submit');
            case 'approve':
                if (payload.currentStatus === productPriceStatuses.GLOBAL_APPROVAL_REQUIRED) {
                    return {
                        status: productPriceStatuses.GLOBAL_APPROVED,
                        globalApprovedBy: payload.userDisplayName,
                        globalApprovedDate: payload.now,
                        regionalApprovedBy: undefined,
                        regionalApprovedDate: undefined,
                    };
                }
                if (payload.currentStatus === productPriceStatuses.REGIONAL_APPROVAL_REQUIRED) {
                    return {
                        status: productPriceStatuses.REGIONAL_APPROVED,
                        regionalApprovedBy: payload.userDisplayName,
                        regionalApprovedDate: payload.now,
                    };
                }
                throw Error('Invalid approval');
            case 'reject':
                if (payload.currentStatus === productPriceStatuses.GLOBAL_APPROVAL_REQUIRED) {
                    return {
                        status: productPriceStatuses.GLOBAL_REJECTED,
                        globalApprovedBy: undefined,
                        globalApprovedDate: undefined,
                        regionalApprovedBy: undefined,
                        regionalApprovedDate: undefined,
                    };
                }
                if (payload.currentStatus === productPriceStatuses.REGIONAL_APPROVAL_REQUIRED) {
                    return {
                        status: productPriceStatuses.REGIONAL_REJECTED,
                        regionalApprovedBy: undefined,
                        regionalApprovedDate: undefined,
                    };
                }
                throw Error('Invalid rejection');
            default:
                throw Error('Invalid status change');
        }
    }

    private async softEditValidation({
        newTableRow,
        oldTableRow,
    }: {
        newTableRow: ITableProductPrices;
        oldTableRow: ITableProductPrices;
    }) {
        if (!newTableRow.modifiedBy) throw new Error('User display name not found');

        if (newTableRow?.globalRevisedMinimum === null || newTableRow?.globalRevisedMinimum === undefined) {
            throw new Error('New Global Revised Minimum is missing');
        }

        if (newTableRow?.globalRevisedTarget === null || newTableRow?.globalRevisedTarget === undefined) {
            throw new Error('New Global Revised Target is missing');
        }

        if (newTableRow?.regionalRevisedMinimum === null || newTableRow?.regionalRevisedMinimum === undefined) {
            throw new Error('New Regional Revised Minimum is missing');
        }

        if (newTableRow?.regionalRevisedTarget === null || newTableRow?.regionalRevisedTarget === undefined) {
            throw new Error('New Regional Revised Target is missing');
        }

        if (newTableRow.globalRevisedMinimum <= 0) {
            throw new Error('Global Revised Minimum must be greater than 0');
        }

        if (newTableRow.globalRevisedTarget <= 0) {
            throw new Error('Global Revised Target must be greater than 0');
        }

        if (newTableRow.regionalRevisedMinimum <= 0) {
            throw new Error('Regional Revised Minimum must be greater than 0');
        }

        if (newTableRow.regionalRevisedTarget <= 0) {
            throw new Error('Regional Revised Target must be greater than 0');
        }

        if (newTableRow?.globalRevisedTarget < newTableRow?.globalRevisedMinimum) {
            throw new Error('Global Revised Target cannot be less than Global Revised Minimum');
        }

        if (newTableRow?.globalRevisedMinimum > newTableRow?.globalRevisedTarget) {
            throw new Error('Global Revised Minimum cannot be greater than Global Revised Target');
        }

        if (newTableRow?.regionalRevisedTarget < newTableRow?.regionalRevisedMinimum) {
            throw new Error('Regional Revised Target cannot be less than Regional Revised Minimum');
        }

        if (newTableRow?.regionalRevisedMinimum > newTableRow?.regionalRevisedTarget) {
            throw new Error('Regional Revised Minimum cannot be greater than Regional Revised Target');
        }
    }
}
