import { SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';

export type FieldOption = {
    value: string;
    displayText: string;
    isAllowed: boolean;
};

export function useMassActionField(fieldOptions: FieldOption[]) {
    const allowedFieldOptions = fieldOptions.filter(action => action.isAllowed);
    const defaultSelectedField = allowedFieldOptions?.[0]?.value || '';
    const [selectedField, setSelectedField] = useState<string>(defaultSelectedField);

    const fieldOnChangeHandler:
        | ((event: SelectChangeEvent<string>, child: React.ReactNode) => void)
        | undefined = e => {
        setSelectedField(e.target.value);
    };

    useEffect(() => {
        if (!allowedFieldOptions.map(allowedFieldOption => allowedFieldOption.value).includes(selectedField)) {
            setSelectedField(defaultSelectedField);
        }
    }, [allowedFieldOptions, selectedField, defaultSelectedField]);

    return { allowedFieldOptions, selectedField, fieldOnChangeHandler };
}
