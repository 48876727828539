import { Navigate } from 'react-router-dom';
import { ROUTES } from 'routing';
import { useUserPermissions } from 'shared/hooks';

export function ModuleRedirect() {
    const {
        page: { isViewProductPricesPageAllowed, isViewCustomerPricesPageAllowed },
    } = useUserPermissions();

    if (isViewProductPricesPageAllowed) {
        return <Navigate to={ROUTES.productPrices} replace />;
    }

    if (isViewCustomerPricesPageAllowed) {
        return <Navigate to={ROUTES.customerPrices} replace />;
    }

    return null;
}
