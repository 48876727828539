import { useDebounce } from '@insight2profit/drive-app';
import { TextField } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useCustomerPricesSoldToDropdown } from 'shared/queries/customerPricesDropdownQueries';
import { IVwDropDownCustomerPricesSoldTo } from 'shared/types';
import { CustomerPricesAddNewAutocomplete } from './customerPricesAddNewAutocomplete';

export const getSoldToId = (soldTo: IVwDropDownCustomerPricesSoldTo | string) => {
    if (typeof soldTo === 'string') {
        return soldTo.split('|')[0];
    } else {
        return soldTo?.soldToId;
    }
};

export function CustomerPricesSoldToAutocomplete(params: GridRenderEditCellParams) {
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 500);
    const { data, isLoading, isFetching } = useCustomerPricesSoldToDropdown({
        searchValue: debouncedSearchValue,
    });
    const apiRef = useGridApiContext();
    return (
        <CustomerPricesAddNewAutocomplete
            isLoading={isLoading}
            setEditCellValue={apiRef.current.setEditCellValue}
            rowId={params.row.id}
            field={params.field}
            value={params.value}
            options={debouncedSearchValue && data ? data : []}
            error={params.error}
            getOptionLabel={(option: IVwDropDownCustomerPricesSoldTo) => {
                if (typeof option === 'object' && typeof option.search === 'string') return option.search;
                if (typeof option === 'string') return option;
                return '';
            }}
            isOptionEqualToValue={(
                option: IVwDropDownCustomerPricesSoldTo,
                value: IVwDropDownCustomerPricesSoldTo | string
            ) => {
                if (typeof value === 'string') {
                    return option.soldToId === value;
                }
                return option.soldToId === value.soldToId;
            }}
            placeholder={'Sold To'}
            onChange={(_, value) => {
                setSearchValue(value?.search ?? '');
                apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: value });
                apiRef.current.setEditCellValue({ id: params.id, field: 'salesOrganization', value: null });
            }}
            onBlur={event => setSearchValue('')}
            loading={(isLoading || isFetching) && !!debouncedSearchValue}
            onInputChange={(event, value, reason) => {
                if (value?.length >= 3) setSearchValue(value);
            }}
            renderInput={params => <TextField {...params} placeholder='Sold to Customer' />}
        />
    );
}
