import {
    EmbedParams,
    EventData,
    IAuthorizationService,
    IEventService,
    IPowerBiService,
    PowerBiReport,
} from '@price-for-profit/micro-services';
import { TrackViewPageParams, TrackViewPagePayload } from '../types';

export interface IAnalyticsReportService {
    getAuthorizedReportsForUser(): Promise<PowerBiReport[]>;
    getReportToken(reportId: string): Promise<EmbedParams>;
    trackViewPage(params: TrackViewPageParams): Promise<EventData>;
}

export class AnalyticsReportService implements IAnalyticsReportService {
    constructor(
        private authService: IAuthorizationService,
        private eventService: IEventService,
        private pbiService: IPowerBiService,
        private clientId: string,
        private applicationId: string,
        private userId: string
    ) {}

    async getAuthorizedReportsForUser(): Promise<PowerBiReport[]> {
        return await this.authService.getReportsForClientApplicationUser({
            clientId: this.clientId,
            applicationId: this.applicationId,
            userId: this.userId,
        });
    }

    async getReportToken(reportId: string): Promise<EmbedParams> {
        return await this.pbiService.getEmbedReportToken({
            clientId: this.clientId,
            reportId,
        });
    }

    async trackViewPage(params: TrackViewPageParams): Promise<EventData> {
        const user = await this.authService.getClientUserRestricted({
            clientId: this.clientId,
            userId: this.userId,
        });

        const request: TrackViewPagePayload = {
            clientId: this.clientId,
            applicationId: this.applicationId,
            userId: this.userId,
            timeStamp: new Date(),
            eventType: 'ViewReportsPage',
            action: 'Report',
            data: {
                userDisplayName: user?.displayName,
                selectedReport: params.selectedReport,
                selectedReportId: params.selectedReportId,
            },
        };

        return this.eventService.logUserActivity(request);
    }
}
