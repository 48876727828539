import { useService } from '@insight2profit/drive-app';
import { ServerSideState } from '@price-for-profit/data-grid';
import { DataAccessPaginatedResponse } from '@price-for-profit/micro-services';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useUserPermissions } from 'shared/hooks';
import { IProductPricesMassActionForm, IProductPricesPriceChangeMassActionValidation } from 'shared/types';
import {
    ProductPricesPriceChangeMassActionMutationParams,
    productPricesFormToPriceChangeParameterMapper,
} from './productPricesPriceChangeMassActionMutation';

interface UseProductPricesPriceChangeMassActionValidationMutationProps {
    productPricesState: ServerSideState;
}

export function useProductPricesPriceChangeMassActionValidationMutation({
    productPricesState,
}: UseProductPricesPriceChangeMassActionValidationMutationProps) {
    const { productPricesService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { permittedRowLevels } = useUserPermissions();

    const [validationRows, setValidationRows] = useState<IProductPricesPriceChangeMassActionValidation[]>([]);
    const [isValidated, setIsValidated] = useState(false);

    const mutation = useMutation<
        DataAccessPaginatedResponse<IProductPricesPriceChangeMassActionValidation>,
        Error,
        ProductPricesPriceChangeMassActionMutationParams
    >({
        mutationKey: ['productPricesMassActionValidationMutation'],
        mutationFn: async ({ state, parameters }) => {
            return await productPricesService.validatePriceChangeMassAction({ state, permittedRowLevels, parameters });
        },
        onSuccess: () => {
            setIsValidated(true);
        },
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            setIsValidated(false);
        },
    });

    const onSubmitValidate = async (form: IProductPricesMassActionForm) => {
        const validationNeededRows = await mutation.mutateAsync({
            state: productPricesState,
            parameters: productPricesFormToPriceChangeParameterMapper(form),
        });
        setValidationRows(validationNeededRows.data);
    };

    return { mutation, onSubmitValidate, validationRows, setValidationRows, isValidated, setIsValidated };
}
