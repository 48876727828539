import { Box, Drawer } from '@mui/material';
import { useState } from 'react';
import {
    COMMENTS_TAB,
    CUSTOMER_PRICE_HISTORY,
    MODEL_DETAILS_TAB,
    PRICING_ANALYTICS_TAB,
    customerPricesDrawerTabOptions,
} from 'shared/constants';
import { useSelectedCustomerPrice } from 'shared/providers/provideSelectedCustomerPrice';
import { CustomerPriceDrawerHeader } from './CustomerPriceDrawerHeader';
import { CustomerPriceDrawerTabs } from './CustomerPriceDrawerTabs';
import { CustomerPriceDrawerPriceChangeHistory } from './customerPriceChangeHistory/CustomerPriceDrawerPriceChangeHistory';
import { CustomerPriceDrawerComments } from './customerPriceComments/CustomerPriceDrawerComments';
import { CustomerPriceDrawerModelDetails } from './customerPriceModelDetails/CustomerPriceDrawerModelDetails';
import { CustomerPriceDrawerPricingAnalytics } from './customerPricePricingAnalytics/CustomerDrawerPricingAnalytics';

export function CustomerPriceDrawer() {
    const [selectedTab, setSelectedTab] = useState(customerPricesDrawerTabOptions[0]);

    const { status, isDrawerOpen, close } = useSelectedCustomerPrice();

    const onTabChange = (event: React.SyntheticEvent<Element, Event>, tab: string) => {
        setSelectedTab(tab);
    };

    return (
        <Drawer
            open={isDrawerOpen}
            anchor={'bottom'}
            sx={{
                '& .MuiDrawer-paper': {
                    width: 'calc(100vw - 50px)',
                    marginRight: '25px',
                    marginLeft: '25px',
                    borderRadius: '10px 10px 0px 0px',
                    minHeight: '550px',
                    maxHeight: '90vh',
                    height: '50vh',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
            onClose={() => close()}
        >
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
                <CustomerPriceDrawerHeader
                    shipToCustomer={status?.selectedCustomerPrice?.shipTo || ''}
                    material={status?.selectedCustomerPrice?.material || ''}
                    application={status?.selectedCustomerPrice?.application || ''}
                    orgRegion={status?.selectedCustomerPrice?.orgRegion || ''}
                    close={close}
                />
                <CustomerPriceDrawerTabs selectedTab={selectedTab} onTabChange={onTabChange} />
                {selectedTab === MODEL_DETAILS_TAB && (
                    <CustomerPriceDrawerModelDetails selectedCustomerPrice={status?.selectedCustomerPrice} />
                )}
                {selectedTab === COMMENTS_TAB && (
                    <CustomerPriceDrawerComments selectedCustomerPrice={status?.selectedCustomerPrice} />
                )}
                {
                    <CustomerPriceDrawerPricingAnalytics
                        isVisible={selectedTab === PRICING_ANALYTICS_TAB}
                        selectedCustomerPrice={status?.selectedCustomerPrice}
                    />
                }
                {selectedTab === CUSTOMER_PRICE_HISTORY && (
                    <CustomerPriceDrawerPriceChangeHistory selectedCustomerPrice={status?.selectedCustomerPrice} />
                )}
            </Box>
        </Drawer>
    );
}
