export const MASS_ACTION_TYPES = {
    AMOUNT: 'Set to amount',
    CHANGE_DOLLAR: 'Change by $',
    CHANGE_PERCENT: 'Change by %',
    INCREASE_PERCENT: 'Increase by %',
    APPROVE: 'Approve',
    REJECT: 'Reject',
    SUBMIT: 'Submit all prices',
    VALIDITY_DATES: 'Set Validity Dates',
    UOM: 'UOM',
} as const;
