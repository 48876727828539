import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { currencyCodeMapper, useExchangeRates, useProductPricesLocks } from 'shared/providers';

export const ProductPricesForeignCurrencyRenderCell = (params: GridRenderCellParams) => {
    const { isForeignCurrency } = useExchangeRates();
    const currencyCode = currencyCodeMapper(params.row.orgRegion);
    const valueToFormat = params?.value ?? 0;
    const { isBusinessLineLocked } = useProductPricesLocks();
    const globalFields = [
        'globalRevisedMinimum',
        'globalRevisedTarget',
        'globalRevisedMinimumCMPercent',
        'globalRevisedTargetCMPercent',
    ];

    const formattedValue = new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency: isForeignCurrency ? currencyCode : 'USD',
    }).format(valueToFormat);
    const isLockedField =
        isBusinessLineLocked(params.row.businessLine).isLocked && globalFields.includes(params.field) ? true : false;
    const isEditableCell = params.colDef.editable && params.isEditable ? true : false;

    return <div className={isEditableCell && !isLockedField ? 'editable-cell' : ''}>{formattedValue}</div>;
};
